import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext';
import '../../../styles/referralStats.css';

const ReferralStats = ({ walletAddress }) => {
  const { referralData } = useAppContext();
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 15, // Default to 15 to match other components
    total: referralData?.total_referrals || 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputPage, setInputPage] = useState(1); // For "Go to page" input

  // Truncate wallet address for display (e.g., "0x123...456")
  const truncateAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  // Simulate loading effect for context data (optional, remove if not needed)
  useEffect(() => {
    if (referralData) {
      setPagination(prev => ({
        ...prev,
        total: referralData.total_referrals || 0
      }));
      setLoading(false);
    } else {
      setError('No referral data available');
      setLoading(false);
    }
  }, [referralData]);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(pagination.total / pagination.per_page) || 1;
    if (newPage < 1 || newPage > totalPages) return;
    setPagination(prev => ({ ...prev, page: newPage }));
    setInputPage(newPage);
  };

  const paginatedReferrals = (referralData?.referrals || []).slice(
    (pagination.page - 1) * pagination.per_page,
    pagination.page * pagination.per_page
  );

  if (loading) {
    return <div className="loading-indicator">Loading referrals...</div>;
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  if (pagination.total === 0) {
    return null; // No referrals to display
  }

  return (
    <div className="referral-stats">
      <div className="table-wrapper">
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th scope="col">Wallet</th>
              <th scope="col">Date Referred</th>
            </tr>
          </thead>
          <tbody>
            {paginatedReferrals.map((referral, index) => (
              <tr key={referral.referred_wallet + index}>
                <td>
                  <span 
                    className="address" 
                    title={referral.referred_wallet}
                  >
                    {truncateAddress(referral.referred_wallet)}
                  </span>
                </td>
                <td>{new Date(referral.referred_at).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination-controls">
        <div className="per-page-group">
          <span>Show</span>
          <select 
            value={pagination.per_page} 
            onChange={(e) => setPagination(prev => ({ 
              ...prev, 
              per_page: parseInt(e.target.value), 
              page: 1 
            }))}
            className="per-page-select"
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
          <span>per page</span>
        </div>
        <div className="page-navigation">
          <button 
            onClick={() => handlePageChange(pagination.page - 1)} 
            disabled={pagination.page <= 1}
            className="pagination-button"
          >
            Previous
          </button>
          <input
            type="number"
            min="1"
            max={Math.ceil(pagination.total / pagination.per_page) || 1}
            value={inputPage}
            onChange={(e) => setInputPage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handlePageChange(parseInt(inputPage) || 1);
              }
            }}
            className="page-input"
          />
          <span>of {Math.ceil(pagination.total / pagination.per_page) || 1}</span>
          <button 
            onClick={() => handlePageChange(pagination.page + 1)} 
            disabled={pagination.page >= Math.ceil(pagination.total / pagination.per_page) || pagination.total === 0}
            className="pagination-button"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferralStats;