import React, { useState, useEffect, useCallback } from 'react';
import { useSimulateContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import { ethers } from 'ethers';
import { berachain } from 'wagmi/chains';
import MessageBox from '../components/Common/MessageBox';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

export function TransactionButton({
  abi,
  contractAddress,
  functionName,
  args,
  text,
  onSuccess,
  value = 0,
  gasLimit = null,
}) {
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageBoxHeader, setMessageBoxHeader] = useState('');
  const [messageBoxFooter, setMessageBoxFooter] = useState('');
  const [messageBoxContent, setMessageBoxContent] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  console.log(contractAddress,functionName,args,text,berachain.id)
  // Configuration for simulation and transaction
  const configOptions = {
    address: contractAddress,
    abi,
    functionName,
    args,
    value: ethers.utils.parseEther(value.toString()),
    chainId: berachain.id,
  };

  if (gasLimit) configOptions.gas = ethers.BigNumber.from(gasLimit);

  // Simulate the contract call
  const { data: simulation, error: simulationError, isLoading: simulationLoading } = useSimulateContract(configOptions);
  console.log('useSimulateContract:', { simulation, simulationError, simulationLoading });

  // Send the transaction using useWriteContract
  const {
    data: txData,
    error: writeError,
    isPending: writePending,
    writeContract,
    isSuccess: writeSuccess,
  } = useWriteContract();
  console.log('useWriteContract:', { txData, writeError, writePending, writeSuccess });

  // Wait for transaction confirmation
  const {
    data: receipt,
    isLoading: isConfirming,
    error: receiptError,
    isSuccess: receiptSuccess,
  } = useWaitForTransactionReceipt({
    hash: txData,
    chainId: berachain.id,
    confirmations: 1,
    timeout: 120000, // 2 minutes timeout
  });
  console.log('useWaitForTransactionReceipt:', { receipt, isConfirming, receiptError, receiptSuccess, hash: txData });

  const showMessage = useCallback((message, header = 'Transaction Hash', footer = '', timeout = 5000) => {
    console.log('showMessage:', { message, header, footer });
    setMessageBoxContent(message);
    setMessageBoxHeader(header);
    setMessageBoxFooter(footer);
    setShowMessageBox(true);
    setTimeout(() => setShowMessageBox(false), timeout);
  }, []);

  // Handle transaction sending
  useEffect(() => {
    if (txData) {
      console.log('Transaction sent, hash:', txData);
      showMessage(txData);
      setIsProcessing(true);
    }
  }, [txData, showMessage]);

  // Handle confirmation and trigger onSuccess
  useEffect(() => {
    if (receiptSuccess && receipt) {
      console.log('Transaction confirmed, receipt:', receipt);
      showMessage('Transaction confirmed.');
      setIsProcessing(false);
      if (onSuccess) {
        console.log('Triggering onSuccess with receipt:', receipt);
        onSuccess(receipt);
      }
    }
  }, [receiptSuccess, receipt, onSuccess, showMessage]);

  // Handle errors
  useEffect(() => {
    if (simulationError) {
      console.error('Simulation error:', simulationError);
      showMessage(`Simulation failed: ${simulationError.message}`);
      setIsProcessing(false);
    } else if (writeError) {
      console.error('Write error:', writeError);
      showMessage(`Error: ${writeError.message}`);
      setIsProcessing(false);
    } else if (receiptError) {
      console.error('Receipt error:', receiptError);
      showMessage(`Confirmation error: ${receiptError.message}`);
      setIsProcessing(false);
    }
  }, [simulationError, writeError, receiptError, showMessage]);

  const handleClick = useCallback(() => {
    console.log('handleClick called');
    if (!simulation) {
      console.error('No simulation data');
      showMessage('Transaction failed: No simulation data');
      return;
    }
    if (!writeContract) {
      console.error('writeContract unavailable');
      showMessage('Transaction failed: Wallet not ready');
      return;
    }
    console.log('Sending transaction with request:', simulation.request);
    setIsProcessing(true);
    try {
      writeContract(simulation.request);
    } catch (error) {
      console.error('writeContract failed:', error);
      showMessage(`Failed to send transaction: ${error.message}`);
      setIsProcessing(false);
    }
  }, [writeContract, simulation, showMessage]);

  console.log('Component state:', { writePending, isProcessing, isConfirming, simulationLoading, simulationExists: !!simulation, txData, receipt });

  return (
    <>
      {isProcessing ? (
        <div className="w-100 transactionButtonDisabled">
          <div className="spinner-border spinner-border-sm spinner-border-slow" role="status"></div>
          {writePending ? 'Processing...' : 'Waiting for confirmation'}
        </div>
      ) : (
        <div
          role="button"
          className="w-100 transactionButton"
          onClick={() => {
            console.log('Button clicked');
            handleClick();
          }}
          disabled={isProcessing || !simulation || simulationLoading}
        >
          {text}
        </div>
      )}

      {showMessageBox && (
        <MessageBox
          content={messageBoxContent}
          header={messageBoxHeader}
          footer={
            <a href={`https://www.berascan.com/tx/${messageBoxContent}`} target="_blank" rel="noopener noreferrer">
              Blockchain Explorer <OpenInNewOutlinedIcon fontSize="inherit" />
            </a>
          }
        />
      )}
    </>
  );
}