// Pool.jsx
import '../../../styles/pool.css';
import React from 'react';
import { Modal } from "react-bootstrap";
import { Gear } from 'react-bootstrap-icons';
import { TransactionButton } from '../../../functions/Trx';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import usdcIcon from '../../../img/icons/usd-coin-usdc-logo.png';
import AssetLogo from '../../Common/AssetLogo';
import usePoolLogic from './usePoolLogic';

const MobilePool = () => {
    const {
        chooseAssetModalOpen,
        settingsModalOpen,
        wrongSlippageInputMessage,
        highSlippageInputMessage,
        wrongTrxTimeInputMessage,
        chooseLPTokenModalOpen,
        selectedAsset,
        selectedAssetBalance,
        selectedLPTokenBalance,
        approvalButtonTokenVisible,
        approvalButtonUSDCVisible,
        addLiquidityButtonVisible,
        buttonMessage,
        selectedLPToken,
        approvalButtonLPTokenVisible,
        errorButtonVisible,
        expectedOutputVisible,
        tokenAmountA,
        tokenAmountB,
        selectedLPTokenAmount,
        estimatedOutput1,
        estimatedOutput2,
        amountADesired,
        amountBDesired,
        amountAMin,
        amountBMin,
        deadline,
        liquidityToRemove,
        showAddLiquidyPart,
        showRemoveLiquidyPart,
        style1,
        style2,
        USDCBalance,
        stableCoinName,
        address,
        MarketRouter_Address,
        MarketRouter_ABI,
        ERC20_ABI,
        USDC_Address,
        outputNumber,
        slippage,
        trxTime,
        closeChooseAssetModal,
        openSettingsModal,
        closeSettingsModal,
        saveSettingsSlippage,
        saveSettingsTrxTime,
        filterAssets,
        openSelectAssetModal,
        openChooseLPTokenModal,
        closeChooseLPTokenModal,
        listLPTokens,
        listAssets,
        selectAsset,
        selectLPToken,
        onSuccessApproveToken,
        onSuccessAddLiquidity,
        calculateUSDVolume,
        calculateTokenVolume,
        calculateTokenOutput,
        onSuccessApproveLP,
        onSuccessRemoveLiquidity,
        showRemoveLiquidity,
        showAddLiquidity,
        setMaxBalanceToken,
        setMaxBalanceUSD,
        setMaxBalanceLPToken,
        setPercentOfBalanceLPToken,
        setPercentOfAsset,
        selectedAssetAddress,
        selectedLPPairAddress,
        allowanceToken,
        allowanceUSDC,
        allowanceLPToken,
        web3_nm // Added to use for BigInt conversion
    } = usePoolLogic();

    return (
        <div className="mainContainer">
            <Modal show={chooseAssetModalOpen} onHide={closeChooseAssetModal}>
                <Modal.Header className="" closeButton>
                    <Modal.Title>Select a token</Modal.Title>
                </Modal.Header>
                <Modal.Body className="" style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <div className="row p-3 pr-3 my-auto">
                        <input className="col w-100 searchField" id="search" placeholder="Search" onChange={filterAssets}></input>
                    </div>
                    <div className="list-group border-nav">
                        {listAssets().length === 0 ? (
                            <div className="row"><div className="col p-4">You currently own no assets.</div></div>
                        ) : (
                            listAssets().map(asset => (
                                <div key={asset.key} className="assetSelectList" role="button" onClick={() => selectAsset(asset.ticker)}>
                                    <AssetLogo symbol={asset.ticker} />
                                    <div className="row">
                                        <div className="col-3"><b>{asset.ticker}</b></div>
                                        <div className="col text-right"><b>{asset.name}</b></div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Balance: {outputNumber(asset.balance, 4)}</div>
                                        <div className="col text-right">LP Balance: {asset.lpBalance > 0 ? outputNumber(Number(asset.lpBalance) / (10 ** 18), 8) : outputNumber(0, 8)}</div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={settingsModalOpen} onHide={closeSettingsModal}>
                <Modal.Header className="border" closeButton>
                    <Modal.Title>Choose your preferences</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-tgrey" style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <div className="form-row align-items-center mx-3">
                        <div className="col">Slippage tolerance</div>
                        <div className="col col-3 bg-light">
                            <input className="px-2 form-control input-sm pull-right" type="decimals" id="slippage" onChange={saveSettingsSlippage} placeholder={slippage / 100}></input>
                        </div>
                        <div className="col col-2">%</div>
                    </div>
                    {wrongSlippageInputMessage && <div className="row pl-3 pt-3 w-100 text-danger">Please input a valid slippage percentage</div>}
                    {highSlippageInputMessage && <div className="row pl-3 pt-3 w-100 text-danger">Warning. Your transaction may be frontrun</div>}
                    <div> </div>
                    <div className="form-row align-items-center mx-3">
                        <div className="col">Max transaction time</div>
                        <div className="col col-3 bg-light">
                            <input className="px-2 form-control input-sm pull-right" type="decimals" id="trxTime" onChange={saveSettingsTrxTime} placeholder={trxTime}></input>
                        </div>
                        <div className="col col-2">minutes</div>
                    </div>
                    {wrongTrxTimeInputMessage && <div className="row pl-3 pt-3 w-100 text-danger">Please input a valid transaction time</div>}
                </Modal.Body>
            </Modal>

            <Modal show={chooseLPTokenModalOpen} onHide={closeChooseLPTokenModal}>
                <Modal.Header className="" closeButton>
                    <Modal.Title>Select a liquidity pool</Modal.Title>
                </Modal.Header>
                <Modal.Body className="" style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <div className="row p-3 pr-3 my-auto">
                        <input className="col w-100 searchField" id="search" placeholder="Search" onChange={filterAssets}></input>
                    </div>
                    <div className="list-group">
                        {listLPTokens().length === 0 ? (
                            <div className="row"><div className="col p-4">You currently own no LP tokens.</div></div>
                        ) : (
                            listLPTokens().map(asset => (
                                <div key={asset.key} className="assetSelectList" role="button" onClick={() => selectLPToken(asset.ticker)}>
                                    <AssetLogo symbol={asset.ticker} />
                                    <div className="row">
                                        <div className="col-3"><b>{asset.ticker}</b></div>
                                        <div className="col text-right"><b>{asset.name}</b></div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Balance: {outputNumber(asset.balance, 4)}</div>
                                        <div className="col text-right">LP Balance: {outputNumber(Number(asset.lpBalance) / (10 ** 18), 8)}</div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            <div className="middleBoxSmall">
                <div className="mainBox">
                    <div className="selectButtonRow">
                        <div className={style1} onClick={showAddLiquidity} role="button">
                            <b>Add liquidity</b>
                        </div>
                        <div className={style2} onClick={showRemoveLiquidity} role="button">
                            <b>Remove liquidity</b>
                        </div>
                    </div>
                    <div className="selectSettingsRow">
                        <Gear className="h4" role="button" onClick={openSettingsModal} />
                    </div>

                    {showAddLiquidyPart && (
                        <div className="">
                            <div className="subBox">
                                <div className="selectAmountRow">
                                    {selectedAsset !== "Select Asset" && (
                                        <>
                                            <div className="percentBox" onClick={() => setPercentOfAsset(25)} role="button">25%</div>
                                            <div>    </div>
                                            <div className="percentBox" onClick={() => setPercentOfAsset(50)} role="button">50%</div>
                                            <div>    </div>
                                            <div className="percentBox" onClick={() => setPercentOfAsset(75)} role="button">75%</div>
                                            <div>    </div>
                                            <div className="percentBox" onClick={() => setPercentOfAsset(100)} role="button">100%</div>
                                        </>
                                    )}
                                </div>
                                <div className="tradingBoxRow">
                                    <div className="">
                                        <input id="tokenAmountA" onChange={calculateUSDVolume} className="inputCustom" type="text" lang="en" placeholder="0.00" />
                                    </div>
                                    <div className="">
                                        <div role="button" className="selectAssetButton" onClick={openSelectAssetModal}>
                                            <div className='assetBoxIcon'>
                                                <AssetLogo symbol={selectedAsset} />
                                            </div>
                                            <div className='assetSelectName'>{selectedAsset} <KeyboardArrowDownOutlinedIcon /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='balanceRow'>
                                    <div onClick={setMaxBalanceToken} role="button" className="col align-self-end text-lg-right textBalance">
                                        {selectedAsset !== "Select Asset" && (
                                            <span>
                                                Balance: {selectedAssetBalance > 0.0001 ? (
                                                    <span>{outputNumber(selectedAssetBalance, 4)}</span>
                                                ) : (
                                                    <span>&lt; 0.0001</span>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="tradingBoxMiddleRow">
                                    <ControlPointOutlinedIcon />
                                </div>
                            </div>
                            <div className="subBox">
                                <div className="tradingBoxRow">
                                    <input id="tokenAmountB" onChange={calculateTokenVolume} className="inputCustom" type="text" lang="en" placeholder="0.00" />
                                    <div className='stablecoinAssetBox'>
                                        <div className='assetBoxIcon'>
                                            <img className="" src={usdcIcon} alt="honey" width="24px" />
                                        </div>
                                        {stableCoinName}
                                    </div>
                                </div>
                                <div className='balanceRow'>
                                    <div onClick={setMaxBalanceUSD} role="button" className="col align-self-end text-lg-right textBalance">Balance: {outputNumber(USDCBalance, 2)} (Max)</div>
                                </div>
                            </div>
                            <div className="row"></div>
                            <div className="row">
                                {tokenAmountA * 1e18 >= allowanceToken && (
                                    <div className="w-100 px-3 py-1">
                                        <TransactionButton
                                            contractAddress={selectedAssetAddress}
                                            abi={ERC20_ABI}
                                            functionName='approve'
                                            args={[MarketRouter_Address, web3_nm.utils.toBigInt(2 ** 255)]} // Corrected to use BigInt
                                            text="Approve Token"
                                            onSuccess={onSuccessApproveToken}
                                        />
                                    </div>
                                )}
                                {tokenAmountB * 1e6 >= allowanceUSDC && (
                                    <div className="w-100 px-3 py-1">
                                        <TransactionButton
                                            contractAddress={USDC_Address}
                                            abi={ERC20_ABI}
                                            functionName='approve'
                                            args={[MarketRouter_Address, web3_nm.utils.toBigInt(2 ** 255)]} // Corrected to use BigInt
                                            text={`Approve ${stableCoinName}`}
                                            onSuccess={onSuccessApproveToken}
                                        />
                                    </div>
                                )}
                                {errorButtonVisible && (
                                    <div className="w-100 px-3 py-1">
                                        <div className="w-100 issuaaButtonDeactivated">{buttonMessage}</div>
                                    </div>
                                )}
                                {tokenAmountA * 1e18 < allowanceToken && tokenAmountB * 1e6 < allowanceUSDC && !errorButtonVisible && (
                                    <div className="w-100 px-3 py-1">
                                        <TransactionButton
                                            abi={MarketRouter_ABI}
                                            contractAddress={MarketRouter_Address}
                                            functionName="addLiquidity"
                                            args={[
                                                selectedAssetAddress,
                                                USDC_Address,
                                                amountADesired,
                                                amountBDesired,
                                                amountAMin,
                                                amountBMin,
                                                address,
                                                deadline
                                            ]} // Use pre-calculated BigInt values from hook
                                            text="Add liquidity"
                                            onSuccess={onSuccessAddLiquidity}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {showRemoveLiquidyPart && (
                        <div>
                            <div className="subBox">
                                <div className="selectAmountRow">
                                    {selectedLPToken !== "Select Asset" && (
                                        <>
                                            <div className="percentBox" onClick={() => setPercentOfBalanceLPToken(25)} role="button">25%</div>
                                            <div>    </div>
                                            <div className="percentBox" onClick={() => setPercentOfBalanceLPToken(50)} role="button">50%</div>
                                            <div>    </div>
                                            <div className="percentBox" onClick={() => setPercentOfBalanceLPToken(75)} role="button">75%</div>
                                            <div>    </div>
                                            <div className="percentBox" onClick={() => setPercentOfBalanceLPToken(100)} role="button">100%</div>
                                        </>
                                    )}
                                </div>
                                <div className="tradingBoxRow">
                                    <input id="LPTokenAmount" onChange={calculateTokenOutput} className="inputCustom" type="text" lang="en" placeholder="0.00" />
                                    <div role="button" className="selectAssetButton" onClick={openChooseLPTokenModal}>
                                        <div className='assetBoxIcon'>
                                            <AssetLogo symbol={selectedAsset} />
                                        </div>
                                        <div>{selectedLPToken} <KeyboardArrowDownOutlinedIcon /></div>
                                    </div>
                                </div>
                                <div className='balanceRow'>
                                    <div onClick={setMaxBalanceLPToken} role="button" className="col align-self-end text-lg-right textBalance">
                                        {selectedLPToken !== "Select Asset" && (
                                            <span>
                                                Balance: {selectedLPTokenBalance > 0.001 ? (
                                                    <span>{outputNumber(selectedLPTokenBalance, 8)}</span>
                                                ) : (
                                                    <span>&lt; 0.001</span>
                                                )} (Max)
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="w-12"> </div>
                                <div className="row"></div>
                            </div>
                            <div className="row"></div>
                            <div className="row">
                                {selectedLPTokenAmount > allowanceLPToken && (
                                    <div className="w-100 px-3 py-1 pb-3">
                                        <TransactionButton
                                            contractAddress={selectedLPPairAddress}
                                            abi={ERC20_ABI}
                                            functionName='approve'
                                            args={[MarketRouter_Address, web3_nm.utils.toBigInt(2 ** 255)]} // Corrected to use BigInt
                                            text="Approve"
                                            onSuccess={onSuccessApproveLP}
                                        />
                                    </div>
                                )}
                                {errorButtonVisible && (
                                    <div className="w-100 px-3 py-1 pb-3">
                                        <div className="issuaaButtonDeactivated">{buttonMessage}</div>
                                    </div>
                                )}
                                {!errorButtonVisible && selectedLPTokenAmount < allowanceLPToken && (
                                    <div className="w-100 px-3 py-1 pb-3">
                                        <TransactionButton
                                            abi={MarketRouter_ABI}
                                            contractAddress={MarketRouter_Address}
                                            functionName="removeLiquidity"
                                            args={[
                                                selectedAssetAddress,
                                                USDC_Address,
                                                web3_nm.utils.toBigInt(Math.round(liquidityToRemove)), // Ensure BigInt
                                                amountAMin,
                                                amountBMin,
                                                address,
                                                deadline
                                            ]} // Use pre-calculated BigInt values from hook, adjust liquidityToRemove
                                            text="Remove liquidity"
                                            onSuccess={onSuccessRemoveLiquidity}
                                        />
                                    </div>
                                )}
                            </div>
                            {expectedOutputVisible && (
                                <div className="subBox">
                                    <div>Estimated Output:</div>
                                    <div>{outputNumber(estimatedOutput1, 4)} {selectedLPToken} + {outputNumber(estimatedOutput2, 2)} {stableCoinName}.</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MobilePool;