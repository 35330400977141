import '../../../styles/mobileNavbar.css';
import MenuItem from './MenuItem';
import React,{useState} from 'react';
import MobileConnectButton from './mobileConnectButton';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import logo from '../../../img/graphics/TWIN_Logo_loading.png';
import { useAppContext } from '../../../context/AppContext'; // Import useAppContext
import { Modal} from "react-bootstrap";
import { Link } from 'react-router-dom';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ParaglidingOutlinedIcon from '@mui/icons-material/ParaglidingOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ScoreIcon from '@mui/icons-material/Score';


const MobileNavbar = () => {
  const {
    activePage,
    changeView,
    chainName,
    outputNumber,
    IDTBalance,
    GovernanceTokenTotalBalanceAllChains,
    tokenLess,marketLive
} = useAppContext();


const [disclaimerModalOpen,setDisclaimerModalOpen] = useState(false);
const [isOpen, setIsOpen] = useState(false); // Track the navbar's open state


const toggleNavbar = () => setIsOpen(!isOpen); // Toggle function

const handleLinkClick = () => {
  setIsOpen(false); // Close the navbar
};

const openDisclaimerModal = () => {
  setDisclaimerModalOpen(true)
}
const closeDisclaimerModal = () => {
  setDisclaimerModalOpen(false)
}
    return (
      <Navbar bg="white" expand="lg" fixed="top" expanded={isOpen}>
        <Container>

          <Navbar.Brand>
          <div className="mobileNavbarLogo">
                <img src={logo} alt="TWIN" width="60px"/>
            </div>
          </Navbar.Brand>
          
            <MobileConnectButton />
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" onClick={handleLinkClick}>
              <MenuItem to="/" icon={GridViewOutlinedIcon} label="Dashboard" />
              <MenuItem to="/portfolio" icon={WorkOutlineOutlinedIcon} label="Portfolio" />
              <MenuItem to="/mint" icon={GavelRoundedIcon} label="Mint" />
              {marketLive ?<MenuItem to="/pool" icon={PieChartRoundedIcon} label="Pool" />:null}
              {marketLive ?<MenuItem to="/trade" icon={AttachMoneyOutlinedIcon} label="Trade" />:null}
              <MenuItem to="/redeem" icon={PaymentsOutlinedIcon} label="Redeem" />
              {false ?
              <MenuItem to="/governance" icon={AccountBalanceOutlinedIcon} label="Governance" />
              : null}
              <MenuItem to="/points" icon={ScoreIcon} label="Points" />
              
              <div className="externalLinkMobile">
                <div className='menuItemMobile'>
                    <a href="https://twin-finance.medium.com/" target="_blank" rel="noreferrer">
                        <ChatBubbleOutlineOutlinedIcon/>&nbsp;&nbsp;Blog 
                    </a>
                </div>
              </div>  
              <Nav.Link>
              <div className="menuItemMobile" type="button" onClick={() =>openDisclaimerModal()} >
                    <AccountBalanceOutlinedIcon/>&nbsp;&nbsp;Legal
                </div>
              </Nav.Link>
              {(chainName === 'Berachain' || chainName === 'Berachain BArtio') ? 
                            <div className="w-100 pr-4 pl-4 pb-2">
                                <div type="button" className="w-100 menuButtonGreen">{chainName}</div>
                            </div>
                            :
                            ''
                        }

                        {(chainName === 'Berachain' || chainName === 'Berachain BArtio') && typeof GovernanceTokenTotalBalanceAllChains !== "undefined" && IDTBalance < 1  ?
                            <Link to="/TWIN">
                                <div className="w-100 pr-4 pl-4 pb-2">
                                    <div className="menuButtonTWIN">
                                        <div className='menuButtonTWIN1'>$TWIN</div>
                                        <div className='menuButtonTWIN2'>{outputNumber(parseFloat(GovernanceTokenTotalBalanceAllChains),0)}</div>
                                        
                                    </div>
                                </div>
                            </Link>
                            :
                            ''
                        }
                        {(chainName === 'Berachain'  || chainName === 'Berachain BArtio') && typeof GovernanceTokenTotalBalanceAllChains !== "undefined" && IDTBalance > 0 ? 
                            <Link to="/TWIND">
                                <div className="w-100 pr-4 pl-4 pb-2">
                                    <div className="menuButtonTWIND">     
                                        TWIND Conversion
                                    </div>
                                </div>
                            </Link>
                            
                            :
                            ''
                        }
            </Nav>
          </Navbar.Collapse>

        </Container>
        <Modal show={disclaimerModalOpen} onHide={closeDisclaimerModal}>
            <Modal.Header className="" closeButton>
                <Modal.Title>Legal notice</Modal.Title> 
            </Modal.Header>
            
            <Modal.Body className="" style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
             }} 
            >
                <div>
                  This website provides information and content of general nature about TWIN as a decentralized finance protocol (TWIN protocol) and as a decentralized autonomous organization (TWIN DAO). 
                    
                </div>
                <div>
                  The information and the content of this website may be wrong, subject to change or incomplete and may not be updated. You should not rely on this website or on any of the content therein for any kind of investment decision or for legal advice, financial advice, investment advice, or any kind of advice. 
                    
                </div>
                <div>
                  You always act at your own risk with respect to the content of this website and the TWIN protocol. In no way are the owners of, or contributors to the TWIN protocol, the TWIN DAO and to this website responsible for the actions, decisions, or other behavior taken or not taken by you in connection with this website or your potential interaction with the TWIN protocol. 
                    
                </div>
                <div>
                  Please note that the TWIN protocol is operated by the TWIN DAO and you are solely responsible for compliance with all laws that may apply to you and your use of the TWIN protocol and this website. 
                </div>
                <div>
                  Cryptocurrencies and blockchain technologies have been the subject of scrutiny by various regulatory bodies across the globe. 
                </div>
                <div>
                  TWIN DAO and its contributors make no representation regarding the application to your use of the TWIN protocol or this website of any laws, including, without limitation, those related to investments, tax, gaming, options, derivatives, or securities. Depending on the jurisdiction, the use of the TWIN protocol may be restricted. 
                  
                </div>
                <div>
                  You agree that TWIN DAO and its contributors are not responsible for determining whether or which laws may apply to you and your use of the TWIN protocol and this website. 
                </div>
                <div>
                  The TWIN DAO may restrict the use of the TWIN protocol to citizens and residents of certain jurisdictions. Particular restrictions apply for US residents who are strictly prohibited from purchasing, minting, farming or staking crypto assets using the TWIN protocol. 
                </div>
                <div>
                  Further, restricted persons should not interact with the TWIN protocol at any time. By interacting with any smart contract of the TWIN protocol you expressly and unconditionally affirm that you are not a resident of the US. 
                </div>
                <div>
                  All rights reserved. No guarantees, warranties or whatsoever given. <b>DeFi tools are not toys. Do your own research.</b>
                </div>

              </Modal.Body>
                        
        </Modal> 
      </Navbar>
    )
  }
  export default MobileNavbar;