import '../../../styles/sidebar.css';

import { Modal} from "react-bootstrap";
import logoWide from '../../../img/graphics/TWIN_Logo_wide.png';
import panda from '../../../img/graphics/PandaWithHoney.png';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ParaglidingOutlinedIcon from '@mui/icons-material/ParaglidingOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ScoreIcon from '@mui/icons-material/Score';
import { tokenLess } from '../../../config';
                        
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext'; // Import useAppContext

const showPoints = false

const SidebarElement = () => {
    const {
        changeView,
        chainName,
        outputNumber,
        IDTBalance,
        GovernanceTokenTotalBalanceAllChains,
        marketLive
    } = useAppContext();

    const [disclaimerModalOpen,setDisclaimerModalOpen] = useState(false);
    
    
    
    

    const openDisclaimerModal = () => {
        setDisclaimerModalOpen(true)
    }
    const closeDisclaimerModal = () => {
        setDisclaimerModalOpen(false)
    }
  

    return (
    <div className="" style={{ display: 'flex', minHeight: '100vh' }}>
        
        
        <div className = "sidebar">
            <Link to="/">
                <div className="sidebarLogo">
                    <img className="" src={logoWide} alt="TWIN" width="163px"/>
                </div>
            </Link>
            <div className='sideBarMenu'>
                <Link to="/">
                    <div className="menuItem" type="button" >
                        <div className="" style={{}} ><GridViewOutlinedIcon/>&nbsp;&nbsp;Dashboard</div>
                    </div>
                </Link>
                <Link to="/portfolio">
                    <div className="menuItem" type="button">
                        <div className="" style={{}}><WorkOutlineOutlinedIcon/>&nbsp;&nbsp;Portfolio</div>
                    </div>
                </Link>
                    
                <Link to="/mint">
                <div className="menuItem" type="button">
                    <div className="" style={{}}>
                        <GavelRoundedIcon/>&nbsp;&nbsp;Mint
                    </div>
                </div>
                </Link>

                {marketLive ?
                    <Link to="/pool">
                        <div className="menuItem" type="button">
                            <div className="" style={{}}><PieChartRoundedIcon/>&nbsp;&nbsp;Pool</div>
                        </div>
                    </Link>
                    :
                    <div className="menuItem text-gray" type="button">
                        <div className="" style={{}}><PieChartRoundedIcon/>&nbsp;&nbsp;Pool</div>
                    </div>
                }
                {showPoints ?
                    <Link to="/farm">
                        <div className="menuItem" type="button">
                            <div className="" style={{}}><AgricultureOutlinedIcon/>&nbsp;&nbsp;Farm</div>
                        </div>
                    </Link>
                :''}
                {marketLive ?
                    <Link to="/trade">
                        <div className="menuItem" type="button">
                            <div className="" style={{}}><AttachMoneyOutlinedIcon/>&nbsp;&nbsp;Trade</div>
                        </div>
                    </Link>
                    :
                  
                        <div className="menuItem text-gray" type="button">
                            <div className="" style={{}}><AttachMoneyOutlinedIcon/>&nbsp;&nbsp;Trade</div>
                        </div>
                    
                  
                }
                <Link to="/redeem">
                    <div className="menuItem" type="button">
                        <div><PaymentsOutlinedIcon/>&nbsp;&nbsp;Redeem</div>
                    </div>
                </Link>
                { !tokenLess ?
                    <Link to="/governance">
                        <div className="menuItem" type="button">
                            <div><AccountBalanceOutlinedIcon/>&nbsp;&nbsp;Governance</div>
                        </div>
                    </Link>
                    :
                    ''
                }
                <Link to="/points">
                    <div className="menuItem" type="button">
                        <div><ScoreIcon/>&nbsp;&nbsp;Points</div>
                    </div>
                </Link>
                
                <div className='menuItem'>
                    <a href="https://twin-finance.medium.com/" target="_blank" rel="noreferrer">
                        <ChatBubbleOutlineOutlinedIcon/>&nbsp;&nbsp;Blog 
                    </a>
                </div>
                <div>&nbsp;</div>
                                                
                        
                        {(chainName === 'Berachain' || chainName === 'Berachain BArtio') ? 
                            <div className="w-100 pr-4 pl-4 pb-2">
                                <div type="button" className="w-100 menuButtonGreen">{chainName}</div>
                            </div>
                            :
                            ''
                        }

                        {(chainName === 'Berachain' || chainName === 'Berachain BArtio') && typeof GovernanceTokenTotalBalanceAllChains !== "undefined" && IDTBalance < 1  & !tokenLess ?
                            <Link to="/TWIN">
                                <div className="w-100 pr-4 pl-4 pb-2">
                                    <div className="menuButtonTWIN">
                                        <div className='menuButtonTWIN1'>$TWIN</div>
                                        <div className='menuButtonTWIN2'>{outputNumber(parseFloat(GovernanceTokenTotalBalanceAllChains),0)}</div>
                                        
                                    </div>
                                </div>
                            </Link>
                            :
                            ''
                        }
                        {(chainName === 'Berachain'  || chainName === 'Berachain BArtio') && typeof GovernanceTokenTotalBalanceAllChains !== "undefined" && IDTBalance > 0 & !tokenLess ? 
                            <Link to="/TWIND">
                                <div className="w-100 pr-4 pl-4 pb-2">
                                    <div className="menuButtonTWIND">     
                                        TWIND Conversion
                                    </div>
                                </div>
                            </Link>
                            
                            :
                            ''
                        }
                    <div className="sidebarPanda">
                        <img className="" src={panda} alt="Panda" width="170px"/>
                    </div>
            </div>
            
            
                
                
            
        </div>
    </div>
    )
    }
    export default SidebarElement;
