// src/components/Pages/Mainpage/Mainpage.jsx
import '../../../styles/mainpage.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { tokenLess } from '../../../config';
import AssetLogo from '../../Common/AssetLogo';
import useMainpageLogic from './useMainpageLogic';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Mainpage = () => {
  const {
    assets,
    outputNumber,
    ISSPrice,
    totalLockedValue,
    ISSMarketCapCurrent,
    marketLive,
    highestYieldingAsset,
    mintedAssetVolume,
    poolOutput,
    renderTooltip1,
  } = useMainpageLogic();

  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const filteredPools = poolOutput
    ? poolOutput.filter(
        (pool) =>
          pool.symbol.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          pool.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
    : [];

  const sortedPools = [...filteredPools].sort((a, b) => {
    if (!sortColumn) return 0;

    let valueA, valueB;
    switch (sortColumn) {
      case 'name':
        valueA = a.name.toLowerCase();
        valueB = b.name.toLowerCase();
        return sortDirection === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      case 'tvl':
        valueA = marketLive ? parseFloat(a.poolLiquidityUSD || 0) : parseFloat(a.yourStake || 0);
        valueB = marketLive ? parseFloat(b.poolLiquidityUSD || 0) : parseFloat(b.yourStake || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      case 'yield':
        if (marketLive) return 0;
        valueA = parseFloat(a.apr || 0);
        valueB = parseFloat(b.apr || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      default:
        return 0;
    }
  });

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Render sort indicator (always show, style based on active sort)
  const renderSortIndicator = (column) => {
    const isActive = sortColumn === column;
    const isAsc = sortDirection === 'asc';
    return isActive && !isAsc ? (
      <ArrowDownwardIcon
        fontSize="small"
        className={`sortIcon ${isActive ? 'sortIconActive' : ''}`}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        className={`sortIcon ${isActive ? 'sortIconActive' : ''}`}
      />
    );
  };

  return (
    <div className="mainContainer">
      <div className="middleBoxFull">
        <div className="contentContainer">
          <div className="infoRow">
            {!tokenLess && (
              <div className="infoBox">
                <div className="infoBoxIcon">
                  <AttachMoneyOutlinedIcon fontSize="inherit" />
                </div>
                <div className="infoBoxItem">
                  ${outputNumber(parseFloat(ISSPrice), 3) > 0 ? outputNumber(parseFloat(ISSPrice), 3) : <span>...</span>}
                </div>
                <div className="infoBoxItem2">TWIN Price</div>
              </div>
            )}
            <div className="infoBox">
              <div className="infoBoxIcon">
                <WorkOutlineOutlinedIcon fontSize="inherit" />
              </div>
              <div className="infoBoxItem">{typeof assets !== 'undefined' ? assets.length * 2 : <span>...</span>}</div>
              <div className="infoBoxItem2">Assets</div>
            </div>
            {marketLive ? (
              <div className="infoBox">
                <div className="infoBoxIcon">
                  <AttachMoneyOutlinedIcon fontSize="inherit" />
                </div>
                <div className="infoBoxItem">
                  ${typeof totalLockedValue !== 'undefined' ? outputNumber(totalLockedValue / 1e6, 2) : <span>...</span>}m
                </div>
                <div className="infoBoxItem2">Value locked</div>
              </div>
            ) : (
              <div className="infoBox">
                <div className="infoBoxIcon">
                  <AttachMoneyOutlinedIcon fontSize="inherit" />
                </div>
                <div className="infoBoxItem">
                  ${typeof mintedAssetVolume !== 'undefined' ? outputNumber(mintedAssetVolume / 1e6, 5) : <span>...</span>}m
                </div>
                <div className="infoBoxItem2">Minted Asset Volume</div>
              </div>
            )}
            {!marketLive && (
              <div className="infoBox">
                <div className="infoBoxIcon">
                  <AgricultureIcon fontSize="inherit" />
                </div>
                <div className="infoBoxItem">
                  {typeof highestYieldingAsset !== 'undefined' ? outputNumber(highestYieldingAsset[2] * 100, 0) : <span>...</span>}%
                </div>
                <div className="infoBoxItem2">
                  Top farm: {typeof highestYieldingAsset !== 'undefined' ? highestYieldingAsset[0] : <span>...</span>}
                </div>
              </div>
            )}
            {!tokenLess && (
              <div className="infoBox">
                <div className="infoBoxIcon">
                  <PieChartRoundedIcon fontSize="inherit" />
                </div>
                <div className="infoBoxItem">
                  ${typeof assets !== 'undefined' ? outputNumber(ISSMarketCapCurrent / 1e6, 2) : <span>...</span>}m
                </div>
                <div className="infoBoxItem2">TWIN Market Cap</div>
              </div>
            )}
          </div>

          <div className="mainpageDashboard">
            <div className="col p-3 pl-4 pb-0">
              <div className="headlineSearchWrapper">
                <div className="mainpageHeadline">TWIN Assets</div>
                <div className="searchContainer">
                  <SearchIcon className="searchIcon" />
                  <input
                    type="text"
                    placeholder="Search assets..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="searchInput"
                  />
                </div>
              </div>
              <div className="">
                <table className="mainTable w-100">
                  <thead>
                    <tr>
                      <th scope="col">Symbol</th>
                      <th
                        className="text-right sortable"
                        scope="col"
                        onClick={() => handleSort('name')}
                        style={{ cursor: 'pointer' }}
                      >
                        Name {renderSortIndicator('name')}
                      </th>
                      {marketLive && (
                        <th className="text-right" scope="col">
                          Price
                        </th>
                      )}
                      <th className="text-right" scope="col">
                        Upper Limit
                        <OverlayTrigger placement="right" overlay={renderTooltip1}>
                          <InfoOutlinedIcon
                            fontSize="small"
                            style={{ color: '#086A24', marginLeft: '0.5rem', cursor: 'help' }}
                          />
                        </OverlayTrigger>
                      </th>
                      {marketLive && (
                        <th className="text-right" scope="col">
                          Pool liq.(USD)
                        </th>
                      )}
                      {marketLive ? (
                        <th
                          className="text-right sortable"
                          scope="col"
                          onClick={() => handleSort('tvl')}
                          style={{ cursor: 'pointer' }}
                        >
                          Your stake (USD) {renderSortIndicator('tvl')}
                        </th>
                      ) : (
                        <th
                          className="text-right sortable"
                          onClick={() => handleSort('tvl')}
                          style={{ cursor: 'pointer' }}
                        >
                          TVL {renderSortIndicator('tvl')}
                        </th>
                      )}
                      {!marketLive && (
                        <th
                          className="text-right sortable"
                          scope="col"
                          onClick={() => handleSort('yield')}
                          style={{ cursor: 'pointer' }}
                        >
                          Yield (APR) {renderSortIndicator('yield')}
                        </th>
                      )}
                      {marketLive && <th className="text-right" scope="col"></th>}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedPools.length > 0 ? (
                      sortedPools.map((pool) => (
                        <tr key={pool.key}>
                          <td>
                            <div className="firstRow">
                              <AssetLogo symbol={pool.symbol} /> {pool.symbol}
                            </div>
                          </td>
                          <td>
                            <div className="text-right">{pool.name}</div>
                          </td>
                          {marketLive && <td className="text-right">{pool.price}</td>}
                          <td className="text-right">{pool.upperLimit}</td>
                          {marketLive && <td className="text-right">{pool.poolLiquidity}</td>}
                          {marketLive ? (
                            <td className="text-right">{pool.poolLiquidityUSD}</td>
                          ) : (
                            <td className="text-right">{pool.yourStake}$</td>
                          )}
                          {!marketLive && (
                            <td className="text-right">
                              {pool.isHighestYield && <span>🔥 </span>}
                              {pool.apr}%
                            </td>
                          )}
                          {marketLive ? (
                            <td>
                              <div className="d-flex flex-row">
                                <Link to={pool.tradeLinkBuy}>
                                  <div className="buyButton">Buy</div>
                                </Link>
                                <Link to={pool.tradeLinkSell}>
                                  <div className="sellButton">Sell</div>
                                </Link>
                              </div>
                            </td>
                          ) : (
                            pool.symbol !== 'USDC' &&
                            pool.symbol !== 'TWIN' && (
                              <td>
                                <div className="d-flex flex-row">
                                  <Link to={pool.mintLink}>
                                    <div className="mintButton">Mint</div>
                                  </Link>
                                  <Link to={pool.redeemLink}>
                                    <div className="redeemButton">Redeem</div>
                                  </Link>
                                </div>
                              </td>
                            )
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8">No assets found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="buffer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mainpage;