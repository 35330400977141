import React from 'react';
import { useAppContext } from '../../../context/AppContext'; // Import the context hook

const PointsSummary = () => {
  const { pointsBreakdown, myPoints } = useAppContext();
  console.log(myPoints)
  console.log(pointsBreakdown)
  return (
    <div className="summary-section">
      <div className="summary-card">
        <div className="points-breakdown">
          <div className="breakdown-item">
            <span>TWIN Troopers Campaign: </span>
            <span>{pointsBreakdown['TWIN Troopers Campaign'] ? `${pointsBreakdown['TWIN Troopers Campaign']}` : '0'}  pts</span>
          </div>
          <div className="breakdown-item">
            <span>Farming: </span>
            <span>{pointsBreakdown['Farming'] ? `${pointsBreakdown['Farming']}` : '0'}  pts</span>
          </div>
          <div className="breakdown-item">
            <span>Referrals: </span>
            <span>{pointsBreakdown['Referral'] ? `${pointsBreakdown['Referrals']}` : '0'}  pts</span>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointsSummary;