import React, { useState, useEffect } from 'react';

const EpochTimer = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [activeEpoch, setActiveEpoch] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEpochData = async () => {
      try {
        const response = await fetch('https://aapi.twinfinance.io/epochs');
        if (!response.ok) throw new Error('Failed to fetch epoch data');
        
        const data = await response.json();
        
        if (data.current_epoch) {
          const epochEnd = new Date(data.current_epoch.end_time);
          setEndTime(epochEnd);
          calculateTimeLeft(epochEnd);
          setActiveEpoch(data.current_epoch.id)
        } else {
          setError('No active epoch found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEpochData();
  }, []);

  useEffect(() => {
    if (!endTime) return;

    const timer = setInterval(() => {
      calculateTimeLeft(endTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [endTime]);

  const calculateTimeLeft = (endDate) => {
    const now = new Date().getTime();
    const difference = endDate.getTime() - now;
    setTimeLeft(Math.max(0, Math.floor(difference / 1000)));
  };

  const formatTime = (seconds) => {
    if (seconds === null || seconds === undefined) return '--';
    
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    
    return `${days}d ${hours}h ${minutes}m ${secs}s`;
  };

  if (loading) {
    return <div className="epoch-timer">Loading epoch data...</div>;
  }

  if (error) {
    return <div className="epoch-timer error">Error: {error}</div>;
  }

  if (!endTime) {
    return <div className="epoch-timer">No active epoch running</div>;
  }

  return (
    <>
      <div className="stat-box">
        <span className="stat-label">Current epoch</span>
        <span className="stat-value">
          {activeEpoch ? `${activeEpoch}` : 'Loading'}
        </span>
      </div>
      <div className="stat-box">
        <span className="stat-label">Time remaining</span>
        <span className="stat-value">
          {timeLeft ? `${formatTime(timeLeft)}` : 'Loading'}
        </span>
      </div>
                  
    
    </>
  );
};

export default EpochTimer;