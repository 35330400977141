import { useLocation } from 'react-router-dom';
import Web3 from 'web3';
import React, { createContext, useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { tokenLess, USDDecimals, stableCoinName } from '../config.js';
import { readContractData } from '../functions/Functions.jsx';
import {
  USDC_ABI,
  assetFactory_ABI,
  ERC20_ABI,
  GovernanceToken_ABI,
  MarketFactory_ABI,
  MarketRouter_ABI,
  MarketPair_ABI,
  RewardsMachine_ABI,
  DAO_ABI,
  Upgrader_ABI,
  VotingEscrow_ABI,
} from '../config.js';
import {
  USDC_Address_b,
  IDT_Address_b,
  assetFactory_Address_b,
  GovernanceToken_Address_b,
  MarketFactory_Address_b,
  MarketRouter_Address_b,
  RewardsMachine_Address_b,
  DAO_Address_b,
  Upgrader_Address_b,
  VotingEscrow_Address_b,
} from '../config.js';
import { useAccount, useChainId } from 'wagmi';
import { checkIn } from '../functions/api.js';
import {
  timeStampToDate,
  outputNumber,
  timeStampToDateAndTime,
  sleep,
  roundDown,
} from '../functions/Functions.jsx';
import {
  getUserData,
  getGovernanceTokenData,
  getAssetData,
  getPrice,
  getAssetBalances,
  getLPBalances,
  getAssetBalancesFromTicker,
  getLPBalancesFromTicker,
} from '../functions/BlockchainData.jsx';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const location = useLocation();
  console.log(location);
  // Define excluded paths where blockchain data should not load
  const excludedPaths = ['/TOU', '/Privacy']; // Add more paths as needed

  const urlParams = new URLSearchParams(location.search);
  const refCode = urlParams.get('ref');
  const marketLive = false;

  // State Variables
  const [activePage, setActivePage] = useState('mainpage');
  const [address, setAddress] = useState();
  const [assetDetails, setAssetDetails] = useState();
  const [assetFactory, setAssetFactory] = useState();
  const [assetFactory_Address, setAssetFactory_Address] = useState();
  const [assets, setAssets] = useState();
  const [assetValue, setAssetValue] = useState();
  const [chainData, setChainData] = useState();
  const [chainName, setChainName] = useState();
  const [chainDetailsLoaded, setChainDetailsLoaded] = useState(false);
  const [blockchainDataLoaded, setblockchainDataLoaded] = useState(false);
  const [fullProtocolIntegration, setFullProtocolIntegration] = useState(false);
  const [DAO_Address, setDAO_Address] = useState();
  const [expiredAssets, setExpiredAssets] = useState();
  const [GovernanceToken, setGovernanceToken] = useState();
  const [GovernanceToken_Address, setGovernanceToken_Address] = useState();
  const [GovernanceTokenBalance, setGovernanceTokenBalance] = useState();
  const [GovernanceTokenTotalBalance, setGovernanceTokenTotalBalance] = useState();
  const [GovernanceTokenTotalBalanceAllChains, setGovernanceTokenTotalBalanceAllChains] = useState();
  const [GovernanceTokenStakeBalance, setGovernanceTokenStakeBalance] = useState();
  const [GovernanceTokenVestedStakeBalance, setGovernanceTokenVestedStakeBalance] = useState();
  const [IDT_nm, setIDT_nm] = useState();
  const [IDTBalance, setIDTBalance] = useState();
  const [IDTBalanceWei, setIDTBalanceWei] = useState();
  const [ISSPrice, setISSPrice] = useState();
  const [ISSMarketCap, setISSMarketCap] = useState();
  const [ISSMarketCapCurrent, setISSMarketCapCurrent] = useState();
  const [ISSSupply, setISSSupply] = useState();
  const [ISSSupplyCurrent, setISSSupplyCurrent] = useState();
  const [liveAssets, setLiveAssets] = useState();
  const [loadingBlockchainData, setLoadingBlockchainData] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [lockDate, setLockDate] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [LPValue, setLPValue] = useState(true);
  const [MarketFactory_Address, setMarketFactory_Address] = useState();
  const [MarketRouter_Address, setMarketRouter_Address] = useState();
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [messageBoxHeader, setMessageBoxHeader] = useState('');
  const [messageBoxFooter, setMessageBoxFooter] = useState('');
  const [messageBoxContent, setMessageBoxContent] = useState('');
  const [pageLoadedMainpage, setPageLoadedMainpage] = useState(false);
  const [pools, setPools] = useState([]); // Initialize as empty array
  const [RewardsMachine_Address, setRewardsMachine_Address] = useState();
  const [showAccount, setShowAccount] = useState(false);
  const [hasLoadedMainpage, setHasLoadedMainpage] = useState(false);
  const [slippage, setSlippage] = useState(() => {
    const savedSlippage = localStorage.getItem('marketSlippage');
    return savedSlippage !== null ? parseFloat(savedSlippage) * 100 : 50; // Default 0.5% (50 basis points)
  });
  const [trxTime, setTrxTime] = useState(() => {
    const savedTrxTime = localStorage.getItem('marketTrxTime');
    return savedTrxTime !== null ? parseInt(savedTrxTime) : 20; // Default 20 minutes
  });
  const [testnet, setTestnet] = useState();
  const [totalLockedValue, setTotalLockedValue] = useState();
  const [totalValue, setTotalValue] = useState();
  const [TotalVeISSSupply, setTotalVeISSSupply] = useState();
  const [txhash, setTxhash] = useState('');
  const [uData, setUData] = useState();
  const [Upgrader_Address, setUpgrader_Address] = useState();
  const [USDC_Address, setUSDC_Address] = useState();
  const [USDCAllowance, setUSDCAllowance] = useState();
  const [USDCBalance, setUSDCBalance] = useState(0);
  const [veISSBalance, setVeISSBalance] = useState();
  const [VotingEscrow_Address, setVotingEscrow_Address] = useState();
  const [web3_nm, setWeb3_nm] = useState();
  const [wrongNetworkMessage, setWrongNetworkMessage] = useState(false);
  const [geoLocationOk, setGeoLocationOk] = useState(true);
  const [myRefCode, setMyRefCode] = useState();
  const [referralData, setReferralData] = useState();
  const [showTerms, setShowTerms] = useState(false);
  const [highestYieldingAsset, setHighestYieldingAsset] = useState([]);
  const [mintedAssetVolume, setMintedAssetVolume] = useState();

  // Smart Contracts
  const [assetFactory_nm, setAssetFactory_nm] = useState();
  const [USDC_nm, setUSDC_nm] = useState();
  const [GovernanceToken_nm, setGovernanceToken_nm] = useState();
  const [MarketRouter_nm, setMarketRouter_nm] = useState();
  const [MarketFactory_nm, setMarketFactory_nm] = useState();
  const [RewardsMachine_nm, setRewardsMachine_nm] = useState();
  const [DAO_nm, setDAO_nm] = useState();
  const [Upgrader_nm, setUpgrader_nm] = useState();
  const [VotingEscrow_nm, setVotingEscrow_nm] = useState();
  const [myPoints, setMyPoints] = useState();
  const [myRank, setMyRank] = useState();
  const [pointsBreakdown, setPointsBreakdown] = useState();

  const chainId = useChainId();
  console.log('Current chainId:', chainId);

  // Persistence effect (optional if you want to sync context -> localStorage on change)
  useEffect(() => {
    localStorage.setItem('marketSlippage', slippage / 100); // Store as percentage
  }, [slippage]);

  useEffect(() => {
    localStorage.setItem('marketTrxTime', trxTime);
  }, [trxTime]);


  const { address: connectedAddress, isConnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      console.log('onConnect triggered:', { address, connector, isReconnected });
      setAddress(address);
      setLoggedIn(true);
    },
    onDisconnect() {
      console.log('onDisconnect triggered');
      setAddress(undefined);
      setLoggedIn(false);
      setUSDCBalance(0);
      setGovernanceTokenBalance(0);
      setGovernanceTokenStakeBalance(0);
      setGovernanceTokenTotalBalance(0);
      setGovernanceTokenTotalBalanceAllChains(0);
      setVeISSBalance(0);
      setLockDate(0);
      setIDTBalance(0);
      setAssetValue(0);
      setLPValue(0);
      setTotalValue(0);
      const updatedAssetDetails = { ...assetDetails };
      for (const key in updatedAssetDetails) {
        updatedAssetDetails[key] = {
          ...updatedAssetDetails[key],
          tokenBalance1: 0,
          tokenBalance2: 0,
        };
      }
      setAssetDetails(updatedAssetDetails);
      const updatedPools = pools.map(pool => {
        const newPool = [...pool];
        newPool[4] = 0;
        return newPool;
      });
      setPools(updatedPools);
    },
  });
  
  // Debug log to monitor state
  console.log('AppContext - address:', connectedAddress, 'isConnected:', isConnected, 'loggedIn:', loggedIn);
  
  // Sync address and loggedIn with useAccount state
  useEffect(() => {
    setAddress(connectedAddress);
    setLoggedIn(isConnected);
  }, [connectedAddress, isConnected]);

  // Sync address and loggedIn with useAccount state
  useEffect(() => {
    setAddress(connectedAddress);
    setLoggedIn(isConnected);
  }, [connectedAddress, isConnected]);

  // Load chain details on component mount
  useEffect(() => {
    getChainDetails();
  }, []);
  // test this
  // Load mainpage when chain details are loaded
  useEffect(() => {
    if (chainDetailsLoaded && !excludedPaths.includes(location.pathname)) {
      loadMainpage();
      setHasLoadedMainpage(true);
    } else if (excludedPaths.includes(location.pathname)) {
    // Ensure loading states are reset for excluded paths
    setLoadingBlockchainData(false);
    setPageLoadedMainpage(true); // Set to true to avoid blocking UI, but no data loaded
    setblockchainDataLoaded(false);
    }
  }, [chainDetailsLoaded]);
  useEffect(() => {
    if (chainDetailsLoaded && !excludedPaths.includes(location.pathname) && !hasLoadedMainpage) {
      loadMainpage();
      setHasLoadedMainpage(true);
    }
  }, [location.pathname, chainDetailsLoaded, hasLoadedMainpage]);
  
  // Load user-specific data when address changes and page is loaded
  useEffect(() => {
    if (address && pageLoadedMainpage) {
      loadUserSpecificData();
    }
  }, [address, pageLoadedMainpage]);

  useEffect(() => {
    const handleCheckIn = async () => {
      console.log('Address:', address);
      if (address) {
        console.log('Debugging useEffect 1');
        console.log('Checking in');
        console.log(address);
        console.log('refcode:' + refCode);
        try {
          let result = await checkIn(address, refCode);
          console.log(result);
          if (result.success === false) {
            console.log('API error. Skipping check in.');
            return;
          }
          if (result.data.regionValid) {
            setGeoLocationOk(true);
          }
          if (!result.data.termsAccepted) {
            setShowTerms(true);
          }
          if (!result.data.regionValid) {
            console.log('blocked location');
            setGeoLocationOk(false);
          }
          setMyRefCode(result.data.refCode);
        } catch (error) {
          console.error('Error checking in:', error);
        }
      }
    };
    handleCheckIn();
  }, [address, refCode]);

  useEffect(() => {
    console.log('Path changed to:', location.pathname);
    if (location.pathname === '/') {
      setActivePage('Dashboard');
    }
    if (location.pathname === '/portfolio') {
      setActivePage('Portfolio');
    }
    if (location.pathname === '/mint') {
      setActivePage('Mint assets');
    }
    if (location.pathname.startsWith('/pool')) {
      setActivePage('Pools');
    }
    if (location.pathname === '/trade') {
      setActivePage('Trade');
    }
    if (location.pathname === '/redeem') {
      setActivePage('Redeem');
    }
    if (location.pathname === '/governance') {
      setActivePage('Governance');
    }
    if (location.pathname === '/TWIND') {
      setActivePage('TWIND Conversion');
    }
    if (location.pathname === '/TWIN') {
      setActivePage('TWIN Token');
    }
    if (location.pathname.startsWith('/trade')) {
      setActivePage('TWIN Trade');
    }
    if (location.pathname.startsWith('/points')) {
      setActivePage('Points');
    }
    if (location.pathname.startsWith('/TOU')) {
      setActivePage('Terms of Use');
    }
    if (location.pathname.startsWith('/Privacy')) {
      setActivePage('Privacy Policy');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (pageLoadedMainpage && blockchainDataLoaded) {
      console.log('Debugging useEffect 6');
      console.log('Asset details or blockchain data changed, re-rendering Portfolio...');
      updateAssetDetails(assetDetails);
    }
  }, [pageLoadedMainpage, blockchainDataLoaded, assetDetails, assets]);

  useEffect(() => {
    if (
      GovernanceTokenBalance !== undefined &&
      GovernanceTokenStakeBalance !== undefined &&
      loadingBlockchainData === false
    ) {
      console.log('Debugging useEffect 7');
      console.log('updating asset value');
      console.log(assetDetails);
      console.log(GovernanceTokenBalance);
      console.log(USDCBalance);
      updateAssetValue();
    }
  }, [GovernanceTokenBalance, GovernanceTokenStakeBalance]);

  useEffect(() => {
    if (
      tokenLess &&
      USDCBalance !== undefined &&
      assetDetails !== undefined &&
      loadingBlockchainData === false
    ) {
      console.log('Debugging useEffect 8');
      console.log('updating asset value');
      console.log(assetDetails);
      console.log(USDCBalance);
      updateAssetValue();
    }
  }, [USDCBalance, assetDetails]);

  useEffect(() => {
    if (loadingBlockchainData === false) {
      console.log('Debugging useEffect 9');
      console.log(assetValue);
      updatePortfolioValue();
    }
  }, [assetValue]);

  const updateAssetDetails = (newAssetDetails) => {
    setAssetDetails(newAssetDetails);
    console.log('Asset details updated:', newAssetDetails);
  };

  // Functions to update settings
  const saveSlippagePreference = async (slippageValue) => {
    setSlippage(slippageValue); // slippageValue in basis points (e.g., 50 for 0.5%)
    // localStorage.setItem('marketSlippage', slippageValue / 100); // Moved to useEffect
  };

  const saveTrxTimePreference = async (trxTimeValue) => {
    setTrxTime(trxTimeValue);
    // localStorage.setItem('marketTrxTime', trxTimeValue); // Moved to useEffect
  };

  function changeView(_page) {
    setActivePage(_page);
  }

  async function updateUserData() {
    setLoadingData(true);
    setLoadingBlockchainData(true);
    var _uData = await getUserData(address);
    setIDTBalance(_uData.DistributionTokenBalance);

    let _USDCBalance = _uData.StableCoinBalance;
    await setUSDCBalance(_USDCBalance);

    let _GovernanceTokenBalance = _uData.GovernanceTokenBalance;
    setGovernanceTokenBalance(_GovernanceTokenBalance);

    let _GovernanceTokenStakeBalance = _uData.lockedBalance;
    let _veISSBalance = _uData.veBalance;
    let _lockDate = _uData.lockTime;
    setVeISSBalance(_veISSBalance);
    setLockDate(_lockDate);
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance);
    let _GovernanceTokenTotalBalance =
      parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance);
    let _GovernanceTokenTotalBalanceAllChains = _GovernanceTokenTotalBalance;
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance);
    setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalanceAllChains);
    console.log('User for TWIN and HONEY data updated');
    setLoadingData(false);
    setLoadingBlockchainData(false);
  }

  async function loadMainpage() {
    setLoadingData(true);
    setblockchainDataLoaded(false);
    setPageLoadedMainpage(false);
    console.log('Loading mainpage');

    if (!tokenLess) {
      let governanceTokenData = await getGovernanceTokenData();
      let _ISSSupply = governanceTokenData.GovernanceTokenTotalSupply;
      setISSSupply(_ISSSupply);
      let _ISSSupplyCurrent = governanceTokenData.GovernanceTokenCurrentSupply;
      console.log(_ISSSupplyCurrent);
      setISSSupplyCurrent(_ISSSupplyCurrent);
      let _totalVeISSSupply = governanceTokenData.TotalVeSupply;
      setTotalVeISSSupply(_totalVeISSSupply);

      let ISSData = await getPrice(GovernanceToken_Address);
      setISSPrice(ISSData[0]);
      setISSMarketCap(ISSData[1]);
      setISSMarketCapCurrent(ISSData[0] * _ISSSupplyCurrent);
    }

    let _assets = [];
    let assetData = await getAssetData('berachain');
    let _pools = assetData[0].map(pool => {
      const newPool = [...pool];
      newPool[4] = 0;
      return newPool;
    });
    let _assetDetails = {};
    for (const key in assetData[1]) {
      _assetDetails[key] = {
        ...assetData[1][key],
        tokenBalance1: 0,
        tokenBalance2: 0,
      };
    }
    setAssetDetails(_assetDetails);
    setPools(_pools);
    setHighestYieldingAsset(assetData[2]);
    setMintedAssetVolume(assetData[3]);

    for (const key of Object.keys(_assetDetails)) {
      _assets.push(key);
    }
    console.log(_assets);
    setAssets(_assets);

    let _totalLockedValue = 0;
    for (let i = 0; i < _pools.length; i++) {
      _totalLockedValue += _pools[i][2];
    }
    setTotalLockedValue(_totalLockedValue);

    setLoadingBlockchainData(false);
    setPageLoadedMainpage(true);
    setblockchainDataLoaded(true);
    setLoadingData(false);
  }

  async function loadUserSpecificData() {
    if (!address) return;
    setLoadingData(true);
    console.log('Loading user-specific data');

    var _uData = await getUserData(address);
    setUSDCBalance(_uData.StableCoinBalance || 0);
    setIDTBalance(_uData.DistributionTokenBalance || 0);

    if (!tokenLess) {
      setGovernanceTokenBalance(_uData.GovernanceTokenBalance || 0);
      let _GovernanceTokenStakeBalance = _uData.lockedBalance;
      let _veISSBalance = _uData.veBalance;
      let _lockDate = _uData.lockTime;
      setVeISSBalance(_veISSBalance);
      setLockDate(_lockDate);
      setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance);
      let _GovernanceTokenTotalBalance =
        parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_uData.GovernanceTokenBalance || 0);
      setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance);
      setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalance);
    }

    await loadAssetBalances();
    await loadLPBalances();
    

    setLoadingData(false);
  }

  async function updateAssetValue() {
    setLoadingData(true);
    let _assetValue = 0;
    let keys = Object.keys(assetDetails || {});
    keys.forEach((key) => {
      const value = assetDetails[key];
      console.log(value);
      _assetValue +=
        parseFloat(value['priceLong']) * parseFloat(value['tokenBalance1']) +
        parseFloat(value['priceShort']) * parseFloat(value['tokenBalance2']);
    });
    console.log(_assetValue);
    if (isNaN(_assetValue)) {
      setLoadingData(false);
      return;
    }
    if (!tokenLess) {
      _assetValue += parseFloat(GovernanceTokenTotalBalance) * parseFloat(ISSPrice) + parseFloat(USDCBalance);
    } else {
      _assetValue += parseFloat(USDCBalance);
    }

    console.log(_assetValue);
    console.log(GovernanceTokenTotalBalance * ISSPrice);
    console.log(USDCBalance);
    setAssetValue(_assetValue);
    setLoadingData(false);
  }

  async function loadAssetBalances() {
    if (!address) return;
    console.log('Loading Asset Balances');
    setLoadingData(true);
    let assetBalances = await getAssetBalances(address);

    let _assetDetails = { ...assetDetails };

    assetBalances.forEach((balance) => {
      _assetDetails[balance[0]] = {
        ..._assetDetails[balance[0]],
        tokenBalance1: balance[1],
        tokenBalance2: balance[2],
      };
    });
    console.log(assetBalances);
    console.log(_assetDetails);

    setAssetDetails(_assetDetails);

    let _assetValue = 0;
    let keys = Object.keys(_assetDetails);
    keys.forEach((key) => {
      const value = _assetDetails[key];
      _assetValue += value['priceLong'] * value['tokenBalance1'] + value['priceShort'] * value['tokenBalance2'];
    });
    if (!tokenLess) {
      _assetValue += GovernanceTokenTotalBalance * ISSPrice + USDCBalance;
    } else {
      _assetValue += USDCBalance;
    }
    console.log(_assetValue);
    setAssetValue(_assetValue);
    setLoadingData(false);
  }

  async function loadLPBalances() {
    if (!address || !pools || pools.length === 0) {
      console.log('Skipping LP balances load: no address or pools not loaded');
      return;
    }
    setLoadingData(true);
    console.log('Loading LP Balances');
    let _LPValue = 0;
    let lpBalances = await getLPBalances(address);

    let _pools = pools.map((pool) => [...pool]);

    for (let i = 0; i < _pools.length; i++) {
      let asset = _pools[i][0];
      let lpBalance = lpBalances[asset];
      console.log(lpBalance);
      if (lpBalance !== undefined) {
        _pools[i][4] = lpBalance * 1e18;
        _LPValue += (lpBalance * 1e18) * _pools[i][2] / _pools[i][5];
      }
    }
    console.log(_pools);
    console.log(_LPValue);
    setLPValue(_LPValue);
    setTotalValue(_LPValue + assetValue);

    setPools(_pools);
    setLoadingData(false);
  }

  async function getChainDetails() {
    setLoadingData(true);
    setChainDetailsLoaded(false);
    let currentChainId;
    try {
      currentChainId = chainId;
    } catch {
      setLoadingBlockchainData(false);
      return;
    }
    console.log('Current Chain ID:', currentChainId);
    let _web3_nm = new Web3(new Web3.providers.HttpProvider('https://rpc.berachain.com/'));
    let _AssetFactory_Address = assetFactory_Address_b;
    let _DAO_Address = DAO_Address_b;
    let _fullProtocolIntegration = true;
    let _GovernanceToken_Address = GovernanceToken_Address_b;
    let _IDT_Address = IDT_Address_b;
    let _MarketFactory_Address = MarketFactory_Address_b;
    let _MarketRouter_Address = MarketRouter_Address_b;
    let _RewardsMachine_Address = RewardsMachine_Address_b;
    let _testnet = false;
    let _Upgrader_Address = Upgrader_Address_b;
    let _USDC_Address = USDC_Address_b;
    let _VotingEscrow_Address = VotingEscrow_Address_b;

    if (currentChainId === 80094 || currentChainId === parseInt('0x138de', 16)) {
      console.log('Berachain detected');
    } else {
      console.log('Unknown chain detected');
      _fullProtocolIntegration = true;
      setLoadingBlockchainData(false);
      setFullProtocolIntegration(false);
      return;
    }

    setWeb3_nm(_web3_nm);
    setChainName(undefined);
    setUSDC_Address(_USDC_Address);
    setGovernanceToken_Address(_GovernanceToken_Address);
    setAssetFactory_Address(_AssetFactory_Address);
    setMarketFactory_Address(_MarketFactory_Address);
    setMarketRouter_Address(_MarketRouter_Address);
    setRewardsMachine_Address(_RewardsMachine_Address);
    setDAO_Address(_DAO_Address);
    setUpgrader_Address(_Upgrader_Address);
    setTestnet(_testnet);
    setFullProtocolIntegration(_fullProtocolIntegration);

    const _USDC_nm = new _web3_nm.eth.Contract(USDC_ABI, _USDC_Address);
    const _assetFactory_nm = new _web3_nm.eth.Contract(assetFactory_ABI, _AssetFactory_Address);
    const _MarketFactory_nm = new _web3_nm.eth.Contract(MarketFactory_ABI, _MarketFactory_Address);
    if (!tokenLess) {
      const _RewardsMachine_nm = new _web3_nm.eth.Contract(RewardsMachine_ABI, _RewardsMachine_Address);
      const _DAO_nm = new _web3_nm.eth.Contract(DAO_ABI, _DAO_Address);
      const _Upgrader_nm = new _web3_nm.eth.Contract(Upgrader_ABI, _Upgrader_Address);
      const _VotingEscrow_nm = new _web3_nm.eth.Contract(VotingEscrow_ABI, _VotingEscrow_Address);
      const _GovernanceToken_nm = new _web3_nm.eth.Contract(GovernanceToken_ABI, _GovernanceToken_Address);
      const _IDT_nm = new _web3_nm.eth.Contract(GovernanceToken_ABI, _IDT_Address);
      setDAO_nm(_DAO_nm);
      setUpgrader_nm(_Upgrader_nm);
      setRewardsMachine_nm(_RewardsMachine_nm);
      setVotingEscrow_nm(_VotingEscrow_nm);
      setGovernanceToken_nm(_GovernanceToken_nm);
      setIDT_nm(_IDT_nm);
    }
    setUSDC_nm(_USDC_nm);
    setAssetFactory_nm(_assetFactory_nm);
    setMarketFactory_nm(_MarketFactory_nm);
    setChainDetailsLoaded(true);
    setLoadingData(false);
  }

  async function loginWeb3() {
    setLoadingData(true);
    setLoadingBlockchainData(true);
    setAddress(address);
    loadMainpage();
    console.log('Blockchain data is loaded');
    setLoadingData(false);
  }

  async function loadLimitedBlockchainData() {
    setLoadingData(true);
    let _IDTBalanceWei = await IDT_nm.methods.balanceOf(address).call();
    setIDTBalanceWei(_IDTBalanceWei);
    let _GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call();
    let _ISSSupplyWei = await GovernanceToken_nm.methods.totalSupply().call();
    let _ISSSupply = parseFloat(web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'));
    setISSSupply(_ISSSupply);
    let _veISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call();
    let _remainingRewards = await GovernanceToken_nm.methods.balanceOf(RewardsMachine_Address).call();
    let _veISSShare = parseFloat(_veISSSupplyWei / (_ISSSupplyWei - _remainingRewards));

    let _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'));
    console.log(_GovernanceTokenBalance);
    setGovernanceTokenBalance(_GovernanceTokenBalance);

    let _userData = await VotingEscrow_nm.methods.userData(address).call();
    let _GovernanceTokenStakeBalanceWei = _userData['_lockedBalance']['amount'];
    let _GovernanceTokenStakeBalance = parseFloat(
      web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether')
    );

    let _veISSBalanceWei = _userData['_balanceVeISS'];
    let _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'));
    let _lockDate = _userData['_lockedBalance']['end'];
    let _totalVeISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call();
    let _totalVeISSSupply = parseFloat(web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'));

    setVeISSBalance(_veISSBalance);
    setLockDate(_lockDate);
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance);
    setTotalVeISSSupply(_totalVeISSSupply);

    let _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance);
    let _GovernanceTokenTotalBalanceAllChains = _GovernanceTokenTotalBalance;
    let _USDCAllowance = await USDC_nm.methods.allowance(address, assetFactory_Address).call();

    setUSDCAllowance(_USDCAllowance);
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance);
    setGovernanceTokenTotalBalanceAllChains(_GovernanceTokenTotalBalanceAllChains);

    setLoadingData(false);
    console.log('Blockchain data is loaded');
  }

  function openMessageBox(message, header = 'Transaction Hash', footer = '') {
    setMessageBoxVisible(true);
    setMessageBoxContent(message);
    setMessageBoxHeader(header);
    setMessageBoxFooter(footer);
  }

  function closeMessageBox() {
    setMessageBoxVisible(false);
  }

  const checkUSDAllowanceAssetFactory = async () => {
    let allowance = await readContractData(chainName, USDC_Address, ERC20_ABI, 'allowance', [
      address,
      assetFactory_Address,
    ]);
    setUSDCAllowance(allowance);
  };

  const loadUSDBalance = async () => {
    setLoadingData(true);
    setLoadingBlockchainData(true);
    await sleep(2000);
    var balanceWEI = await readContractData(chainName, USDC_Address, ERC20_ABI, 'balanceOf', [address]);
    console.log(balanceWEI);
    let balance;
    if (parseInt(USDDecimals) === 6) {
      balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'mwei'));
    } else {
      balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'));
    }
    console.log(balance);
    await setUSDCBalance(balance);

    setLoadingBlockchainData(false);
    setLoadingData(false);
  };

  const updateAssetBalance = async (symbol) => {
    console.log(symbol);
    console.log('Loading new Asset Balance');
    let _assetDetails = assetDetails;
    let updatedBalances = await getAssetBalancesFromTicker(address, symbol);
    let tokenBalanceLong = updatedBalances[0];
    let tokenBalanceShort = updatedBalances[1];
    _assetDetails[symbol]['tokenBalance1'] = parseFloat(tokenBalanceLong);
    _assetDetails[symbol]['tokenBalance2'] = parseFloat(tokenBalanceShort);
    if (marketLive){
      let UpdatedLPBalances = await getLPBalancesFromTicker(address, symbol);
      _assetDetails[symbol]['poolBalanceLong'] = parseFloat(UpdatedLPBalances[0]);
      _assetDetails[symbol]['poolBalanceShort'] = parseFloat(UpdatedLPBalances[1]);
    }
    console.log(_assetDetails);
    setAssetDetails(_assetDetails);
  };

  const updateISSData = async () => {
    let _IDTBalanceWei = await IDT_nm.methods.balanceOf(address).call();
    setIDTBalanceWei(_IDTBalanceWei);
    let _GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call();
    let _ISSSupplyWei = await GovernanceToken_nm.methods.totalSupply().call();
    let _ISSSupply = parseFloat(web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'));
    setISSSupply(_ISSSupply);
    let _veISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call();
    let _remainingRewards = await GovernanceToken_nm.methods.balanceOf(RewardsMachine_Address).call();
    let _veISSShare = parseFloat(_veISSSupplyWei / (_ISSSupplyWei - _remainingRewards));

    let _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'));
    console.log(_GovernanceTokenBalance);
    setGovernanceTokenBalance(_GovernanceTokenBalance);

    let _userData = await VotingEscrow_nm.methods.userData(address).call();
    let _GovernanceTokenStakeBalanceWei = _userData['_lockedBalance']['amount'];
    let _GovernanceTokenStakeBalance = parseFloat(
      web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether')
    );

    let _veISSBalanceWei = _userData['_balanceVeISS'];
    let _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'));
    let _lockDate = _userData['_lockedBalance']['end'];
    let _totalVeISSSupplyWei = await VotingEscrow_nm.methods.totalSupply().call();
    let _totalVeISSSupply = parseFloat(web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'));

    setVeISSBalance(_veISSBalance);
    setLockDate(_lockDate);
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance);
    setTotalVeISSSupply(_totalVeISSSupply);

    return true;
  };

  const updateAssetBalanceWithAddress = async (_address) => {
    setLoadingBlockchainData(true);
    console.log('Loading new Asset Balance');
    if (_address === GovernanceToken_Address) {
      await updateISSData();
      setLoadingBlockchainData(false);
      return;
    }
    let tickerData = await getTickerForAddress(_address);
    console.log(tickerData);
    let symbol = tickerData[0];
    if (symbol !== '') {
      await updateAssetBalance(symbol);
      setLoadingBlockchainData(false);
      return;
    } else {
      console.log('Symbol not found');
      setLoadingBlockchainData(false);
      return;
    }
  };

  const updateLPPair = async (_tokenAddress) => {
    setLoadingBlockchainData(true);
    console.log('Token Address:' + _tokenAddress);
    console.log(USDC_Address);
    let pair = await readContractData(chainName, MarketFactory_Address, MarketFactory_ABI, 'getPair', [
      _tokenAddress,
      USDC_Address,
    ]);
    console.log(pair);
    let reserves = await readContractData(chainName, pair, MarketPair_ABI, 'getReserves', []);
    let userBalanceWEI = await readContractData(chainName, pair, MarketPair_ABI, 'balanceOf', [address]);
    var userBalance = parseFloat(web3_nm.utils.fromWei(userBalanceWEI.toString(), 'ether'));
    let totalSupply = await readContractData(chainName, pair, MarketPair_ABI, 'totalSupply', []);
    let token0 = await readContractData(chainName, pair, MarketPair_ABI, 'token0', []);
    let USDReserves;
    let tokenReserves;
    if (token0 === USDC_Address) {
      USDReserves = parseInt(reserves[0]);
      tokenReserves = parseInt(reserves[1]);
    } else {
      USDReserves = parseInt(reserves[1]);
      tokenReserves = parseInt(reserves[0]);
    }
    let _pools = pools;
    console.log(_pools);
    let selector;
    for (var i = 0, size = _pools.length; i < size; i++) {
      if (_pools[i][1] === pair) {
        selector = i;
      }
    }
    console.log(selector);
    let poolData = _pools[selector];
    console.log(poolData);
    poolData[2] = USDReserves * 2 / 10 ** USDDecimals;
    poolData[4] = parseInt(userBalance);
    poolData[5] = parseInt(totalSupply);
    poolData[7] = USDReserves;
    poolData[8] = tokenReserves;

    _pools[selector] = poolData;
    setPools(_pools);
    setLoadingBlockchainData(false);
  };

  const updateLPPairWithAddress = async (pair) => {
    setLoadingBlockchainData(true);
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI, pair);
    let reserves = await MarketPair.methods.getReserves().call();
    let userBalance = await MarketPair.methods.balanceOf(address).call();
    let totalSupply = await MarketPair.methods.totalSupply().call();
    let token0 = await MarketPair.methods.token0().call();
    let USDReserves;
    let tokenReserves;
    if (token0 === USDC_Address) {
      USDReserves = parseInt(reserves[0]);
      tokenReserves = parseInt(reserves[1]);
    } else {
      USDReserves = parseInt(reserves[1]);
      tokenReserves = parseInt(reserves[0]);
    }
    let pools = pools;
    let selector;
    for (var i = 0, size = pools.length; i < size; i++) {
      if (pools[i][1] === pair) {
        selector = i;
      }
    }
    let poolData = pools[selector];
    poolData[2] = USDReserves * 2 / 10 ** USDDecimals;
    poolData[4] = userBalance;
    poolData[5] = totalSupply;
    poolData[7] = USDReserves;
    poolData[8] = tokenReserves;
    pools[selector] = poolData;

    setPools(pools);
    setLoadingBlockchainData(false);
  };

  const checkRewards = async () => {
    let _openRewards = await RewardsMachine_nm.methods.getRewards(address).call();
    let _nextRewardsPayment = await RewardsMachine_nm.methods.nextRewardsPayment().call();
    var _GovernanceTokenBalanceWei = await GovernanceToken_nm.methods.balanceOf(address).call();
    var _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'));
    setGovernanceTokenBalance(_GovernanceTokenBalance);
    var userData = await VotingEscrow_nm.methods.userData(address).call();
    let _GovernanceTokenStakeBalanceWei = userData['_lockedBalance']['amount'];
    var _GovernanceTokenStakeBalance = parseFloat(
      web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether')
    );
    setGovernanceTokenStakeBalance(_GovernanceTokenStakeBalance);
    let _veISSBalanceWei = userData['_balanceVeISS'];
    var _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'));
    setVeISSBalance(_veISSBalance);
    let _lockDate = userData['_lockedBalance']['end'];
    setLockDate(_lockDate);

    var _GovernanceTokenTotalBalance = parseFloat(_GovernanceTokenStakeBalance) + parseFloat(_GovernanceTokenBalance);
    setGovernanceTokenTotalBalance(_GovernanceTokenTotalBalance);
  };

  const updatePortfolioValue = async () => {
    console.log('Loading Portfolio Value');
    let updatedAssetValue = 0;
    let updatedLPValue = 0;
    let updatedTotalValue = 0;
    if (!tokenLess) {
      console.log('This should not be relevant');
      try {
        updatedAssetValue += GovernanceTokenTotalBalance * ISSPrice + parseFloat(USDCBalance);
      } catch {
        updatedAssetValue += parseFloat(USDCBalance);
      }
    } else {
      console.log('No token');
      updatedAssetValue += parseFloat(USDCBalance);
      console.log(updatedAssetValue);
    }

    let _pools = pools;
    console.log(_pools);
    for (let i = 0, size = _pools.length; i < size; i++) {
      let poolValue = (parseFloat(_pools[i][4]) / parseFloat(_pools[i][5])) * parseFloat(_pools[i][2]);
      console.log(poolValue);
      updatedLPValue = updatedLPValue + poolValue;
      console.log(updatedLPValue);
    }
    let _assetDetails = assetDetails;

    for (let s in _assetDetails) {
      updatedAssetValue += _assetDetails[s]['tokenBalance1'] * _assetDetails[s]['priceLong'];
      console.log(updatedAssetValue);
      updatedAssetValue += _assetDetails[s]['tokenBalance2'] * _assetDetails[s]['priceShort'];
      console.log(updatedAssetValue);
    }
    console.log(updatedLPValue);
    updatedTotalValue = updatedAssetValue + updatedLPValue;
    setTotalValue(updatedTotalValue);
    console.log(updatedTotalValue);
    return;
  };

  const updateISSPrice = async () => {
    let ISSData = await getPrice(GovernanceToken_Address);
    setISSPrice(ISSData[0]);
  };

  const updatePriceWithAddress = async (_address) => {
    setLoadingBlockchainData(true);
    let priceData = await getPrice(_address);
    console.log(priceData);
    if (GovernanceToken_Address === _address) {
      setISSPrice(priceData[0]);
    } else {
      console.log('updating price data');
      let _assetDetails = { ...assetDetails };
      let tickerData = await getTickerForAddress(_address);
      console.log(tickerData);
      if (tickerData[1] === 'long') {
        console.log('Long identified');
        _assetDetails[tickerData[0]]['priceLong'] = priceData[0];
      }
      if (tickerData[1] === 'short') {
        console.log('Short identified');
        _assetDetails[tickerData[0]]['priceShort'] = priceData[0];
      }
      console.log(_assetDetails);
      setAssetDetails(_assetDetails);
    }
    setLoadingBlockchainData(false);
  };

  async function getTickerForAddress(_address) {
    for (const key of Object.keys(assetDetails || {})) {
      if (assetDetails[key]['Token1'] === _address) {
        return [key, 'long'];
      }
      if (assetDetails[key]['Token2'] === _address) {
        return [key, 'short'];
      }
    }
    return ['', ''];
  }

  const [viewport, setViewport] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AppContext.Provider
      value={{
        stableCoinName,
        USDDecimals,
        activePage,
        setActivePage,
        address,
        setAddress,
        assetDetails,
        setAssetDetails,
        assetFactory,
        setAssetFactory,
        assetFactory_Address,
        setAssetFactory_Address,
        assets,
        setAssets,
        assetValue,
        setAssetValue,
        chainData,
        setChainData,
        chainName,
        setChainName,
        chainDetailsLoaded,
        setChainDetailsLoaded,
        blockchainDataLoaded,
        setblockchainDataLoaded,
        fullProtocolIntegration,
        setFullProtocolIntegration,
        DAO_Address,
        setDAO_Address,
        expiredAssets,
        setExpiredAssets,
        GovernanceToken,
        setGovernanceToken,
        GovernanceToken_Address,
        setGovernanceToken_Address,
        GovernanceTokenBalance,
        setGovernanceTokenBalance,
        GovernanceTokenTotalBalance,
        setGovernanceTokenTotalBalance,
        GovernanceTokenTotalBalanceAllChains,
        setGovernanceTokenTotalBalanceAllChains,
        GovernanceTokenStakeBalance,
        setGovernanceTokenStakeBalance,
        GovernanceTokenVestedStakeBalance,
        setGovernanceTokenVestedStakeBalance,
        IDT_nm,
        setIDT_nm,
        IDTBalance,
        setIDTBalance,
        IDTBalanceWei,
        setIDTBalanceWei,
        ISSPrice,
        setISSPrice,
        ISSMarketCap,
        setISSMarketCap,
        ISSMarketCapCurrent,
        setISSMarketCapCurrent,
        ISSSupply,
        setISSSupply,
        ISSSupplyCurrent,
        setISSSupplyCurrent,
        liveAssets,
        setLiveAssets,
        loadingBlockchainData,
        setLoadingBlockchainData,
        lockDate,
        setLockDate,
        loggedIn,
        setLoggedIn,
        LPValue,
        setLPValue,
        MarketFactory_Address,
        setMarketFactory_Address,
        MarketRouter_Address,
        setMarketRouter_Address,
        messageBoxVisible,
        setMessageBoxVisible,
        messageBoxContent,
        setMessageBoxContent,
        pageLoadedMainpage,
        setPageLoadedMainpage,
        pools,
        setPools,
        RewardsMachine_Address,
        setRewardsMachine_Address,
        showAccount,
        setShowAccount,
        slippage,
        setSlippage,
        testnet,
        setTestnet,
        totalLockedValue,
        setTotalLockedValue,
        totalValue,
        setTotalValue,
        TotalVeISSSupply,
        setTotalVeISSSupply,
        trxTime,
        setTrxTime,
        txhash,
        setTxhash,
        Upgrader_Address,
        setUpgrader_Address,
        USDC_Address,
        setUSDC_Address,
        USDCAllowance,
        setUSDCAllowance,
        USDCBalance,
        setUSDCBalance,
        veISSBalance,
        setVeISSBalance,
        VotingEscrow_Address,
        setVotingEscrow_Address,
        web3_nm,
        setWeb3_nm,
        wrongNetworkMessage,
        setWrongNetworkMessage,
        assetFactory_nm,
        setAssetFactory_nm,
        USDC_nm,
        setUSDC_nm,
        GovernanceToken_nm,
        setGovernanceToken_nm,
        MarketRouter_nm,
        setMarketRouter_nm,
        MarketFactory_nm,
        setMarketFactory_nm,
        RewardsMachine_nm,
        setRewardsMachine_nm,
        DAO_nm,
        setDAO_nm,
        Upgrader_nm,
        setUpgrader_nm,
        VotingEscrow_nm,
        setVotingEscrow_nm,
        timeStampToDate,
        outputNumber,
        timeStampToDateAndTime,
        roundDown,
        sleep,
        ERC20_ABI,
        assetFactory_ABI,
        viewport,
        updateAssetBalance,
        updateAssetBalanceWithAddress,
        loadUSDBalance,
        loadLPBalances,
        MarketPair_ABI,
        MarketRouter_ABI,
        updatePortfolioValue,
        updateLPPair,
        saveSlippagePreference,
        saveTrxTimePreference,
        updatePriceWithAddress,
        updateUserData,
        updateISSData,
        VotingEscrow_ABI,
        loadLimitedBlockchainData,
        DAO_ABI,
        geoLocationOk,
        myRefCode,
        setMyRefCode,
        referralData,
        setReferralData,
        setMyPoints,
        myPoints,
        myRank,
        setMyRank,
        pointsBreakdown,
        setPointsBreakdown,
        marketLive,
        showTerms,
        setShowTerms,
        highestYieldingAsset,
        mintedAssetVolume,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export const useViewport = () => {
  const { viewport } = useContext(AppContext);
  return viewport;
};