import React from 'react';
import assetIcon from '../../img/icons/Avatar.png';

// Static import of all logos
const logoMap = {
  AAPL: new URL('../../img/logos/AAPL.png', import.meta.url).href,
  COIN: new URL('../../img/logos/COIN.png', import.meta.url).href,
  MSTR: new URL('../../img/logos/MSTR.png', import.meta.url).href,
  NVDA: new URL('../../img/logos/NVDA.jpeg', import.meta.url).href,

  iAAPL: new URL('../../img/logos/AAPL.png', import.meta.url).href,
  iCOIN: new URL('../../img/logos/COIN.png', import.meta.url).href,
  iMSTR: new URL('../../img/logos/MSTR.png', import.meta.url).href,
  iNVDA: new URL('../../img/logos/NVDA.jpeg', import.meta.url).href,
  USDC: new URL('../../img/logos/USDC.png', import.meta.url).href,
  TWIN: new URL('../../img/logos/TWIN.png', import.meta.url).href,
  // Add all your asset symbols here
};

const AssetLogo = ({ symbol, width = '24px', alt = 'asset icon' }) => {
  const getLogo = (sym) => {
    return logoMap[sym] || assetIcon;
  };

  return (
    <img
      src={symbol === 'Select an asset' ? assetIcon : getLogo(symbol)}
      alt={alt}
      width={width}
    />
  );
};

export default AssetLogo;