// src/components/Pages/Portfolio/PointsHeader.jsx
import React from 'react';
import { useAppContext } from '../../../context/AppContext';
import EpochTimer from './EpochTimer';

const PointsHeader = () => {
  const { 
    myPoints, 
    myRank,
    pointsBreakdown,
    loggedIn
  } = useAppContext();

  console.log('PointsHeader - myPoints:', myPoints, 'myRank:', myRank, 'loggedIn:', loggedIn);

  return (
    <div className="quick-stats">
      <div className="stat-box">
        <span className="stat-label">My Points</span>
        <span className="stat-value">
          {!loggedIn ? '0' : (myPoints !== undefined && myPoints !== null ? myPoints.toLocaleString() : 'Loading...')}
        </span>
      </div>
      <div className="stat-box">
        <span className="stat-label">My Rank</span>
        <span className="stat-value">
          {!loggedIn || !myRank ? 'Unranked' : `#${myRank}`}
        </span>
      </div>
      <EpochTimer />
    </div>
  );
};

export default PointsHeader;