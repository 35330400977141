// src/components/Pages/Portfolio/MobilePortfolio.jsx
import '../../../styles/mobilePortfolio.css';
import React, { useState, useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import MobilePortfolioAssets from './mobilePortfolioAssets';
import MobilePortfolioAssetsMarketless from './mobilePortfolioAssetsMarketless';
import MobilePortfolioLpTokens from './mobilePortfolioLpTokens'; // New component import
import AssetLogo from '../../Common/AssetLogo';
import usePortfolioLogic from './usePortfolioLogic';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';

const MobilePortfolio = () => {
  const {
    portfolio,
    portfolioAssets,
    lpTokens,
    assetValue,
    LPValue,
    outputNumber,
    marketLive,
    loading,
    loggedIn,
  } = usePortfolioLogic();

  const { open } = useWeb3Modal();
  const account = useAccount();
  const address = account.address;
  const [isConnecting, setIsConnecting] = useState(false);

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 4)}...${address.substring(address.length - 5)}`;
  };

  const addressShort = shortenAddress(address);

  const handleConnect = async () => {
    setIsConnecting(true);
    await open();
  };

  useEffect(() => {
    if (address) {
      setIsConnecting(false);
    }
  }, [address]);

  useEffect(() => {
    console.log('MobilePortfolio - address:', address, 'loggedIn:', loggedIn, 'isConnecting:', isConnecting);
    console.log('Values - totalValue:', assetValue + LPValue, 'assetValue:', assetValue, 'LPValue:', LPValue);
  }, [address, loggedIn, isConnecting, assetValue, LPValue]);

  return (
    <div className="mainContainerMobile">
      <div className="middleBoxFullMobile">
        <div className="portfolioTotalValueBoxMobile">
          <div className="portfolioBoxMobileLeft">
            <div className="portfolioBoxItemMobile">
              {loggedIn ? (
                `$${outputNumber(parseFloat(assetValue) + parseFloat(LPValue), 0)}`
              ) : (
                "$0"
              )}
            </div>
            <div className="portfolioBoxItem2Mobile">
              {loggedIn ? "Total portfolio value" : "Connect wallet to see your portfolio"}
            </div>
          </div>
          <div className="infoBoxIconMobile">
            <AttachMoneyOutlinedIcon fontSize="inherit" />
          </div>
        </div>

        {/* Loading State */}
        {loading && (
          <div className="text-center mt-4">
            <CircularProgress
              size={30}
              thickness={7}
              sx={{
                color: '#086A24',
                animationDuration: '5s',
              }}
            />
            <div style={{ marginTop: '10px', color: '#086A24', fontSize: '14px' }}>
              Loading your assets...
            </div>
          </div>
        )}

        {/* LP Tokens */}
        {marketLive && loggedIn && !loading && (
          <MobilePortfolioLpTokens lpTokens={lpTokens} LPValue={LPValue} />
        )}

        {/* Portfolio Assets */}
        {!loading && loggedIn && (
          <>
            {!marketLive ? (
              <MobilePortfolioAssetsMarketless
                portfolio={portfolio}
                portfolioAssets={portfolioAssets}
                loading={loading}
              />
            ) : (
              <MobilePortfolioAssets portfolio={portfolio} portfolioAssets={portfolioAssets} />
            )}
          </>
        )}

        {/* Connect Wallet Placeholder */}
        {!loading && !loggedIn && (
          <div className="text-center mt-4">
            <p>Please connect your wallet to view your assets.</p>
            <div role="button" className="issuaaButton" onClick={handleConnect}>
              {isConnecting ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <CircularProgress
                    size={30}
                    thickness={7}
                    sx={{
                      color: '#086A24',
                      animationDuration: '5s',
                    }}
                  />
                  <div style={{ marginTop: '10px', color: '#086A24', fontSize: '14px' }}>
                    Connecting...
                  </div>
                </div>
              ) : address ? (
                <span>{addressShort}</span>
              ) : (
                <span>Connect Wallet</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobilePortfolio;