// src/components/Pages/Mainpage/useMainpageLogic.js
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/AppContext';
import Tooltip from 'react-bootstrap/Tooltip';

const useMainpageLogic = () => {
  const {
    activePage,
    assets,
    pools,
    outputNumber,
    ISSPrice,
    totalLockedValue,
    ISSMarketCapCurrent,
    farmActive,
    marketLive,
    highestYieldingAsset,
    mintedAssetVolume,
  } = useAppContext();

  const [poolOutput, setPoolOutput] = useState('');

  useEffect(() => {
    if (typeof pools !== 'undefined') {
      setPoolOutput(
        pools.map((pool, index) => ({
          key: index,
          symbol: pool[0],
          name: pool[3],
          price: marketLive ? outputNumber(pool[10], 2) : null,
          upperLimit: outputNumber(parseFloat(pool[9]) / 1000, 0),
          poolLiquidity: marketLive ? outputNumber(pool[2], 0) : null,
          yourStake: marketLive ? null : outputNumber(pool[13], 0),
          apr: !marketLive ? outputNumber(pool[12] * 100, 0) : null,
          isHighestYield: pool[12] === highestYieldingAsset[2],
          tradeLinkBuy: `/trade/buy/${pool[1]}`,
          tradeLinkSell: `/trade/sell/${pool[1]}`,
          mintLink: '/mint',
          redeemLink: '/redeem',
          poolLiquidityUSD: marketLive ? outputNumber(pool[2] * parseFloat(pool[4]) / parseFloat(pool[5]), 0) : null,
        }))
      );
    }
  }, [pools, outputNumber, farmActive, marketLive, highestYieldingAsset]);

  const renderTooltip1 = (props) => (
    <Tooltip {...props}>The upper limit is the basis from which the value of the short token is calculated. If the upper limit is reached, the asset will be frozen and the upper limit amount will be paid out to long token holders. The short token will be worthless. </Tooltip>
  );
  

  return {
    activePage,
    assets,
    pools,
    outputNumber,
    ISSPrice,
    totalLockedValue,
    ISSMarketCapCurrent,
    farmActive,
    marketLive,
    highestYieldingAsset,
    mintedAssetVolume,
    poolOutput,
    renderTooltip1,
  };
};

export default useMainpageLogic;