// src/components/Pages/Portfolio/PointsDashboard.jsx
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext';
import Leaderboard from './Leaderboard';
import GenerateRefCode from './GenerateRefCode';
import ReferralProgress from './ReferralProgress';
import PointsSummary from './PointsSummary';
import PointsHistory from './PointsHistory';
import EpochTimer from './EpochTimer';
import ReferralStats from './ReferralStats';
import '../../../styles/pointsDashboard.css';
import PointsHeader from './PointsHeader';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';
import CircularProgress from '@mui/material/CircularProgress';

const PointsDashboard = () => {
  const [activeTab, setActiveTab] = useState('summary'); // Default can be overridden
  const { 
    myPoints, 
    myRank,
    address, 
    setReferralData,
    setMyPoints,
    setMyRank,
    setPointsBreakdown,
    pointsBreakdown,
    loggedIn
  } = useAppContext();

  const { open } = useWeb3Modal();
  const account = useAccount();
  const [isConnecting, setIsConnecting] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 4)}...${address.substring(address.length - 5)}`;
  };

  const addressShort = shortenAddress(address);

  const handleConnect = async () => {
    setIsConnecting(true);
    await open();
  };

  useEffect(() => {
    if (address) {
      setIsConnecting(false);
    }
  }, [address]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        setError(null);
  
        console.log('Fetching referral data...');
        const referralResponse = await fetch(
          `https://aapi.twinfinance.io/referrals?wallet=${address}`
        );
  
        if (referralResponse.status === 404) {
          console.log('No referral data found, setting empty data.');
          setReferralData({
            your_ref_code: null,
            total_referrals: 0,
            referrals: []
          });
        } else if (!referralResponse.ok) {
          throw new Error('Failed to fetch referral data');
        } else {
          const referralData = await referralResponse.json();
          console.log('Referral Data:', referralData);
          setReferralData(referralData);
        }
  
        console.log('Fetching user points and rank...');
        const pointsResponse = await fetch(
          `https://aapi.twinfinance.io/user-points?wallet=${address}`
        );
  
        if (!pointsResponse.ok) throw new Error('Failed to fetch points data');
        const pointsData = await pointsResponse.json();
        console.log('Points Data:', pointsData);
  
        console.log('Updating context...');
        setMyPoints(pointsData.total_points || 0);
        setMyRank(pointsData.current_rank || null);
        setPointsBreakdown(pointsData.points_breakdown || {});
  
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        console.log('Fetching complete, setting loading to false.');
        setLoading(false);
      }
    };
  
    if (address && loggedIn) {
      fetchUserData();
    } else {
      console.log('No wallet connected, switching to leaderboard.');
      setActiveTab('leaderboard'); // Switch to leaderboard when not connected
      setLoading(false);
    }
  }, [address, loggedIn, setReferralData, setMyPoints, setMyRank, setPointsBreakdown]);

  useEffect(() => {
    console.log('PointsDashboard - address:', address, 'loggedIn:', loggedIn, 'isConnecting:', isConnecting, 'pointsBreakdown:', pointsBreakdown);
  }, [address, loggedIn, isConnecting, pointsBreakdown]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="text-center mt-4">
          <CircularProgress
            size={30}
            thickness={7}
            sx={{
              color: '#086A24',
              animationDuration: '5s',
            }}
          />
          <div style={{ marginTop: '10px', color: '#086A24', fontSize: '14px' }}>
            Loading dashboard data...
          </div>
        </div>
      );
    }

    if (error) {
      return <div className="error">Error: {error}</div>;
    }

    switch (activeTab) {
      case 'leaderboard':
        return <Leaderboard />; // Always show leaderboard, connected or not
      case 'referrals':
        if (!loggedIn) {
          return (
            <div className="text-center mt-4">
              <p>Please connect your wallet to view your referral data.</p>
              <div role="button" className="issuaaButton" onClick={handleConnect}>
                {isConnecting ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgress
                      size={30}
                      thickness={7}
                      sx={{
                        color: '#086A24',
                        animationDuration: '5s',
                      }}
                    />
                    <div style={{ marginTop: '10px', color: '#086A24', fontSize: '14px' }}>
                      Connecting...
                    </div>
                  </div>
                ) : address ? (
                  <span>{addressShort}</span>
                ) : (
                  <span>Connect Wallet</span>
                )}
              </div>
            </div>
          );
        }
        return (
          <>
            <ReferralProgress />
            <GenerateRefCode />
            <ReferralStats />
          </>
        );
      default: // 'summary'
        if (!loggedIn) {
          return (
            <div className="text-center mt-4">
              <p>Please connect your wallet to view your points overview.</p>
              <div role="button" className="issuaaButton" onClick={handleConnect}>
                {isConnecting ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgress
                      size={30}
                      thickness={7}
                      sx={{
                        color: '#086A24',
                        animationDuration: '5s',
                      }}
                    />
                    <div style={{ marginTop: '10px', color: '#086A24', fontSize: '14px' }}>
                      Connecting...
                    </div>
                  </div>
                ) : address ? (
                  <span>{addressShort}</span>
                ) : (
                  <span>Connect Wallet</span>
                )}
              </div>
            </div>
          );
        }
        return (
          <>
            <div className="mainBox">
              {pointsBreakdown ? (
                <>
                  <PointsSummary />
                  <PointsHistory />
                </>
              ) : (
                <div className="text-center mt-4">
                  <p>No points data available yet.</p>
                </div>
              )}
            </div>
          </>
        );
    }
  };

  return (
    <div className="mainContainer">
      <div className="points-dashboard">        
        <div className="dashboard-header">
          <div className="quick-stats">
            <PointsHeader />
          </div>
        </div>

        <div className="dashboard-tabs">
          <button 
            className={activeTab === 'summary' ? 'active' : ''}
            onClick={() => setActiveTab('summary')}
          >
            Overview
          </button>
          <button 
            className={activeTab === 'leaderboard' ? 'active' : ''}
            onClick={() => setActiveTab('leaderboard')}
          >
            Leaderboard
          </button>
          <button 
            className={activeTab === 'referrals' ? 'active' : ''}
            onClick={() => setActiveTab('referrals')}
          >
            Referrals
          </button>
        </div>

        <div className="dashboard-content">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default PointsDashboard;