import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext';
import AssetLogo from '../../Common/AssetLogo';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from 'react-bootstrap/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const PortfolioAssets = ({ portfolioAssets, portfolio }) => {
  const { stableCoinName, assetValue, outputNumber, timeStampToDate } = useAppContext();
  const [expandedAsset, setExpandedAsset] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const toggleExpansion = (asset) => {
    setExpandedAsset((prevState) => ({
      ...prevState,
      [asset]: !prevState[asset],
    }));
  };

  const renderTooltip1 = (props) => (
    <Tooltip {...props}>Each asset has a duration of 365 days from the creation of the asset.</Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip {...props}>
      The upper limit is the basis from which the value of the short token is calculated. If the upper limit is reached,
      the asset will be frozen and the upper limit amount will be paid out to long token holders. The short token will be
      worthless.
    </Tooltip>
  );

  // Filter assets based on search term
  const filteredAssets = portfolioAssets
    ? portfolioAssets.filter(
        (asset) =>
          asset.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          (portfolio[asset]?.name || '').toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
    : [];

  // Sort the filtered assets
  const sortedAssets = [...filteredAssets].sort((a, b) => {
    if (!sortColumn) return 0;

    let valueA, valueB;
    switch (sortColumn) {
      case 'name':
        valueA = (portfolio[a]?.name || '').toLowerCase();
        valueB = (portfolio[b]?.name || '').toLowerCase();
        return sortDirection === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      case 'value':
        valueA = parseFloat(portfolio[a]?.portfolioValue || 0);
        valueB = parseFloat(portfolio[b]?.portfolioValue || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      case 'price':
        valueA = parseFloat(portfolio[a]?.price || 0);
        valueB = parseFloat(portfolio[b]?.price || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      default:
        return 0;
    }
  });

  // Handle sort click
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Render sort indicator (always show, style based on active sort)
  const renderSortIndicator = (column) => {
    const isActive = sortColumn === column;
    const isAsc = sortDirection === 'asc';
    return isActive && !isAsc ? (
      <ArrowDownwardIcon
        fontSize="small"
        className={`sortIcon ${isActive ? 'sortIconActive' : ''}`}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        className={`sortIcon ${isActive ? 'sortIconActive' : ''}`}
      />
    );
  };

  return (
    <div className="portfolioAssetBox">
      <div className="portfolioBoxHeader">
        <div className="headlineSearchWrapper">
          <div>Your Assets</div>
          <div className="searchContainer">
            <SearchIcon className="searchIcon" />
            <input
              type="text"
              placeholder="Search assets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="searchInput"
            />
          </div>
        </div>
      </div>
      <div className="row mainPortfolioContent">
        <div className="pl-3 pr-3 w-100">
          <table className="w-100">
            <thead>
              <tr>
                <th className="text-left">Symbol</th>
                <th
                  className="text-left sortable"
                  onClick={() => handleSort('name')}
                  style={{ cursor: 'pointer' }}
                >
                  Name {renderSortIndicator('name')}
                </th>
                <th
                  className="text-right sortable"
                  onClick={() => handleSort('price')}
                  style={{ cursor: 'pointer' }}
                >
                  Price {renderSortIndicator('price')}
                </th>
                <th className="text-right">Position</th>
                <th className="text-right">
                  Expiry Date
                  <OverlayTrigger placement="right" overlay={renderTooltip1}>
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{ color: '#086A24', marginLeft: '0.5rem', cursor: 'help' }}
                    />
                  </OverlayTrigger>
                </th>
                <th className="text-right">
                  Upper limit
                  <OverlayTrigger placement="right" overlay={renderTooltip2}>
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{ color: '#086A24', marginLeft: '0.5rem', cursor: 'help' }}
                    />
                  </OverlayTrigger>
                </th>
                <th className="text-right">Status</th>
                <th
                  className="text-right sortable"
                  onClick={() => handleSort('value')}
                  style={{ cursor: 'pointer' }}
                >
                  Value (USD) {renderSortIndicator('value')}
                </th>
                <th className="text-right" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {sortedAssets && sortedAssets.length > 0 ? (
                sortedAssets.map((asset, index) => (
                  <tr key={index}>
                    <td className="text-left assetCell">
                      <div className="assetNameSymbolMobile">
                        <AssetLogo symbol={asset} />
                        <span>{asset}</span>
                      </div>
                    </td>
                    <td className="text-left">{portfolio[asset]?.name}</td>
                    <td className="text-right">{outputNumber(portfolio[asset]?.price, 2)}</td>
                    <td className="text-right">{outputNumber(portfolio[asset]?.balance, 4)}</td>
                    <td className="text-right">
                      {asset !== 'TWIN' && asset !== 'USDC' ? timeStampToDate(portfolio[asset]?.expiryDate) : 'n.a.'}
                    </td>
                    <td className="text-right">
                      {asset !== 'TWIN' && asset !== 'USDC'
                        ? outputNumber(parseFloat(portfolio[asset]?.upperLimit) / 1000, 2)
                        : 'n.a.'}
                    </td>
                    <td className="text-right">
                      {portfolio[asset]?.isFrozen ? 'frozen' : <div className="portfolioStatusLive">LIVE</div>}
                    </td>
                    <td className="text-right">{outputNumber(portfolio[asset]?.portfolioValue, 0)}</td>
                    <td>
                      {portfolio[asset]['name'] !== stableCoinName ? (
                        <div className="d-flex flex-row">
                          <Link to={`/trade/buy/${portfolio[asset]['marketPair']}`}>
                            <div className="buyButton">Buy</div>
                          </Link>
                          <Link to={`/trade/sell/${portfolio[asset]['marketPair']}`}>
                            <div className="sellButton">Sell</div>
                          </Link>
                        </div>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9">No assets available</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-left">
                  <b>Total Assets</b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-right">
                  <b>{outputNumber(assetValue, 0)}</b>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PortfolioAssets;