import axios from 'axios';

export const checkIn = async (_address, _refCode) => {
  const url = 'https://aapi.twinfinance.io/checkIn';
  const data = {
    address: _address,
    refCode: _refCode || '', // Ensure refCode is always a string
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('Response:', response.data);
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error:', error);
    return { success: false, data: error.response?.data || error.message };
  }
};
