// src/hooks/useBurnAssetsLogic.js
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/AppContext'; // Adjusted path

const useBurnAssetsLogic = () => {
  const {
    address,
    assetDetails,
    USDCBalance,
    assetFactory_nm,
    assetFactory_Address,
    web3_nm,
    outputNumber,
    updateAssetBalance,
    loadUSDBalance,
    ERC20_ABI,
    stableCoinName,
    assetFactory_ABI,
    USDDecimals,
    USDC_Address,
    USDC_nm,
    maxBurnableAmount,
    burnableExpiredAssets,
    burnableAssets,
    sleep,
    roundDown,
    assetFactory_nm_ABI,
    loggedIn
  } = useAppContext();

  // State declarations (unchanged except for clarity)
  const [allowance, setAllowance] = useState(0);
  const [allowance2, setAllowance2] = useState(0);
  const [amountToBurn, setAmountToBurn] = useState(0);
  const [amountToBurnLong, setAmountToBurnLong] = useState(0);
  const [amountToBurnShort, setAmountToBurnShort] = useState(0);
  const [amountToGet, setAmountToGet] = useState(0);
  const [amountToGetExpired, setAmountToGetExpired] = useState(0);
  const [assetBurnOptions, setAssetBurnOptions] = useState([]);
  const [assetBurnOptionsExpired, setAssetBurnOptionsExpired] = useState([]);
  const [burnAmount, setBurnAmount] = useState(0);
  const [burnableBalanceToken1, setBurnableBalanceToken1] = useState(0);
  const [burnableBalanceToken2, setBurnableBalanceToken2] = useState(0);
  const [chooseAssetModalOpen, setChooseAssetModalOpen] = useState(false);
  const [chooseExpiredAssetModalOpen, setChooseExpiredAssetModalOpen] = useState(false);
  const [filteredAssetBurnOptions, setFilteredAssetBurnOptions] = useState([]);
  const [filteredAssetBurnOptionsExpired, setFilteredAssetBurnOptionsExpired] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalBurnExpiredAssetsOpen, setModalBurnExpiredAssetsOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState('Select Asset');
  const [selectedAssetExpired, setSelectedAssetExpired] = useState('Select Asset');
  const [selectedAssetBurnableBalance, setSelectedAssetBurnableBalance] = useState(0);
  const [style1, setStyle1] = useState('selectButtonSelected');
  const [style2, setStyle2] = useState('selectButtonUnselected');
  const [submitButtonDeactivated, setSubmitButtonDeactivated] = useState(false);
  const [submitButtonDeactivatedMessage, setSubmitButtonDeactivatedMessage] = useState('');
  const [token1Address, setToken1Address] = useState('');
  const [token2Address, setToken2Address] = useState('');
  const [tokenToBurn, setTokenToBurn] = useState('');
  const [view, setView] = useState('live');

  // Functions (unchanged except for exposing web3_nm)
  const selectLiveView = async () => {
    setView('live');
    setStyle1('selectButtonSelected');
    setStyle2('selectButtonUnselected');
  };

  const selectExpiredView = async () => {
    setView('expired');
    setStyle1('selectButtonUnselected');
    setStyle2('selectButtonSelected');
    setSelectedAsset('Select Asset');
  };

  const openModal = async () => {
    let _amountToBurn = document.getElementById('amountToBurn').value;
    let AssetDetails = await assetFactory_nm.methods.getAsset(selectedAsset).call();
    let upperLimit = parseFloat(AssetDetails.upperLimit) / 1000;
    let _amountToGet = upperLimit * _amountToBurn;
    setAmountToBurn(_amountToBurn);
    setAmountToGet(_amountToGet);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const openModalBurnExpiredAssets = async () => {
    setTokenToBurn(selectedAssetExpired);
    let _amountToBurnLong = parseFloat(document.getElementById('amountToBurnToken1').value);
    let _amountToBurnShort = parseFloat(document.getElementById('amountToBurnToken2').value);
    let AssetDetails = await assetFactory_nm.methods.getAsset(selectedAssetExpired).call();
    let upperLimit = parseInt(AssetDetails.upperLimit) / 1000;
    let expiryValue = parseInt(AssetDetails.endOfLifeValue) / 1000;
    let _amountToGetExpired = expiryValue * _amountToBurnLong + (upperLimit - expiryValue) * _amountToBurnShort;
    setAmountToBurnLong(_amountToBurnLong);
    setAmountToBurnShort(_amountToBurnShort);
    setAmountToGetExpired(_amountToGetExpired);
    setModalBurnExpiredAssetsOpen(true);
  };

  const closeModalBurnExpiredAssets = () => setModalBurnExpiredAssetsOpen(false);

  const onSuccessApprove = async () => {
    if (view === 'live') {
      checkInputLive();
    } else {
      checkInputExpired();
    }
  };

  const onSuccessBurn = async () => {
    setModalOpen(false);
    setModalBurnExpiredAssetsOpen(false);
    document.getElementById('amountToBurn').value = 0;
    setSelectedAsset('Select Asset');
    setSelectedAssetExpired('Select Asset');
    setSelectedAssetBurnableBalance(0);
    await loadUSDBalance();
    await updateAssetBalance(tokenToBurn);
  };

  const setMaxAmount = async () => {
    let amount = parseInt(selectedAssetBurnableBalance) / 1e18;
    amount = roundDown(amount, 14);
    document.getElementById('amountToBurn').value = amount;
    checkInputLive();
  };

  const setMaxAmountToken1 = async () => {
    let amount = parseInt(burnableBalanceToken1) / 1e18;
    amount = roundDown(amount, 14);
    document.getElementById('amountToBurnToken1').value = amount;
  };

  const setMaxAmountToken2 = async () => {
    let amount = parseInt(burnableBalanceToken2) / 1e18;
    amount = roundDown(amount, 14);
    document.getElementById('amountToBurnToken2').value = amount;
  };

  const openChooseAssetModal = async () => {
    let _assets = [];
    for (let key in assetDetails) {
      if (assetDetails[key]['frozen'] === false) {
        let burnableBalance = Math.min(assetDetails[key]['tokenBalance1'], assetDetails[key]['tokenBalance2']);
        if (assetDetails[key].expiryTime > Date.now() / 1000) {
          _assets.push([key, burnableBalance, assetDetails[key]['name'], assetDetails[key]['upperLimit']]);
        }
      } else {
        let burnableBalance = assetDetails[key]['tokenBalance1'];
        _assets.push([key, burnableBalance, assetDetails[key]['name'], assetDetails[key]['upperLimit']]);
      }
    }
    setAssetBurnOptions(_assets);
    setChooseAssetModalOpen(true);
  };

  const closeChooseAssetModal = () => setChooseAssetModalOpen(false);

  const openChooseExpiredAssetModal = async () => {
    let _assets = [];
    for (let key in assetDetails) {
      let burnableBalance1 = assetDetails[key]['tokenBalance1'];
      let burnableBalance2 = assetDetails[key]['tokenBalance2'];
      if (assetDetails[key].expiryTime < Date.now() / 1000) {
        _assets.push([key, burnableBalance1, burnableBalance2, assetDetails[key]['name'], assetDetails[key]['upperLimit']]);
      }
    }
    setAssetBurnOptionsExpired(_assets);
    setChooseExpiredAssetModalOpen(true);
  };

  const closeChooseExpiredAssetModal = () => setChooseExpiredAssetModalOpen(false);

  const checkInputLive = async () => {
    if (!token1Address) {
      document.getElementById('amountToBurn').value = '';
      return;
    }
    let _amountRaw = document.getElementById('amountToBurn').value * Number(1e18);
    if (isNaN(_amountRaw)) {
      document.getElementById('amountToBurn').value = burnAmount;
      return;
    }
    let _amount = web3_nm.utils.toBigInt(_amountRaw);
    let tokenContract = new web3_nm.eth.Contract(ERC20_ABI, token1Address);
    let tokenContract2 = new web3_nm.eth.Contract(ERC20_ABI, token2Address);
    let _allowance = await tokenContract.methods.allowance(address, assetFactory_nm._address).call();
    let _allowance2 = await tokenContract2.methods.allowance(address, assetFactory_nm._address).call();
    setAllowance(parseInt(_allowance));
    setAllowance2(parseInt(_allowance2));
    setBurnAmount(parseInt(_amount) / 1e18);

    if (_amountRaw > selectedAssetBurnableBalance) {
      setSubmitButtonDeactivated(true);
      setSubmitButtonDeactivatedMessage('Balance too low');
    } else if (_amountRaw === 0) {
      setSubmitButtonDeactivated(true);
      setSubmitButtonDeactivatedMessage('Select an amount greater than 0');
    } else {
      setSubmitButtonDeactivated(false);
    }
  };

  const checkInputExpired = async () => {
    let amt1 = document.getElementById('amountToBurnToken1').value * 1e18;
    let amt2 = document.getElementById('amountToBurnToken2').value * 1e18;
    let _amount1 = web3_nm.utils.toBigInt(amt1);
    let _amount2 = web3_nm.utils.toBigInt(amt2);
    let tokenContract = new web3_nm.eth.Contract(ERC20_ABI, token1Address);
    let tokenContract2 = new web3_nm.eth.Contract(ERC20_ABI, token2Address);
    let _allowance = await tokenContract.methods.allowance(address, assetFactory_nm._address).call();
    let _allowance2 = await tokenContract2.methods.allowance(address, assetFactory_nm._address).call();
    setAllowance(parseInt(_allowance));
    setAllowance2(parseInt(_allowance2));
    setBurnAmount(parseInt(_amount1) / 1e18); // For consistency with live view
    setAmountToBurnLong(parseInt(_amount1) / 1e18); // Explicitly set for expired
    setAmountToBurnShort(parseInt(_amount2) / 1e18); // Explicitly set for expired

    if (amt1 > burnableBalanceToken1 || amt2 > burnableBalanceToken2) {
      setSubmitButtonDeactivated(true);
      setSubmitButtonDeactivatedMessage('Balance too low');
    } else {
      setSubmitButtonDeactivated(false);
    }
  };

  const filterAssets = () => {
    let _filteredAssets = [];
    let searchTerm = '_';
    try {
      searchTerm = document.getElementById('search').value.toLowerCase();
    } catch {
      searchTerm = '_';
    }
    for (let i = 0; i < assetBurnOptions.length; ++i) {
      if (assetBurnOptions[i][0].toLowerCase().includes(searchTerm) && assetBurnOptions[i][1] > 0.0000001) {
        _filteredAssets.push(assetBurnOptions[i]);
      }
    }
    setFilteredAssetBurnOptions(_filteredAssets);
  };

  const filterAssetsExpired = () => {
    let _filteredAssets = [];
    let searchTerm = '';
    try {
      searchTerm = document.getElementById('search').value.toLowerCase();
    } catch {
      searchTerm = '';
    }
    for (let i = 0; i < assetBurnOptionsExpired.length; ++i) {
      if (
        (assetBurnOptionsExpired[i][3].toLowerCase().includes(searchTerm) ||
          assetBurnOptionsExpired[i][0].toLowerCase().includes(searchTerm)) &&
        assetBurnOptionsExpired[i][1] > 0.0000001
      ) {
        _filteredAssets.push(assetBurnOptionsExpired[i]);
      }
    }
    setFilteredAssetBurnOptionsExpired(_filteredAssets);
  };

  useEffect(() => {
    filterAssets();
  }, [assetBurnOptions]);

  useEffect(() => {
    filterAssetsExpired();
  }, [assetBurnOptionsExpired]);

  useEffect(() => {
    checkInputLive();
  }, [allowance2]);

  const selectAssetLive = async (asset) => {
    document.getElementById('amountToBurn').value = 0;
    let _token1Address = assetDetails[asset][0];
    let _token2Address = assetDetails[asset][1];
    let tokenContract1 = new web3_nm.eth.Contract(ERC20_ABI, _token1Address);
    let balanceToken1 = await tokenContract1.methods.balanceOf(address).call();
    let tokenContract2 = new web3_nm.eth.Contract(ERC20_ABI, _token2Address);
    let balanceToken2 = await tokenContract2.methods.balanceOf(address).call();
    if (parseInt(balanceToken1) <= parseInt(balanceToken2) || assetDetails[asset]['frozen'] === true) {
      setSelectedAssetBurnableBalance(balanceToken1);
    } else {
      setSelectedAssetBurnableBalance(balanceToken2);
    }
    let _allowance = await tokenContract1.methods.allowance(address, assetFactory_nm._address).call();
    let _allowance2 = await tokenContract2.methods.allowance(address, assetFactory_nm._address).call();
    setAllowance(_allowance);
    setAllowance2(_allowance2);
    setSelectedAsset(asset);
    setTokenToBurn(asset);
    setToken1Address(_token1Address);
    setToken2Address(_token2Address);
    closeChooseAssetModal();
  };

  const selectAssetExpired = async (asset) => {
    setSelectedAssetExpired(asset);
    let _token1Address = assetDetails[asset][0];
    let _token2Address = assetDetails[asset][1];
    let tokenContract1 = new web3_nm.eth.Contract(ERC20_ABI, _token1Address);
    let tokenContract2 = new web3_nm.eth.Contract(ERC20_ABI, _token2Address);
    let balanceToken1 = await tokenContract1.methods.balanceOf(address).call();
    let balanceToken2 = await tokenContract2.methods.balanceOf(address).call();
    setBurnableBalanceToken1(balanceToken1);
    setBurnableBalanceToken2(balanceToken2);
    setToken1Address(_token1Address);
    setToken2Address(_token2Address);
    await sleep(50);
    await checkInputExpired();
    closeChooseExpiredAssetModal();
  };

  return {
    view,
    style1,
    style2,
    selectedAsset,
    selectedAssetExpired,
    selectedAssetBurnableBalance,
    burnAmount,
    amountToBurnLong,
    amountToBurnShort,
    amountToGet,
    amountToGetExpired,
    tokenToBurn,
    allowance,
    allowance2,
    submitButtonDeactivated,
    submitButtonDeactivatedMessage,
    chooseAssetModalOpen,
    chooseExpiredAssetModalOpen,
    modalOpen,
    modalBurnExpiredAssetsOpen,
    filteredAssetBurnOptions,
    filteredAssetBurnOptionsExpired,
    burnableBalanceToken1,
    burnableBalanceToken2,
    selectLiveView,
    selectExpiredView,
    openModal,
    closeModal,
    openModalBurnExpiredAssets,
    closeModalBurnExpiredAssets,
    openChooseAssetModal,
    closeChooseAssetModal,
    openChooseExpiredAssetModal,
    closeChooseExpiredAssetModal,
    selectAssetLive,
    selectAssetExpired,
    checkInputLive,
    checkInputExpired,
    setMaxAmount,
    setMaxAmountToken1,
    setMaxAmountToken2,
    filterAssets,
    filterAssetsExpired,
    onSuccessApprove,
    onSuccessBurn,
    stableCoinName,
    assetFactory_ABI,
    assetFactory_Address,
    ERC20_ABI,
    assetDetails,
    outputNumber,
    web3_nm, // Expose web3_nm for use in approve transactions
    loggedIn
  };
};

export default useBurnAssetsLogic;