// src/components/Pages/Portfolio/PortfolioLpTokens.jsx
import React, { useState } from 'react';
import { useAppContext } from '../../../context/AppContext';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AssetLogo from '../../Common/AssetLogo';

const PortfolioLpTokens = ({ lpTokens, LPValue }) => {
  const { outputNumber } = useAppContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // Filter LP tokens based on search term
  const filteredLpTokens = lpTokens
    ? lpTokens.filter(
        (lp) =>
          lp.symbol.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          lp.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
    : [];

  // Sort the filtered LP tokens
  const sortedLpTokens = [...filteredLpTokens].sort((a, b) => {
    if (!sortColumn) return 0;

    let valueA, valueB;
    switch (sortColumn) {
      case 'name':
        valueA = a.name.toLowerCase();
        valueB = b.name.toLowerCase();
        return sortDirection === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      case 'value':
        valueA = parseFloat(a.value || 0);
        valueB = parseFloat(b.value || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      case 'lpBalance':
        valueA = parseFloat(a.lpBalance || 0);
        valueB = parseFloat(b.lpBalance || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      default:
        return 0;
    }
  });

  // Handle sort click
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Render sort indicator (always show, style based on active sort)
  const renderSortIndicator = (column) => {
    const isActive = sortColumn === column;
    const isAsc = sortDirection === 'asc';
    return isActive && !isAsc ? (
      <ArrowDownwardIcon
        fontSize="small"
        className={`sortIcon ${isActive ? 'sortIconActive' : ''}`}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        className={`sortIcon ${isActive ? 'sortIconActive' : ''}`}
      />
    );
  };

  return (
    <div className="portfolioAssetBox">
      <div className="portfolioBoxHeader">
        <div className="headlineSearchWrapper">
          <div>Your liquidity provider tokens</div>
          <div className="searchContainer">
            <SearchIcon className="searchIcon" />
            <input
              type="text"
              placeholder="Search LP tokens..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="searchInput"
            />
          </div>
        </div>
      </div>
      <div className="row mainPortfolioContent">
        <div className="pl-3 pr-3 w-100">
          <table className="w-100">
            <thead>
              <tr>
                <th className="text-left" scope="col">
                  Symbol
                </th>
                <th
                  className="text-left sortable"
                  scope="col"
                  onClick={() => handleSort('name')}
                  style={{ cursor: 'pointer' }}
                >
                  Name {renderSortIndicator('name')}
                </th>
                <th
                  className="text-right sortable"
                  scope="col"
                  onClick={() => handleSort('lpBalance')}
                  style={{ cursor: 'pointer' }}
                >
                  LP-Balance {renderSortIndicator('lpBalance')}
                </th>
                <th className="text-right" scope="col">
                  Withdrawable Assets
                </th>
                <th className="text-right" scope="col">
                  Pool share
                </th>
                <th
                  className="text-right sortable"
                  scope="col"
                  onClick={() => handleSort('value')}
                  style={{ cursor: 'pointer' }}
                >
                  Value (USD) {renderSortIndicator('value')}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedLpTokens.length > 0 ? (
                sortedLpTokens.map((lp, index) => (
                  <tr key={index}>
                    <td className="text-left assetCell">
                        <div className="assetNameSymbol">
                            <AssetLogo symbol={lp.symbol} />
                            <span>{lp.symbol}</span>
                        </div>
                    </td>
                    <td className="text-left">{lp.name}</td>
                    <td className="text-right">{lp.lpBalance}</td>
                    <td className="text-right">{lp.withdrawableAssets}</td>
                    <td className="text-right">{lp.poolShare}</td>
                    <td className="text-right">{lp.value}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No LP tokens available</td>
                </tr>
              )}
              <tr>
                <td className="text-left">
                  <b>Total LP tokens</b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-right">
                  <b>{outputNumber(LPValue, 0)}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PortfolioLpTokens;