import React from 'react';
import { Modal } from 'react-bootstrap';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';

const SettingsModal = ({
  show,
  onHide,
  slippage,
  trxTime,
  saveSettingsSlippage,
  saveSettingsTrxTime,
  wrongSlippageInputMessage,
  highSlippageInputMessage,
  wrongTrxTimeInputMessage,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Choose your preferences</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBodyScroll">
        <div className="settingsRow">
          <div>Slippage tolerance</div>
          <div className="settingsInputArea">
            <input
              type="text"
              id="slippage"
              onChange={saveSettingsSlippage}
              placeholder={slippage / 100} // Convert basis points to percentage for display
            />
            <PercentOutlinedIcon fontSize="inherit" />
          </div>
        </div>
        {wrongSlippageInputMessage && (
          <div className="row pl-3 pt-3 w-100 text-danger">
            Please input a valid slippage percentage
          </div>
        )}
        {highSlippageInputMessage && (
          <div className="row pl-3 pt-3 w-100 text-danger">
            Warning. Your transaction may be frontrun
          </div>
        )}
        <div> </div>
        <div className="settingsRow">
          <div>Max transaction time</div>
          <div className="settingsInputArea">
            <input
              type="text"
              id="trxTime"
              onChange={saveSettingsTrxTime}
              placeholder={trxTime}
            />
            <AccessTimeOutlinedIcon fontSize="inherit" />
          </div>
        </div>
        {wrongTrxTimeInputMessage && (
          <div className="row pl-3 pt-3 w-100 text-danger">
            Please input a valid transaction time
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SettingsModal;