import React, { useRef, useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext';
import '../../../styles/referralProgress.css';

const ReferralProgress = () => {
  const { referralData } = useAppContext();
  const referrals = referralData?.referrals || [];
  const totalReferrals = referrals.length;
  const totalReferralsNeeded = 10; // Assuming 10 referrals are needed for bonus rewards
  const progress = Math.min(Math.max((totalReferrals / totalReferralsNeeded) * 100, 0), 100);

  // State for copy confirmation
  const [copied, setCopied] = useState(false);

  // Handle copy to clipboard
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText('https://app.twinfinance.io?refCode='+referralData?.your_ref_code || '');
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Hide message after 2 seconds
    } catch (err) {
      console.error('Copy failed:', err);
    }
  };

  // Progress bar reference for animations
  const progressBarRef = useRef(null);

  useEffect(() => {
    const progressBar = progressBarRef.current;
    if (progressBar) {
      progressBar.style.transition = 'width 0.5s ease-in-out';
    }
  }, [progress]);

  if (!referralData) {
    return <div className="loading-indicator">Loading referral progress...</div>;
  }

  return (
    <div className="referral-progress-container">
      <h2 className="title">Referral Progress</h2>

      <div className="progress-section">
        <div className="referral-progress-bar"> {/* Renamed from progress-bar */}
          <div 
            className="progress-fill" 
            style={{ width: `${progress}%` }}
            ref={progressBarRef}
          />
          <span className="progress-text">
            {totalReferrals}/{totalReferralsNeeded} referrals ({progress.toFixed(0)}%)
          </span>
        </div>
        <p className="progress-message">
          Complete {totalReferralsNeeded} referrals to unlock bonus rewards!
        </p>
        <button 
          className="invite-button" 
          onClick={handleCopy}
          disabled={!referralData.your_ref_code}
        >
          Copy Referral Link
        </button>
        {copied && <span className="copy-confirmation">Copied!</span>}
      </div>
    </div>
  );
};

export default ReferralProgress;