// src/components/Pages/Portfolio/MobilePortfolioLpTokens.jsx
import React, { useState } from 'react';
import { useAppContext } from '../../../context/AppContext';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AssetLogo from '../../Common/AssetLogo';

const MobilePortfolioLpTokens = ({ lpTokens, LPValue }) => {
  const { outputNumber } = useAppContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // Filter LP tokens based on search term
  const filteredLpTokens = lpTokens
    ? lpTokens.filter(
        (lp) =>
          lp.symbol.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          lp.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
    : [];

  // Sort the filtered LP tokens
  const sortedLpTokens = [...filteredLpTokens].sort((a, b) => {
    if (!sortColumn) return 0;

    let valueA, valueB;
    switch (sortColumn) {
      case 'name':
        valueA = a.name.toLowerCase();
        valueB = b.name.toLowerCase();
        return sortDirection === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      case 'value':
        valueA = parseFloat(a.value || 0);
        valueB = parseFloat(b.value || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      case 'lpBalance':
        valueA = parseFloat(a.lpBalance || 0);
        valueB = parseFloat(b.lpBalance || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      default:
        return 0;
    }
  });

  // Handle sort click
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Render sort indicator (always show, style based on active sort)
  const renderSortIndicator = (column) => {
    const isActive = sortColumn === column;
    const isAsc = sortDirection === 'asc';
    return isActive && !isAsc ? (
      <ArrowDownwardIcon
        fontSize="small"
        className={`sortIconMobile ${isActive ? 'sortIconActiveMobile' : ''}`}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        className={`sortIconMobile ${isActive ? 'sortIconActiveMobile' : ''}`}
      />
    );
  };

  return (
    <div className="portfolioAssetBoxMobile">
      <div className="portfolioBoxHeaderMobile">
        <div className="headlineSearchWrapperMobile">
          <div>Your liquidity provider tokens</div>
          <div className="searchContainerMobile">
            <SearchIcon className="searchIconMobile" />
            <input
              type="text"
              placeholder="Search LP tokens..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="searchInputMobile"
            />
          </div>
        </div>
      </div>
      <div className="row mainPortfolioContentMobile">
        <div className="pl-3 pr-3 w-100">
          <div className="sortButtonsMobile">
            <button onClick={() => handleSort('name')} className="sortButtonMobile">
              Name {renderSortIndicator('name')}
            </button>
            <button onClick={() => handleSort('value')} className="sortButtonMobile">
              Value {renderSortIndicator('value')}
            </button>
            <button onClick={() => handleSort('lpBalance')} className="sortButtonMobile">
              Balance {renderSortIndicator('lpBalance')}
            </button>
          </div>
          {sortedLpTokens.length > 0 ? (
            <div className="w-100">
              {sortedLpTokens.map((lp, index) => (
                <div className="portfolioCard" key={index}>
                  <div className="portfolioCardHeader">
                    <div className="text-left bold"><AssetLogo symbol={lp.symbol} /> {lp.name} ({lp.symbol})</div>
                  </div>
                  <div className="text-left">LP-Balance: {lp.lpBalance}</div>
                  <div className="text-left">Withdrawable Assets: {lp.withdrawableAssets}</div>
                  <div className="text-left">Pool Share: {lp.poolShare}</div>
                  <div className="text-left">Value: ${lp.value}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="portfolioCard">
              <div>No LP tokens available</div>
            </div>
          )}
          <div className="portfolioCardSummary">
            <div className="text-left portfolioCardHeader">Total LP Tokens: $ {outputNumber(LPValue, 0)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePortfolioLpTokens;