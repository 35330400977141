import React, { Component } from 'react';
import '../../styles/tou.css';

class PrivacyPolicy extends Component {
    render() { 
        return ( 
            <div className="mainContainer">
                <div className="middleBoxFull">
                    <div className='contentContainer'>
                        <div className="mainBox tou">
                            <h1>PRIVACY POLICY</h1>
                            <p className="last-updated">Last Updated: February 20, 2025</p>

                            <p>Welcome to Twin DAO ("Twin", "we", "us", or "our"). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you interact with our decentralized protocol, website, and related services (collectively, the "Protocol"). By using the Protocol, you agree to the practices outlined in this policy.</p>

                            <h2>1. Information We Collect</h2>
                            <p>We may collect the following types of information when you use the Protocol:</p>
                            <ul>
                                <li><strong>Personal Information</strong>: Information that identifies you, such as your name, email address, or wallet address, which you may provide when signing up for updates or contacting us.</li>
                                <li><strong>Non-Personal Information</strong>: Data that does not identify you directly, such as browsing history, usage statistics, or device information.</li>
                                <li><strong>Blockchain Data</strong>: Transaction details and wallet addresses recorded on the blockchain, which are inherently public due to the decentralized nature of the Protocol.</li>
                            </ul>

                            <h2>2. How We Collect Information</h2>
                            <p>We collect information through the following methods:</p>
                            <ul>
                                <li><strong>Directly from You</strong>: When you submit information via forms, sign-ups, or interactions with the Protocol.</li>
                                <li><strong>Automatically</strong>: Using cookies, web beacons, or similar technologies to track your usage of the Protocol.</li>
                                <li><strong>From Third Parties</strong>: From analytics providers, blockchain networks, or other partners that help us operate and secure the Protocol.</li>
                            </ul>

                            <h2>3. Why We Collect Information</h2>
                            <p>We collect your information for the following purposes:</p>
                            <ul>
                                <li>To provide and enhance the Protocol's services, including transaction processing and governance participation.</li>
                                <li>To communicate with you, such as responding to inquiries or sending updates.</li>
                                <li>To comply with legal obligations, such as regulatory requirements.</li>
                                <li>To analyze usage trends and improve the Protocol’s functionality.</li>
                                <li>To send promotional materials or newsletters, if you have opted in.</li>
                            </ul>

                            <h2>4. How We Use Your Information</h2>
                            <p>We use your information to:</p>
                            <ul>
                                <li><strong>Enable Services</strong>: Facilitate your ability to mint, trade, or manage assets and participate in the Protocol.</li>
                                <li><strong>Process Transactions</strong>: Execute blockchain-based transactions via smart contracts.</li>
                                <li><strong>Support Users</strong>: Address your questions or technical issues.</li>
                                <li><strong>Market the Protocol</strong>: Share updates or offers, with your consent.</li>
                                <li><strong>Analyze Data</strong>: Understand how the Protocol is used to improve its performance.</li>
                            </ul>

                            <h2>5. How We Share Your Information</h2>
                            <p>We may share your information in these cases:</p>
                            <ul>
                                <li><strong>Service Providers</strong>: With third parties who assist in operating the Protocol (e.g., hosting or analytics services).</li>
                                <li><strong>Affiliates</strong>: With related entities for administrative or service-related purposes.</li>
                                <li><strong>Legal Requirements</strong>: When required by law, such as in response to a subpoena or regulatory request.</li>
                                <li><strong>Business Transfers</strong>: In the event of a merger, acquisition, or asset sale involving Twin DAO.</li>
                            </ul>

                            <h2>6. Your Rights and Choices</h2>
                            <p>You have the following options regarding your information:</p>
                            <ul>
                                <li><strong>Access</strong>: Request to see the personal data we hold about you.</li>
                                <li><strong>Correction</strong>: Ask us to update or correct inaccurate data.</li>
                                <li><strong>Deletion</strong>: Request removal of your personal data, subject to legal limitations.</li>
                                <li><strong>Opt-Out</strong>: Unsubscribe from marketing emails via the link provided or by contacting us.</li>
                                <li><strong>Cookies</strong>: Adjust your browser or Protocol settings to manage cookie preferences.</li>
                            </ul>
                            <p>To exercise these rights, please contact us using the details in Section 11.</p>

                            <h2>7. Data Security</h2>
                            <p>We take steps to protect your information, including:</p>
                            <ul>
                                <li>Encryption of sensitive data.</li>
                                <li>Access controls to authorized personnel only.</li>
                                <li>Regular security reviews.</li>
                            </ul>
                            <p>However, no method is 100% secure, and you are responsible for securing your wallet and private keys.</p>

                            <h2>8. International Data Transfers</h2>
                            <p>The Protocol operates globally, so your data may be transferred to and processed in countries outside your own, which may have different privacy laws. By using the Protocol, you consent to these transfers.</p>

                            <h2>9. Children’s Privacy</h2>
                            <p>The Protocol is not designed for users under 18. We do not knowingly collect data from children and will delete such data if identified.</p>

                            <h2>10. Changes to This Privacy Policy</h2>
                            <p>We may revise this policy as needed. Material changes will be communicated via the Protocol or other channels. Your continued use after updates indicates acceptance of the new terms.</p>

                            <h2>11. Contact Us</h2>
                            <p>For questions or concerns about this policy, reach us at:</p>
                            <p>Email: <a href="mailto:info@twinfinance.io">info@twinfinance.io</a></p>

                            <h2>12. Additional Information</h2>
                            <p>This policy does not cover third-party sites or services linked from the Protocol. Please review their privacy policies separately.</p>
                            <p>By using the Protocol, you confirm that you have read and agree to this Privacy Policy.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;