import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap';
import './scss/custom.css';
import 'bootstrap/dist/js/bootstrap.js';

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { WagmiProvider } from 'wagmi';
import { berachain } from 'wagmi/chains'; // Assuming this exists
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Create a QueryClient instance
const queryClient = new QueryClient();

// Use predefined Berachain chain
const chains = [berachain];
const projectId = '7cafe8d302b5b80c089eeec2204ede6f';

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

// Configure wagmi with predefined chain
const wagmiConfig = defaultWagmiConfig({ 
  chains, 
  projectId, 
  metadata,
  autoConnect: false, // Set to false to test manual disconnect
});

// Initialize Web3Modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    '--w3m-font-family': 'PT+Sans',
    '--w3m-color-mix': '#232323',
    '--w3m-color-mix-strength': 20,
    '--w3m-accent': '#232323',
  },
});

// Type the root element
const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={wagmiConfig}>
        <App />
      </WagmiProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

reportWebVitals();