import React, { useState, useEffect } from 'react';
import { useSignMessage, useAccount } from 'wagmi';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useAppContext } from '../../../context/AppContext';
import SocialShare from './SocialShare'; // Import SocialShare
import '../../../styles/generateRefCode.css';

const GenerateRefCode = () => {
  const { myRefCode, setMyRefCode } = useAppContext();
  const { address } = useAccount();
  const { signMessageAsync, signMessage } = useSignMessage();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCopied, setShowCopied] = useState(false); // State for copy feedback
  const url = 'https://aapi.twinfinance.io/generate-refcode';

  const generateRefCode = async () => {
    try {
      const newRefCode = uuidv4().split('-')[0];
      const message = `I am generating a referral code for TWIN Finance. Referral Code: ${newRefCode}`;
      const signature = await signMessageAsync({ message });

      await axios.post(url, {
        address: address,
        referral_code: newRefCode,
        signature: signature,
      });

      setMyRefCode(newRefCode);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error('Error generating referral code:', error);
    }
  };

  const copyRefLink = () => {
    if (!myRefCode) return;
    const refLink = `https://app.twinfinance.io?refCode=${myRefCode}`;
    navigator.clipboard.writeText(refLink);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
  };

  return (
    <div className="generate-refcode-container">
      <p className="referral-explanation">
        Earn 20% of the points from everyone who joins using your referral link! The more people you refer, the more points you earn.
      </p>

      {myRefCode ? (
        <div className="refcode-actions">
          <div className="refcode-display">
            <p>
              Your referral code: <strong>{myRefCode}</strong>
            </p>
            <p className="ref-link">
              Link: https://app.twinfinance.io/?refCode={myRefCode}
            </p>
          </div>
          
          <div className="action-buttons">
            <button className="action-button generate" onClick={copyRefLink}>
              {showCopied ? 'Copied!' : 'Copy Link'}
            </button>
            <SocialShare /> {/* Integrated SocialShare here */}
          </div>
          
          {(showSuccess || showCopied) && (
            <p className="success-message">
              {showSuccess ? 'Referral code generated successfully!' : 'Link copied to clipboard!'}
            </p>
          )}
        </div>
      ) : (
        <button 
          onClick={generateRefCode} 
          disabled={!address}
          className="action-button generate"
        >
          Generate Referral Code
        </button>
      )}
    </div>
  );
};

export default GenerateRefCode;