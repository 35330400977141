import '../../../styles/portfolio.css';
import React, { useState, useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import PortfolioAssets from './PortfolioAssets';
import PortfolioAssetsMarketless from './PortfolioAssetsMarketless';
import PortfolioLpTokens from './PortfolioLpTokens';
import AssetLogo from '../../Common/AssetLogo';
import usePortfolioLogic from './usePortfolioLogic';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';
import CircularProgress from '@mui/material/CircularProgress';

const Portfolio = () => {
  const {
    portfolio,
    portfolioAssets,
    lpTokens,
    assetValue,
    LPValue,
    outputNumber,
    marketLive,
    loading,
    loggedIn,
  } = usePortfolioLogic();

  const { open } = useWeb3Modal();
  const account = useAccount();
  const address = account.address;
  const [isConnecting, setIsConnecting] = useState(false);

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 4)}...${address.substring(address.length - 5)}`;
  };

  const addressShort = shortenAddress(address);

  const handleConnect = async () => {
    setIsConnecting(true);
    await open();
  };

  useEffect(() => {
    if (address) {
      setIsConnecting(false);
    }
  }, [address]);

  useEffect(() => {
    console.log('Portfolio - address:', address, 'loggedIn:', loggedIn, 'isConnecting:', isConnecting);
  }, [address, loggedIn, isConnecting]);

  return (
    <div className="mainContainer">
      <div className="middleBoxFull">
        <div className="contentContainer">
          {/* Portfolio Total Value */}
          <div className="portfolioTotalValueBox">
            <div className="infoBoxIcon">
              <AttachMoneyOutlinedIcon />
            </div>
            <div className="infoBoxItem">
              {loading ? 'Loading...' : loggedIn ? outputNumber(parseFloat(assetValue) + parseFloat(LPValue), 0) + " USD" : "0 USD"}
            </div>
            <div className="infoBoxItem2">
              {loggedIn ? "Total portfolio value" : "Connect wallet to see your portfolio"}
            </div>
          </div>

          {/* Loading State */}
          {loading && (
            <div className="text-center mt-4">
              <CircularProgress
                size={30}
                thickness={7}
                sx={{
                  color: '#086A24',
                  animationDuration: '5s',
                }}
              />
              <div style={{ marginTop: '10px', color: '#086A24', fontSize: '14px' }}>
                Loading your assets...
              </div>
            </div>
          )}

          {/* LP Tokens */}
          {!loading && marketLive && loggedIn && (
            <PortfolioLpTokens lpTokens={lpTokens} LPValue={LPValue} />
          )}

          {/* Portfolio Assets (Marketless) */}
          {!loading && !marketLive && loggedIn && (
            <PortfolioAssetsMarketless
              portfolio={portfolio}
              portfolioAssets={portfolioAssets}
              loading={loading}
            />
          )}

          {/* Portfolio Assets */}
          {!loading && marketLive && loggedIn && (
            <PortfolioAssets portfolio={portfolio} portfolioAssets={portfolioAssets} />
          )}

          {/* Connect Wallet Placeholder */}
          {!loggedIn && !loading && (
            <div className="text-center mt-4">
              <p>Please connect your wallet to view your assets and LP tokens.</p>
              <div role="button" className="issuaaButton" onClick={handleConnect}>
                {isConnecting ? (
                  <span>
                    <div className="spinner-border spinner-border-sm spinner-border-slow" role="status" />
                    Connecting...
                  </span>
                ) : address ? (
                  <span>{addressShort}</span>
                ) : (
                  <span>Connect Wallet</span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;