import { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext';
import { ethers } from 'ethers';
import { tokenLess } from '../../../config';

const useMarketLogic = () => {
  const {
    USDCBalance,
    USDC_Address,
    MarketRouter_Address,
    web3_nm,
    outputNumber,
    address,
    MarketFactory_nm,
    USDDecimals,
    sleep,
    updateUserData,
    updateISSPrice,
    updateAssetBalanceWithAddress,
    updatePriceWithAddress,
    updatePortfolioValue,
    loadUSDBalance,
    slippage,
    trxTime,
    MarketRouter_ABI,
    MarketPair_ABI,
    ERC20_ABI,
    stableCoinName,
    assetDetails,
    GovernanceTokenBalance,
    GovernanceToken_Address,
    saveSlippagePreference,
    saveTrxTimePreference,
    loadLPBalances,
  } = useAppContext();

  // State variables
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState("Select Asset");
  const [selectedAssetAddress, setSelectedAssetAddress] = useState('');
  const [selectedAssetBalance, setSelectedAssetBalance] = useState(0);
  const [chooseAssetModalOpen, setChooseAssetModalOpen] = useState(false);
  const [priceDataVisible, setPriceDataVisible] = useState(false);
  const [approvalButtonVisible, setApprovalButtonVisible] = useState(false);
  const [sellPartVisible, setSellPartVisible] = useState(false);
  const [buyPartVisible, setBuyPartVisible] = useState(true);
  const [style1, setStyle1] = useState("selectButtonSelected");
  const [style2, setStyle2] = useState("selectButtonUnselected");
  const [priceImpactBuy, setPriceImpactBuy] = useState(0);
  const [sellButtonVisible, setSellButtonVisible] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [buyButtonVisible, setBuyButtonVisible] = useState(false);
  const [warningButtonVisible, setWarningButtonVisible] = useState(false);
  const [warningButtonMessage, setWarningButtonMessage] = useState("warning");
  const [USDApprovalButtonVisible, setUSDApprovalButtonVisible] = useState(false);
  const [assetBalances, setAssetBalances] = useState();
  const [assetAddresses, setAssetAddresses] = useState();
  const [wrongSlippageInputMessage, setWrongSlippageInputMessage] = useState();
  const [highSlippageInputMessage, setHighSlippageInputMessage] = useState();
  const [wrongTrxTimeInputMessage, setWrongTrxTimeInputMessage] = useState();
  const [allowanceToken, setAllowanceToken] = useState();
  const [allowanceUSDC, setAllowanceUSDC] = useState();
  const [token0, setToken0] = useState();
  const [token1, setToken1] = useState();
  const [reserves0, setReserves0] = useState();
  const [reserves1, setReserves1] = useState();
  const [kFactor, setKFactor] = useState();
  const [AssetAmountIn, setAssetAmountIn] = useState();
  const [TokenPayoutAmount, setTokenPayoutAmount] = useState();
  const [tokenPrice, setTokenPrice] = useState();
  const [actualPrice, setActualPrice] = useState();
  const [amountIn, setAmountIn] = useState();
  const [amountOutMin, setAmountOutMin] = useState();
  const [path, setPath] = useState([]);
  const [deadline, setDeadline] = useState();
  const [USDCPayoutAmount, setUSDCPayoutAmount] = useState();
  const [USDCPayoutAmountMin, setUSDCPayoutAmountMin] = useState();
  const [priceImpact, setPriceImpact] = useState();
  const [liquidityProviderFee, setLiquidityProviderFee] = useState();
  const [USDCAmountIn, setUSDCAmountIn] = useState();
  const [TokenPayoutAmountMin, setTokenPayoutAmountMin] = useState();

  // Effects
  useEffect(() => {
    const initialize = async () => {
      let approvalGiven = await checkApproval(USDC_Address, MarketRouter_Address);
      setUSDApprovalButtonVisible(!approvalGiven);
      const locationUnSplit = window.location.pathname;
      if (locationUnSplit) {
        const locationSplit = locationUnSplit.split("/");
        const buyOrSellVisible = locationSplit[2];
        const pairAddress = locationSplit[3];
        if (buyOrSellVisible === 'sell') await showSell();
        initiatiatePage(pairAddress, buyOrSellVisible);
      }
    };
    initialize();
  }, []);

  useEffect(() => {
    console.log(USDC_Address);
  }, [USDC_Address]);

  // Logic Functions
  const initiatiatePage = async (pairAddress, buyOrSellVisible) => {
    try {
      let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pairAddress);
      let token0 = await MarketPair.methods.token0().call();
      let token1 = await MarketPair.methods.token1().call();
      let TokenContract0 = new web3_nm.eth.Contract(ERC20_ABI, token0);
      let tokenSymbol0 = await TokenContract0.methods.symbol().call();
      let TokenContract1 = new web3_nm.eth.Contract(ERC20_ABI, token1);
      let tokenSymbol1 = await TokenContract1.methods.symbol().call();
      let selectedAsset = tokenSymbol0 === stableCoinName ? tokenSymbol1 : tokenSymbol0;
      let assetAddress = tokenSymbol0 === stableCoinName ? token1 : token0;
      selectAssetInitial(selectedAsset, assetAddress, pairAddress);
    } catch (err) {
      console.log(err.message);
    }
  };

  const roundDown = (n, d) => Math.floor(n * (10 ** d)) / (10 ** d);

  const openSettingsModal = () => setSettingsModalOpen(true);
  const closeSettingsModal = () => setSettingsModalOpen(false);

  const checkSlippageInput = (inputValue) => {
    const slippageValue = parseFloat(inputValue) * 100; // Convert to basis points (e.g., 0.5% = 50)
    if (isNaN(slippageValue) || slippageValue < 0 || slippageValue > 5000) {
      setWrongSlippageInputMessage(true);
      setHighSlippageInputMessage(false);
      return false;
    }
    setWrongSlippageInputMessage(false);
    setHighSlippageInputMessage(slippageValue > 500 && slippageValue <= 5000);
    return true;
  };

  const saveSettingsSlippage = async (e) => {
    const inputValue = e.target.value;
    if (checkSlippageInput(inputValue)) {
      const slippageValue = parseFloat(inputValue) * 100; // Convert to basis points
      await saveSlippagePreference(slippageValue); // Update AppContext
    }
  };

  const checkTrxTimeInput = (inputValue) => {
    const trxTimeValue = parseInt(inputValue);
    if (isNaN(trxTimeValue) || trxTimeValue < 0 || trxTimeValue > 600) {
      setWrongTrxTimeInputMessage(true);
      return false;
    }
    setWrongTrxTimeInputMessage(false);
    return true;
  };

  const saveSettingsTrxTime = async (e) => {
    const inputValue = e.target.value;
    if (checkTrxTimeInput(inputValue)) {
      const trxTimeValue = parseInt(inputValue);
      await saveTrxTimePreference(trxTimeValue); // Update AppContext
    }
  };

  const openChooseAssetModal = () => {
    let _assets = [];
    let _assetBalances = {};
    let _assetAddresses = {};
    if (!tokenLess) {
      _assets.push(["TWIN", GovernanceTokenBalance, "TWIN Protocol Token"]);
      _assetBalances["TWIN"] = GovernanceTokenBalance;
      _assetAddresses["TWIN"] = GovernanceToken_Address;
    }
    for (let key in assetDetails) {
      if (typeof assetDetails[key]['priceLong'] !== 'undefined') {
        _assets.push([key, assetDetails[key]['tokenBalance1'], assetDetails[key]['name'] || key]);
        _assetBalances[key] = assetDetails[key]['tokenBalance1'];
        _assetAddresses[key] = assetDetails[key]['Token1'];
      }
      if (typeof assetDetails[key]['priceShort'] !== 'undefined') {
        const baseName = assetDetails[key]?.['name'] || key.slice(1);
        _assets.push(["i" + key, assetDetails[key]['tokenBalance2'], `Short ${baseName}`]);
        _assetBalances["i" + key] = assetDetails[key]['tokenBalance2'];
        _assetAddresses["i" + key] = assetDetails[key]['Token2'];
      }
    }
    setAssets(_assets);
    setFilteredAssets(_assets);
    setAssetBalances(_assetBalances);
    setAssetAddresses(_assetAddresses);
    setChooseAssetModalOpen(true);
  };

  const closeChooseAssetModal = () => setChooseAssetModalOpen(false);

  const openChooseAssetModalSell = () => {
    let _assets = [];
    let _assetBalances = {};
    let _assetAddresses = {};
    if (GovernanceTokenBalance > 0.001) {
      _assets.push(["TWIN", GovernanceTokenBalance, "TWIN Protocol Token"]);
      _assetBalances["TWIN"] = GovernanceTokenBalance;
      _assetAddresses["TWIN"] = GovernanceToken_Address;
    }
    for (let key in assetDetails) {
      if (assetDetails[key]['tokenBalance1'] > 0.00001) {
        _assets.push([key, assetDetails[key]['tokenBalance1'], assetDetails[key]['name'] || key]);
        _assetBalances[key] = assetDetails[key]['tokenBalance1'];
      }
      if (assetDetails[key]['tokenBalance2'] > 0.00001) {
        const baseName = assetDetails[key]?.['name'] || key.slice(1);
        _assets.push(["i" + key, assetDetails[key]['tokenBalance2'], `Short ${baseName}`]);
        _assetBalances["i" + key] = assetDetails[key]['tokenBalance2'];
      }
      _assetAddresses[key] = assetDetails[key]['Token1'];
      _assetAddresses["i" + key] = assetDetails[key]['Token2'];
    }
    setAssets(_assets);
    setFilteredAssets(_assets);
    setAssetBalances(_assetBalances);
    setAssetAddresses(_assetAddresses);
    setChooseAssetModalOpen(true);
  };

  const filterAssets = () => {
    let _filteredAssets = [];
    let searchTerm = document.getElementById('search').value.toLowerCase();
    for (let i = 0; i < assets.length; ++i) {
      if (
        assets[i][0].toLowerCase().includes(searchTerm) ||
        assets[i][2].toLowerCase().includes(searchTerm)
      ) {
        _filteredAssets.push(assets[i]);
      }
    }
    setFilteredAssets(_filteredAssets);
  };

  const listAssets = () => filteredAssets.map((element, index) => ({
    key: index,
    symbol: element[0],
    balance: outputNumber(element[1], 4),
    name: element[2],
  }));

  const checkAllowances = async (_tokenAddress) => {
    let tokenContract = new web3_nm.eth.Contract(ERC20_ABI, _tokenAddress);
    let _allowanceToken = await tokenContract.methods.allowance(address, MarketRouter_Address).call();
    setAllowanceToken(parseInt(_allowanceToken));
    let USDCContract = new web3_nm.eth.Contract(ERC20_ABI, USDC_Address);
    let _allowanceUSDC = await USDCContract.methods.allowance(address, MarketRouter_Address).call();
    setAllowanceUSDC(parseInt(_allowanceUSDC));
  };

  const selectAsset = async (asset) => {
    checkAllowances(assetAddresses[asset]);
    setSelectedAsset(asset);
    setSelectedAssetBalance(assetBalances[asset]);
    setSelectedAssetAddress(assetAddresses[asset]);
    closeChooseAssetModal();

    let pair = await MarketFactory_nm.methods.getPair(assetAddresses[asset], USDC_Address).call();
    let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
    let reserves = await MarketPair.methods.getReserves().call();
    let token0 = await MarketPair.methods.token0().call();
    let token1 = await MarketPair.methods.token1().call();
    let kFactor = reserves[0] * reserves[1];
    setKFactor(kFactor);
    setReserves0(reserves[0]);
    setReserves1(reserves[1]);
    setToken0(token0);
    setToken1(token1);

    let _tokenPrice = token0 === USDC_Address
      ? parseInt(reserves[0]) / parseInt(reserves[1]) * (10 ** (18 - USDDecimals))
      : parseInt(reserves[1]) / parseInt(reserves[0]) * (10 ** (USDDecimals - 18));
    setTokenPrice(_tokenPrice);

    let approvalGiven = await checkApproval(assetAddresses[asset], MarketRouter_Address);
    setApprovalButtonVisible(!approvalGiven);

    if (sellPartVisible) {
      document.getElementById('assetAmountIn').value = '';
      calculateTradeResult();
    } else {
      document.getElementById('USDCAmountIn').value = '';
      calculateBuyResult();
    }
    setPriceDataVisible(false);
  };

  const selectAssetInitial = async (asset, assetAddress, pair) => {
    setSelectedAsset(asset);
    setSelectedAssetAddress(assetAddress);

    let tokenContract = new web3_nm.eth.Contract(ERC20_ABI, assetAddress);
    let selectedAssetBalanceRaw = await tokenContract.methods.balanceOf(address).call();
    setSelectedAssetBalance(parseInt(selectedAssetBalanceRaw) / 1e18);

    checkAllowances(assetAddress);

    let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
    let reserves = await MarketPair.methods.getReserves().call();
    let token0 = await MarketPair.methods.token0().call();
    let token1 = await MarketPair.methods.token1().call();
    let kFactor = reserves[0] * reserves[1];
    setKFactor(kFactor);
    setReserves0(reserves[0]);
    setReserves1(reserves[1]);
    setToken0(token0);
    setToken1(token1);

    let _tokenPrice = token0 === USDC_Address
      ? parseInt(reserves[0]) / parseInt(reserves[1]) * (10 ** (18 - USDDecimals))
      : parseInt(reserves[1]) / parseInt(reserves[0]) * (10 ** (USDDecimals - 18));
    setTokenPrice(_tokenPrice);

    let approvalGiven = await checkApproval(assetAddress, MarketRouter_Address);
    setApprovalButtonVisible(!approvalGiven);

    if (sellPartVisible) {
      document.getElementById('assetAmountIn').value = 0;
      calculateTradeResult();
    } else {
      try { document.getElementById('USDCAmountIn').value = 0; } catch { console.log("error"); }
      calculateBuyResult();
    }
    setPriceDataVisible(false);
  };

  const calculateBuyResult = async () => {
    if (selectedAsset === 'Select Asset') {
      document.getElementById('USDCAmountIn').value = '';
      document.getElementById('TokenPayoutAmount').value = '';
      return;
    }
    checkAllowances(selectedAssetAddress);
    let input = document.getElementById('USDCAmountIn').value;
    if (parseFloat(input) === 0) {
      document.getElementById('TokenPayoutAmount').value = '';
      setBuyButtonVisible(false);
      setPriceDataVisible(false);
      return;
    }
    const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(input);
    if (!isPositiveNumber) {
      if (!input || typeof USDCAmountIn === 'undefined') {
        document.getElementById('TokenPayoutAmount').value = '';
        document.getElementById('USDCAmountIn').value = '';
        setPriceDataVisible(false);
        return;
      }
      document.getElementById('USDCAmountIn').value = USDCAmountIn / (10 ** USDDecimals);
      return;
    }
    let _USDCAmountIn = parseFloat(input) * (10 ** USDDecimals);
    if (_USDCAmountIn > parseFloat(USDCBalance) * (10 ** USDDecimals)) {
      setBuyButtonVisible(false);
      setWarningButtonVisible(true);
      setWarningButtonMessage("Balance too low");
    } else {
      setBuyButtonVisible(true);
      setWarningButtonVisible(false);
    }
    if (_USDCAmountIn === 0) return;

    let _TokenPayoutAmount, _liquidityProviderFee = _USDCAmountIn * 0.003, _actualPrice;
    if (token1 === USDC_Address) {
      let newUSDCPoolBalance = parseInt(reserves1) + parseInt(_USDCAmountIn);
      let newTokenReserves = Number(kFactor) / newUSDCPoolBalance;
      _TokenPayoutAmount = (parseInt(reserves0) - newTokenReserves) * 0.997;
      _actualPrice = (_USDCAmountIn / (10 ** USDDecimals)) / (_TokenPayoutAmount / 1e18); // Normalize to human-readable units
      if (isNaN(_actualPrice)) {
        document.getElementById('TokenPayoutAmount').value = '';
        document.getElementById('USDCAmountIn').value = '';
        return;
      }
      document.getElementById('TokenPayoutAmount').value = (_TokenPayoutAmount / 1e18).toFixed(6);
    } else if (token0 === USDC_Address) {
      let newUSDCPoolBalance = parseInt(reserves0) + _USDCAmountIn;
      let newTokenReserves = Number(kFactor) / newUSDCPoolBalance;
      _TokenPayoutAmount = (parseInt(reserves1) - newTokenReserves) * 0.997;
      _actualPrice = (_USDCAmountIn / (10 ** USDDecimals)) / (_TokenPayoutAmount / 1e18); // Normalize to human-readable units
      if (isNaN(_actualPrice)) {
        document.getElementById('TokenPayoutAmount').value = '';
        document.getElementById('USDCAmountIn').value = '';
        return;
      }
      document.getElementById('TokenPayoutAmount').value = (_TokenPayoutAmount / 1e18).toFixed(6);
    }
    setTokenPayoutAmount(_TokenPayoutAmount);
    let _slippage = slippage / 10000;
    let _TokenPayoutAmountMin = parseInt(_TokenPayoutAmount * (1 - parseFloat(_slippage)));
    setTokenPayoutAmountMin(_TokenPayoutAmountMin);
    setUSDCAmountIn(_USDCAmountIn);
    setLiquidityProviderFee(_liquidityProviderFee);
    setActualPrice(_actualPrice);

    let _priceImpactBuy = ((_actualPrice - parseFloat(tokenPrice)) / parseFloat(tokenPrice)) * 100;
    setPriceImpactBuy(_priceImpactBuy);
    let _path = [USDC_Address, selectedAssetAddress];
    let _deadline = Math.round(+new Date() / 1000) + (60 * 10);
    setPath(_path);
    setDeadline(_deadline);
    setPriceDataVisible(_priceImpactBuy > 0);
  };

  const calculateBuyResultToken = async () => {
    if (selectedAsset === 'Select Asset') {
      document.getElementById('USDCAmountIn').value = '';
      document.getElementById('TokenPayoutAmount').value = '';
      return;
    }
    checkAllowances(selectedAssetAddress);
    let input = document.getElementById('TokenPayoutAmount').value;
    if (parseFloat(input) === 0) {
      document.getElementById('USDCAmountIn').value = '';
      setBuyButtonVisible(false);
      setPriceDataVisible(false);
      return;
    }
    const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(input);
    if (!isPositiveNumber) {
      if (!input || typeof TokenPayoutAmount === 'undefined') {
        document.getElementById('USDCAmountIn').value = '';
        document.getElementById('TokenPayoutAmount').value = '';
        setTokenPayoutAmount();
        setPriceDataVisible(false);
        return;
      }
      document.getElementById('TokenPayoutAmount').value = TokenPayoutAmount / 1e18;
      return;
    }
    let _TokenPayoutAmount = parseFloat(input) * 1e18;
    if (_TokenPayoutAmount < 0) {
      _TokenPayoutAmount = TokenPayoutAmount;
      document.getElementById('TokenPayoutAmount').value = _TokenPayoutAmount / -1e18;
    }
    let _actualPrice, _USDCAmountIn;
    let newTokenReserves;
    if (token0 === USDC_Address) {
      newTokenReserves = parseInt(reserves1) - _TokenPayoutAmount;
      let newUSDCPoolBalance = Number(kFactor) / newTokenReserves;
      _USDCAmountIn = (newUSDCPoolBalance - parseInt(reserves0)) / 0.997;
      _actualPrice = (_USDCAmountIn / (10 ** USDDecimals)) / (_TokenPayoutAmount / 1e18); // Normalize to human-readable units
      document.getElementById('USDCAmountIn').value = (_USDCAmountIn / (10 ** USDDecimals)).toFixed(14);
    } else if (token1 === USDC_Address) {
      newTokenReserves = parseInt(reserves0) - _TokenPayoutAmount;
      let newUSDCPoolBalance = Number(kFactor) / newTokenReserves;
      _USDCAmountIn = (newUSDCPoolBalance - parseInt(reserves1)) / 0.997;
      _actualPrice = (_USDCAmountIn / (10 ** USDDecimals)) / (_TokenPayoutAmount / 1e18); // Normalize to human-readable units
      document.getElementById('USDCAmountIn').value = (_USDCAmountIn / (10 ** USDDecimals)).toFixed(14);
    }
    if (newTokenReserves < 0) {
      document.getElementById('USDCAmountIn').value = '';
      setBuyButtonVisible(false);
      setWarningButtonVisible(true);
      setWarningButtonMessage("Not enough liquidity");
      return;
    }
    _USDCAmountIn = parseInt(_USDCAmountIn);
    if (_USDCAmountIn > parseFloat(USDCBalance) * (10 ** USDDecimals)) {
      setBuyButtonVisible(false);
      setWarningButtonVisible(true);
      setWarningButtonMessage("Balance too low");
      return;
    }
    setBuyButtonVisible(true);
    setWarningButtonVisible(false);
    let _liquidityProviderFee = _USDCAmountIn * 0.003;
    setTokenPayoutAmount(_TokenPayoutAmount);
    let _slippage = slippage / 10000;
    let _TokenPayoutAmountMin = parseInt(roundDown(_TokenPayoutAmount * (1 - parseFloat(_slippage)), 14));
    setTokenPayoutAmountMin(_TokenPayoutAmountMin);
    setUSDCAmountIn(_USDCAmountIn);
    setLiquidityProviderFee(_liquidityProviderFee);
    setActualPrice(_actualPrice);

    let _priceImpactBuy = ((_actualPrice - parseFloat(tokenPrice)) / parseFloat(tokenPrice)) * 100;
    setPriceImpactBuy(_priceImpactBuy);
    let _path = [USDC_Address, selectedAssetAddress];
    let _deadline = Math.round(+new Date() / 1000) + (60 * 10);
    setPath(_path);
    setDeadline(_deadline);
    await calculateBuy();
    setPriceDataVisible(_priceImpactBuy > 0);
  };

  const calculateTradeResult = async () => {
    if (selectedAsset === 'Select Asset' || !selectedAssetAddress) {
      document.getElementById('assetAmountIn').value = '';
      document.getElementById('USDCPayoutAmount').value = '';
      return;
    }
    checkAllowances(selectedAssetAddress);
    let input = document.getElementById('assetAmountIn').value;
    const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(input);
    let _AssetAmountIn = parseFloat(input) * 1e18;
    if (!isPositiveNumber) {
      if (!input) {
        document.getElementById('USDCPayoutAmount').value = '';
        setPriceDataVisible(false);
        setBuyButtonVisible(false);
        return;
      }
      if (typeof AssetAmountIn === 'undefined') {
        document.getElementById('USDCPayoutAmount').value = '';
        document.getElementById('assetAmountIn').value = '';
        return;
      }
      document.getElementById('assetAmountIn').value = AssetAmountIn / 1e18;
      return;
    }
    if (parseFloat(input) === 0) {
      document.getElementById('USDCPayoutAmount').value = '';
      setBuyButtonVisible(false);
      setPriceDataVisible(false);
      return;
    }
    if (_AssetAmountIn > parseFloat(selectedAssetBalance) * 1e18) {
      setSellButtonVisible(false);
      setWarningButtonVisible(true);
      setWarningButtonMessage("Balance too low");
    } else {
      setSellButtonVisible(true);
      setWarningButtonVisible(false);
    }

    let _USDCPayoutAmount, _liquidityProviderFee, _actualPrice;
    if (token0 === USDC_Address) {
      let newTokenReserves = parseInt(reserves1) + _AssetAmountIn;
      let newUSDCPoolBalance = Number(kFactor) / newTokenReserves;
      _USDCPayoutAmount = (parseInt(reserves0) - newUSDCPoolBalance) * 0.997;
      _liquidityProviderFee = (parseInt(reserves0) - newUSDCPoolBalance) * 0.003;
      _actualPrice = (_USDCPayoutAmount / (10 ** USDDecimals)) / (_AssetAmountIn / 1e18); // Normalize to human-readable units
      if (isNaN(_actualPrice)) {
        document.getElementById('USDCPayoutAmount').value = '';
        return;
      }
      document.getElementById('USDCPayoutAmount').value = (_USDCPayoutAmount / (10 ** USDDecimals)).toFixed(6);
    } else {
      let newTokenReserves = parseInt(reserves0) + _AssetAmountIn;
      let newUSDCPoolBalance = Number(kFactor) / newTokenReserves;
      _USDCPayoutAmount = (parseInt(reserves1) - newUSDCPoolBalance) * 0.997;
      _liquidityProviderFee = (parseInt(reserves1) - newUSDCPoolBalance) * 0.003;
      _actualPrice = (_USDCPayoutAmount / (10 ** USDDecimals)) / (_AssetAmountIn / 1e18); // Normalize to human-readable units
      if (isNaN(_actualPrice)) {
        document.getElementById('USDCPayoutAmount').value = '';
        return;
      }
      document.getElementById('USDCPayoutAmount').value = (_USDCPayoutAmount / (10 ** USDDecimals)).toFixed(2);
    }
    setUSDCPayoutAmount(_USDCPayoutAmount);
    let _slippage = slippage / 10000;
    let _USDCPayoutAmountMin = _USDCPayoutAmount * (1 - parseFloat(_slippage));
    let _amountOutMin = web3_nm.utils.toBigInt(parseInt(_USDCPayoutAmountMin));
    setAmountOutMin(_amountOutMin);
    setActualPrice(_actualPrice);
    setUSDCPayoutAmountMin(_USDCPayoutAmountMin);
    setAssetAmountIn(_AssetAmountIn);
    setLiquidityProviderFee(_liquidityProviderFee);

    let _priceImpact = ((_actualPrice - parseFloat(tokenPrice)) / parseFloat(tokenPrice)) * -100;
    setPriceImpact(_priceImpact);
    await calculateSell();
    setPriceDataVisible(_priceImpact > 0);
  };

  const calculateSellResultUSD = async () => {
    if (selectedAsset === 'Select Asset') {
      document.getElementById('assetAmountIn').value = '';
      document.getElementById('USDCPayoutAmount').value = '';
      return;
    }
    checkAllowances(selectedAssetAddress);
    let input = document.getElementById('USDCPayoutAmount').value;
    const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(input);
    if (!isPositiveNumber) {
      if (!input) {
        document.getElementById('assetAmountIn').value = '';
        setBuyButtonVisible(false);
        setPriceDataVisible(false);
        setUSDCPayoutAmount();
        return;
      }
      if (typeof USDCPayoutAmount === 'undefined') {
        document.getElementById('USDCPayoutAmount').value = '';
        document.getElementById('assetAmountIn').value = '';
        return;
      }
      document.getElementById('USDCPayoutAmount').value = USDCPayoutAmount / (10 ** USDDecimals);
      return;
    }
    if (parseFloat(input) === 0) {
      document.getElementById('assetAmountIn').value = '';
      setBuyButtonVisible(false);
      setPriceDataVisible(false);
      return;
    }
    let _USDCPayoutAmount = parseFloat(input) * (10 ** USDDecimals);
    if (_USDCPayoutAmount < 0) {
      document.getElementById('USDCPayoutAmount').value = USDCPayoutAmount / -(10 ** USDDecimals);
    }

    let _liquidityProviderFee, _actualPrice, _AssetAmountIn;
    let newUSDCPoolBalance;
    if (token0 === USDC_Address) {
      newUSDCPoolBalance = parseInt(reserves0) - _USDCPayoutAmount;
      let newTokenReserves = Number(kFactor) / newUSDCPoolBalance;
      _AssetAmountIn = -(Number(reserves1) - Number(newTokenReserves)) / 0.997;
      document.getElementById('assetAmountIn').value = (_AssetAmountIn / 1e18).toFixed(9);
      _liquidityProviderFee = (parseInt(reserves0) - newUSDCPoolBalance) * 0.003;
      _actualPrice = (_USDCPayoutAmount / (10 ** USDDecimals)) / (_AssetAmountIn / 1e18); // Normalize to human-readable units
    } else {
      newUSDCPoolBalance = parseInt(reserves1) - _USDCPayoutAmount;
      let newTokenReserves = Number(kFactor) / newUSDCPoolBalance;
      _AssetAmountIn = -(Number(reserves0) - Number(newTokenReserves)) / 0.997;
      document.getElementById('assetAmountIn').value = (_AssetAmountIn / 1e18).toFixed(9);
      _liquidityProviderFee = (parseInt(reserves1) - newUSDCPoolBalance) * 0.003;
      _actualPrice = (_USDCPayoutAmount / (10 ** USDDecimals)) / (_AssetAmountIn / 1e18); // Normalize to human-readable units
    }
    if (newUSDCPoolBalance < 0) {
      document.getElementById('assetAmountIn').value = '';
      setBuyButtonVisible(false);
      setWarningButtonVisible(true);
      setWarningButtonMessage("Not enough liquidity");
      return;
    }
    if (_AssetAmountIn > parseFloat(selectedAssetBalance) * 1e18) {
      setSellButtonVisible(false);
      setWarningButtonVisible(true);
      setWarningButtonMessage("Balance too low");
    } else {
      setSellButtonVisible(true);
      setWarningButtonVisible(false);
    }
    setUSDCPayoutAmount(_USDCPayoutAmount);
    let _slippage = slippage / 10000;
    let _USDCPayoutAmountMin = _USDCPayoutAmount * (1 - parseFloat(_slippage));
    let _amountOutMin = web3_nm.utils.toBigInt(parseInt(_USDCPayoutAmountMin));
    setAmountOutMin(_amountOutMin);
    setLiquidityProviderFee(_liquidityProviderFee);
    setActualPrice(_actualPrice);

    let _priceImpact = ((_actualPrice - parseFloat(tokenPrice)) / parseFloat(tokenPrice)) * -100;
    setPriceImpact(_priceImpact);
    await calculateSell();
    setPriceDataVisible(_priceImpact > 0);
  };

  const calculateSell = async () => {
    if (selectedAsset === 'Select Asset') {
      document.getElementById('assetAmountIn').value = '';
      return;
    }
    let amountInRaw = document.getElementById('assetAmountIn').value;
    if (!amountInRaw) amountInRaw = "0";
    else if (isNaN(amountInRaw)) {
      amountInRaw = String(AssetAmountIn / 1e18);
      document.getElementById('assetAmountIn').value = amountInRaw;
    }
    let _amountIn = ethers.utils.parseUnits(amountInRaw, 18);
    if (_amountIn < 0) {
      _amountIn = AssetAmountIn;
      document.getElementById('assetAmountIn').value = _amountIn / 1e18;
      return;
    }
    let _path = [selectedAssetAddress, USDC_Address];
    let _deadline = Math.round(+new Date() / 1000) + (60 * 10);
    setAmountIn(_amountIn);
    setDeadline(_deadline);
    setPath(_path);
  };

  const onSuccessSell = async () => {
    let selectedAssetOld = selectedAsset;
    let selectedAssetAddressOld = selectedAssetAddress;
    let selectedAssetBalanceOld = selectedAssetBalance;
    let newBalance = parseFloat(selectedAssetBalanceOld) - parseFloat(AssetAmountIn / 1e18);
    document.getElementById('assetAmountIn').value = 0;
    document.getElementById('USDCPayoutAmount').value = 0;
    setSelectedAssetBalance(newBalance);
    setPriceDataVisible(false);
    setSelectedAsset("Select Asset");
    setSelectedAssetBalance(0);

    await updateUserData();
    if (selectedAssetOld === 'TWIN') await updateISSPrice();
    await loadUSDBalance();
    await updateAssetBalanceWithAddress(selectedAssetAddressOld);
    await updatePriceWithAddress(selectedAssetAddressOld);
    await loadLPBalances();
    await updatePortfolioValue();
  };

  const calculateBuy = async () => {
    let _path = [USDC_Address, selectedAssetAddress];
    let _deadline = Math.round(+new Date() / 1000) + (60 * 10);
    setPath(_path);
    setDeadline(_deadline);
  };

  const onSuccessBuy = async () => {
    let selectedAssetOld = selectedAsset;
    let selectedAssetAddressOld = selectedAssetAddress;
    let selectedAssetBalanceOld = selectedAssetBalance;
    setPriceDataVisible(false);
    setSelectedAsset("Select Asset");
    setSelectedAssetAddress('');
    document.getElementById('USDCAmountIn').value = 0;
    document.getElementById('TokenPayoutAmount').value = 0;

    let newBalance = parseFloat(TokenPayoutAmount / 1e18) + parseFloat(selectedAssetBalanceOld);
    setSelectedAssetBalance(newBalance);
    await loadUSDBalance();
    await updateAssetBalanceWithAddress(selectedAssetAddressOld);
    await updatePriceWithAddress(selectedAssetAddressOld);
    await loadLPBalances();
    await updatePortfolioValue();
  };

  const checkApproval = async (tokenAddress, approvalAddress) => {
    let tokenContract = new web3_nm.eth.Contract(ERC20_ABI, tokenAddress);
    let amount = web3_nm.utils.toBigInt(100000000000000000000000000000);
    let allowance = await tokenContract.methods.allowance(address, approvalAddress).call();
    return parseInt(allowance) >= parseInt(amount);
  };

  const onSuccessApprove = async () => {
    checkAllowances(selectedAssetAddress);
  };

  const showSell = async () => {
    setStyle1("selectButtonUnselected");
    setStyle2("selectButtonSelected");
    setBuyPartVisible(false);
    setSellPartVisible(true);
    setWarningButtonVisible(false);
    await sleep(5);
    document.getElementById('assetAmountIn').value = '';
    calculateTradeResult();
  };

  const showBuy = async () => {
    setStyle1("selectButtonSelected");
    setStyle2("selectButtonUnselected");
    setBuyPartVisible(true);
    setSellPartVisible(false);
    setWarningButtonVisible(false);
    await sleep(5);
    setBuyButtonVisible(true);
    document.getElementById('USDCAmountIn').value = '';
    calculateBuyResult();
  };

  const setMaxSellBalanceToken = async () => {
    document.getElementById('assetAmountIn').value = roundDown(selectedAssetBalance, 14).toFixed(14).replace(/\.?0+$/, "");
    calculateTradeResult();
  };

  const setPercentOfAssetSell = async (_percentage) => {
    document.getElementById('assetAmountIn').value = roundDown(selectedAssetBalance * _percentage / 100, 14).toFixed(14).replace(/\.?0+$/, "");
    calculateTradeResult();
  };

  const setMaxBuyBalanceUSD = async () => {
    document.getElementById('USDCAmountIn').value = roundDown(USDCBalance, 6).toFixed(6).replace(/\.?0+$/, "");
    calculateBuyResult();
  };

  const setPercentOfUSDC = async (_percentage) => {
    document.getElementById('USDCAmountIn').value = roundDown(USDCBalance * _percentage / 100, 6).toFixed(6).replace(/\.?0+$/, "");
    calculateBuyResult();
  };

  const setPercentOfAssetBuy = async (_percentage) => {
    document.getElementById('TokenPayoutAmount').value = roundDown(selectedAssetBalance * _percentage / 100, 14).toFixed(14).replace(/\.?0+$/, "");
    calculateBuyResultToken();
  };

  return {
    USDCBalance,
    USDC_Address,
    MarketRouter_Address,
    outputNumber,
    USDDecimals,
    MarketRouter_ABI,
    ERC20_ABI,
    stableCoinName,
    slippage,
    trxTime,
    assets,
    filteredAssets,
    selectedAsset,
    selectedAssetAddress,
    selectedAssetBalance,
    chooseAssetModalOpen,
    priceDataVisible,
    approvalButtonVisible,
    sellPartVisible,
    buyPartVisible,
    style1,
    style2,
    priceImpactBuy,
    sellButtonVisible,
    settingsModalOpen,
    buyButtonVisible,
    warningButtonVisible,
    warningButtonMessage,
    USDApprovalButtonVisible,
    assetBalances,
    assetAddresses,
    wrongSlippageInputMessage,
    highSlippageInputMessage,
    wrongTrxTimeInputMessage,
    allowanceToken,
    allowanceUSDC,
    token0,
    token1,
    reserves0,
    reserves1,
    kFactor,
    AssetAmountIn,
    TokenPayoutAmount,
    tokenPrice,
    actualPrice,
    amountIn,
    amountOutMin,
    path,
    deadline,
    USDCPayoutAmount,
    USDCPayoutAmountMin,
    priceImpact,
    liquidityProviderFee,
    USDCAmountIn,
    TokenPayoutAmountMin,
    address,
    openSettingsModal,
    closeSettingsModal,
    saveSettingsSlippage,
    saveSettingsTrxTime,
    openChooseAssetModal,
    closeChooseAssetModal,
    openChooseAssetModalSell,
    filterAssets,
    listAssets,
    selectAsset,
    calculateBuyResult,
    calculateBuyResultToken,
    calculateTradeResult,
    calculateSellResultUSD,
    calculateSell,
    onSuccessSell,
    calculateBuy,
    onSuccessBuy,
    onSuccessApprove,
    showSell,
    showBuy,
    setMaxSellBalanceToken,
    setPercentOfAssetSell,
    setMaxBuyBalanceUSD,
    setPercentOfUSDC,
    setPercentOfAssetBuy,
  };
};

export default useMarketLogic;