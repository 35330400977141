import React, { Component } from 'react';
import '../../styles/tou.css';
class TOU extends Component {
    render() { 
        
        return ( 
            <div className="mainContainer">
                <div className="middleBoxFull">
                    <div className='contentContainer'>
                        <div className="mainBox tou">
                            <h1>TERMS AND CONDITIONS</h1>
                            <p>Last Updated: February 20, 2025</p>

                            <p>These Terms of Use, together with any documents and additional terms they expressly incorporate (collectively, these "Terms"), are entered into between Twin DAO ("Twin", "Twin Finance", "we", "our" or "us") and the person making use of Twin's services, including, but not limited to, the use of this website and smart contracts (hereinafter referred to as the "User", "you" or "your"). These Terms constitute a binding legal agreement between Twin and the User following such User's acceptance of such Terms.</p>

                            <p>Within these Terms, words of any gender shall be held and construed to include any other gender, and words in the singular number shall be held and construed to include the plural unless the context otherwise requires.</p>

                            <p>These Terms govern your access to and use of Twin's Protocol and Services. You must read the Terms carefully. By accessing, browsing or otherwise using Twin's Protocol or Services, or by acknowledging agreement to the Terms, you agree that you have read, understood and accepted all of the Terms and our Privacy Policy (the "Privacy Policy"), which is incorporated by reference into the Terms.</p>

                            <p>By accessing or using Twin's Protocol or Services, the User accepts and agrees to be bound by and comply with these Terms, including the mandatory arbitration provision in Section 18. If the User does not agree to these Terms, such User should not access or use Twin's Protocol or Services.</p>

                            <p>The User must be able to form a legally binding contract online either as an individual or on behalf of a company, as outlined in Section 2.</p>

                            <p>Twin appeals to the User to carefully review the disclosures and disclaimers set forth in Section 14 in their entirety before using any software developed by Twin. Section 14 provides important details about the legal obligations associated with the User's use of the Twin open-source software. By accessing or using Twin's Protocol or Services, the User agrees that Twin does not provide execution or clearing services of any kind and is not responsible for executing or clearing transactions automated through the Twin open-source software.</p>

                            <h2>KEY DEFINITIONS</h2>
                            <p>For the purpose of these Terms, the following capitalized terms shall have the following meanings:</p>
                            <ul>
                                <li><strong>"Affiliate"</strong> means, with respect to a party to these Terms, any legal entity that, directly or indirectly controls, is controlled by, or is under common control with Twin.</li>
                                <li><strong>"Applicable Law"</strong> means any domestic or foreign law, rule, statute, regulation, by-law, order, protocol, code, decree, or other directive, requirement or guideline, published or in force which applies to or is otherwise intended to govern or regulate any person, property, transaction, activity, event or other matter, including any rule, order, judgment, directive or other requirement or guideline issued by any Governmental Authority having jurisdiction over Twin, the User, the Protocol or the Services, or as otherwise duly enacted, enforceable by law, the common law or equity.</li>
                                <li><strong>"TWIN"</strong> means the utility and governance token of the Twin Finance Protocol that may be used to participate in protocol governance when locked as veTWIN.</li>
                                <li><strong>"veTWIN"</strong> means the Voting Escrow TWIN tokens received by users who lock their TWIN tokens for governance participation.</li>
                                <li><strong>"TWIN Assets"</strong> means the synthetic derivative assets created through the Protocol that track the value of underlying real-world or crypto assets.</li>
                                <li><strong>"USDC"</strong> means the USD-pegged stablecoin used within the Protocol for minting and trading TWIN Assets.</li>
                                <li><strong>"Protocol"</strong> means the Twin website, smart contracts, and associated services that enable the creation, trading, and management of synthetic derivative assets.</li>
                                <li><strong>"Services"</strong> means the Products offered by Twin as accessible through the Protocol.</li>
                                <li><strong>"Product"</strong> means any product offered through the Services and available in or through the Protocol, including but not limited to TWIN Assets, liquidity pools, and governance mechanisms.</li>
                            </ul>

                            <h2>PROTOCOL OVERVIEW</h2>
                            <p>The Protocol enables:</p>
                            <ul>
                                <li>Minting and burning of synthetic derivative assets;</li>
                                <li>Trading of derivative assets through automated market maker pools;</li>
                                <li>Liquidity provision to trading pools;</li>
                                <li>Participation in protocol governance through veTWIN.</li>
                            </ul>
                            <p>The Protocol operates through smart contracts deployed on the blockchain and requires no permission or authorization from Twin Finance to use.</p>

                            <h2>ELIGIBILITY</h2>
                            <p>The following are conditions of access to the Portal, and each time you access the Portal, the User represents and warrants to us that:</p>
                            <ul>
                                <li>The User is an individual that is 18 years of age or older, capable of forming a binding contract with us, and under no legal impediment or incapability;</li>
                                <li>If the User is acting on behalf of or through a company; the User is an authorised representative of the company and has the authority from that company to access the Portal and form a binding agreement with us on behalf of that company;</li>
                                <li>The User is not identified as a "Specially Designated National" by the US Office of Foreign Assets Control or otherwise subject to any sanctions or restrictions which may affect our ability to provide the User with our Services;</li>
                                <li>The User has the full power and authority to agree to these Terms and to use any Service offered by Twin through the Portal;</li>
                                <li>The User has read these Terms prior to using any Service, and that you are solely responsible for your trading or non-trading actions and have had the opportunity to take any legal, financial, accounting or other advice that you deem appropriate prior to accessing the Portal or using any of the Services;</li>
                                <li>The User will only utilise legally obtained digital assets that belong to the User, and that the User has full legal and beneficial title to any such assets at the time the User utilises them;</li>
                                <li>The User is not located in, or a resident of, any Restricted Territory (as defined below) and has not used any technical means (including a VPN) to misrepresent its geographical location to access the Portal from any Restricted Territory; and</li>
                                <li>The User is acting for its own account as principal and not as trustee, agent or otherwise on behalf of any other persons.</li>
                            </ul>
                            <p>Your access to the Portal and any Services may be restricted based on your jurisdiction or geographical location. You must not use the Product or the Portal if you are located in or a citizen or resident of any state, country, territory, or other jurisdiction in which use of the Portal or the Services would be illegal or otherwise violate any applicable law (a “Restricted Territory”).</p>
                            <p>The fact that the Portal is accessible in a Restricted Territory or that the Portal allows the use of the official language of a Restricted Territory, or a language commonly used in a Restricted Territory must not be construed as a license to use the Portal in such a Restricted Territory.</p>
                            <p>We unconditionally reserve the right to restrict access to any Restricted Territory and may implement technical controls to prevent access to the Portal or any Services from any Restricted Territory. No services are offered to persons or entities who reside in, are citizens of, are located in, are incorporated in, or have a registered office in any Restricted Territory, which include, without limitation the following: (a) Belarus, Cuba, Democratic People’s Republic of Korea (DPRK), Democratic Republic of Congo, Iran, Lebanon, Libya, Mali, Myanmar, Nicaragua, Russia, Somalia, South Sudan, Sudan, Syria, the following regions of Ukraine: Crimea, Donetsk and Luhansk, or any other country or region that is the subject of comprehensive country-wide or region-wide or economic sanctions by the Financial Action Task Force, United Nations, United States of America, the European Union or the United Kingdom; and (b) United States of America, the United Kingdom, Canada, and the People’s Republic of China.</p>
                            <p>If you are a resident or citizen of or otherwise are located or incorporated in, or have a registered office in any Restricted Territory, do not use Twin’s Portal or Services or attempt to use a virtual private network (VPN) or other technology to circumvent the restrictions set forth herein. Use of a VPN to access Twin’s Portal or Services is expressly prohibited.</p>
                            <p>You acknowledge and agree that you will not use a VPN or similar technology to access or use Twin’s Portal or Services in any way. We reserve the right to restrict or discontinue your access to and use of the Portal and Services if we know or have reason to suspect you are using a VPN for such access or use.</p>

                            <h2>MODIFICATIONS TO THESE TERMS</h2>
                            <p>In our sole discretion, we reserve the right to modify these Terms from time to time. If modifications are made, Twin will provide the User with notice of such changes by providing a notice through our Services or updating the date at the top of these Terms.</p>
                            <p>Unless we say otherwise in our notice, all such modifications are effective immediately, and your continued use of our Services after we provide such notice will be construed as a consensual confirmation of the notified changes.</p>
                            <p>Where the User does not agree with the modified Terms, such User is to cease from using Twin’s Services at once.</p>

                            <h2>ELIGIBLE USERS</h2>
                            <p>The following are conditions of access to the Protocol, and each time you access the Protocol, the User represents and warrants to us that:</p>
                            <ul>
                                <li>The User is an individual that is 18 years of age or older, capable of forming a binding contract with us, and under no legal impediment or incapability;</li>
                                <li>If the User is acting on behalf of a company, the User is an authorized representative of the company and has the authority to access the Protocol and form a binding agreement with us on behalf of that company;</li>
                                <li>The User is not identified as a "Specially Designated National" by the US Office of Foreign Assets Control or otherwise subject to any sanctions or restrictions;</li>
                                <li>The User has the full power and authority to agree to these Terms and to use any Service offered by Twin through the Protocol;</li>
                                <li>The User has read these Terms prior to using any Service and understands that they are solely responsible for their trading or non-trading actions;</li>
                                <li>The User will only utilize legally obtained digital assets that belong to the User, and the User has full legal and beneficial title to any such assets;</li>
                                <li>The User is not located in, or a resident of, any Restricted Territory (as defined below);</li>
                                <li>The User is acting for their own account as principal and not as trustee, agent, or otherwise on behalf of any other persons.</li>
                            </ul>

                            <h2>SERVICES</h2>
                            <p><strong>Protocol Overview</strong> Twin Finance is a decentralized, permissionless protocol that enables the creation and trading of synthetic derivative assets. The Protocol operates through smart contracts deployed on the blockchain and requires no authorization from Twin Finance for use. The User acknowledges that Twin Finance does not custody, control, or have access to User funds at any time.</p>
                            <p><strong>Minting and Burning Services</strong> The Protocol enables Users to mint and burn TWIN Assets through the assetFactory smart contract under the following conditions:</p>
                            <ul>
                                <li><strong>Minting Process:</strong>
                                    <ul>
                                        <li>Users may mint TWIN Assets by depositing USDC stablecoins</li>
                                        <li>Users receive equal amounts of long and short tokens</li>
                                        <li>The quantity of tokens received is determined by dividing the USDC amount by the asset's predefined upper limit</li>
                                        <li>No over-collateralization is required for minting</li>
                                    </ul>
                                </li>
                                <li><strong>Burning Process:</strong>
                                    <ul>
                                        <li>Users may burn TWIN Assets to redeem USDC at any time</li>
                                        <li>A 2% early redemption fee applies when burning equal amounts of long and short tokens during the asset's lifetime</li>
                                        <li>For frozen assets, only long tokens may be redeemed at the upper limit price</li>
                                        <li>At expiry, redemption values are calculated based on the asset's final price determination</li>
                                    </ul>
                                </li>
                            </ul>
                            <p><strong>Trading Services</strong> The Protocol provides automated market maker (AMM) functionality for trading TWIN Assets:</p>
                            <ul>
                                <li><strong>Market Structure:</strong>
                                    <ul>
                                        <li>Trading occurs through liquidity pools of USDC and TWIN Assets</li>
                                        <li>Each underlying asset has separate pools for long and short tokens</li>
                                        <li>Prices are determined by the constant product formula</li>
                                        <li>A 0.25% liquidity provider fee and 0.05% protocol fee apply to all trades</li>
                                    </ul>
                                </li>
                                <li><strong>Liquidity Provision:</strong>
                                    <ul>
                                        <li>Users may provide liquidity to trading pools</li>
                                        <li>Liquidity providers receive LP tokens representing their share of the pool</li>
                                        <li>LP tokens entitle holders to a proportional share of trading fees</li>
                                        <li>Liquidity can be withdrawn by burning LP tokens</li>
                                    </ul>
                                </li>
                            </ul>
                            <p><strong>Governance Services</strong> The Protocol enables participation in decentralized governance through the following mechanisms:</p>
                            <ul>
                                <li><strong>Voting Rights:</strong>
                                    <ul>
                                        <li>Users may lock TWIN tokens to receive veTWIN</li>
                                        <li>veTWIN holders may participate in governance votes</li>
                                        <li>One veTWIN equals one vote in governance matters</li>
                                    </ul>
                                </li>
                                <li><strong>Governance Procedures:</strong>
                                    <ul>
                                        <li>Asset freeze votes require majority approval</li>
                                        <li>Asset expiry price votes determine final settlement values</li>
                                        <li>New asset proposals require minimum 100,000 veTWIN to initiate</li>
                                        <li>Grant proposals and protocol upgrades are subject to community voting</li>
                                    </ul>
                                </li>
                            </ul>
                            <p><strong>Gas Fees and Transaction Costs</strong> Users are responsible for all blockchain transaction fees required to interact with the Protocol. Twin Finance has no control over and does not receive any portion of these network fees.</p>
                            <p><strong>Protocol Restrictions</strong> Twin Finance reserves the right to restrict access to the Protocol interface in certain jurisdictions. However, as an open-source protocol, the underlying smart contracts remain accessible and permissionless.</p>
                            <p><strong>Conditions and Restrictions</strong> Twin Finance may, at any time and in its sole discretion, restrict access to the Protocol interface or impose conditions upon its use, with or without prior notice. The Protocol smart contracts, being immutable and decentralized, will continue to function regardless of interface availability.</p>
                            <p><strong>No Broker, Legal or Fiduciary Relationship</strong> Twin Finance is not your broker, lawyer, intermediary, agent, or advisor and has no fiduciary relationship or obligation to you regarding any decisions or activities that you effect when using the Protocol. No communication or information provided by Twin Finance is intended as, or should be construed as, advice.</p>

                            <h2>PROTOCOL RISKS</h2>
                            <p><strong>Smart Contract Risk</strong></p>
                            <ul>
                                <li>The Protocol smart contracts may contain bugs or vulnerabilities</li>
                                <li>Smart contracts are immutable once deployed</li>
                                <li>You acknowledge and accept all risks associated with smart contract usage</li>
                            </ul>
                            <p><strong>Financial Risk</strong></p>
                            <ul>
                                <li>Derivative assets may lose value or become worthless</li>
                                <li>Asset prices are volatile and subject to market conditions</li>
                                <li>Upper limits may be reached, causing asset freezing</li>
                                <li>No guarantee of profit or return on investment</li>
                            </ul>
                            <p><strong>Governance Risk</strong></p>
                            <ul>
                                <li>Protocol parameters and features may change through governance</li>
                                <li>Asset valuations depend on decentralized governance votes</li>
                                <li>Governance decisions are binding and may affect your positions</li>
                            </ul>

                            <h2>PROTOCOL MECHANICS</h2>
                            <p><strong>ASSET CREATION AND MANAGEMENT</strong></p>
                            <p><strong>Minting Process</strong> The Protocol enables the creation of synthetic derivative assets through the assetFactory smart contract. When minting TWIN Assets, Users must deposit USDC stablecoins as collateral. The Protocol automatically issues an equal quantity of long and short tokens, calculated by dividing the deposited USDC amount by the asset's predefined upper limit. Users acknowledge that this process is automated and irreversible once executed.</p>
                            <p><strong>Asset Parameters</strong> Each TWIN Asset is created with specific parameters including an upper limit and expiration date. The upper limit serves as a maximum price threshold and determines the initial token distribution ratio. The expiration date, set to one year from creation unless otherwise specified through governance, defines the asset's active lifespan. Users acknowledge that these parameters are immutable once set and govern all subsequent interactions with the asset.</p>
                            <p><strong>Asset Valuation</strong> Long tokens track the price of the underlying asset, while short tokens are valued at the difference between the upper limit and the underlying asset's price. Users acknowledge that the combined value of one long token and one short token should equal the upper limit while the asset remains unfrozen.</p>
                            <p><strong>ASSET REDEMPTION AND BURNING</strong></p>
                            <p><strong>Standard Redemption</strong> Users may burn TWIN Assets through the assetFactory smart contract to redeem USDC. This process requires submitting an equal quantity of long and short tokens during the asset's active period. A mandatory 2% early redemption fee applies to all such transactions.</p>
                            <p><strong>Frozen Asset Redemption</strong> If an asset's price exceeds its upper limit, the asset enters a frozen state. In this condition, only long tokens may be redeemed, with redemption value fixed at the upper limit price. Short tokens become valueless but must still be burned. Users acknowledge that freezing is irreversible and automatically triggered by governance confirmation.</p>
                            <p><strong>Expiry Redemption</strong> Upon asset expiration, Users may redeem their tokens through the burnExpiredAssets function. The redemption value is calculated based on the governance-determined expiry price, with long tokens valued at the final price and short tokens at the difference between the upper limit and final price.</p>
                            <p><strong>TRADING MECHANISM</strong></p>
                            <p><strong>Market Structure</strong> The Protocol implements an automated market maker system with distinct liquidity pools for each asset's long and short tokens. Each pool pairs the respective token with USDC stablecoins. Users acknowledge that all trades execute automatically based on the constant product formula without traditional order books or counterparties.</p>
                            <p><strong>Price Determination</strong> Asset prices are determined by the ratio of USDC to asset tokens in each pool, following the constant product formula: x * y = k, where x and y represent the quantities of each asset in the pool. Users acknowledge that large trades may result in significant price impact and slippage.</p>
                            <p><strong>Trading Fees</strong> Each trade incurs a 0.25% liquidity provider fee and a 0.05% protocol fee. The liquidity provider fee is automatically added to the pool's reserves and distributed proportionally to liquidity providers. The protocol fee supports the TWIN ecosystem through token buybacks or other governance-determined mechanisms.</p>
                            <p><strong>LIQUIDITY PROVISION</strong></p>
                            <p><strong>Deposit Process</strong> Users may provide liquidity by depositing both USDC and TWIN Assets into a pool. The Protocol issues LP tokens representing the User's proportional share of the pool. The quantity of LP tokens minted is determined by the formula: min(Ain/X * LPtotal, Bin/Y * LPtotal), where A and B represent the deposited amounts.</p>
                            <p><strong>Withdrawal Process</strong> Users may withdraw liquidity by burning LP tokens. The Protocol automatically returns a proportional share of both assets in the pool, calculated as: Aout = LPburn/LPtotal * X and Bout = LPburn/LPtotal * Y. Users acknowledge that the returned quantities may differ from their initial deposit due to fees, trades, and price changes.</p>
                            <p><strong>Rewards and Incentives</strong> Liquidity providers earn their proportional share of the 0.25% trading fees. Additional TWIN token rewards may be distributed to liquidity providers through governance decisions. Users acknowledge that reward rates may vary and are not guaranteed.</p>
                            <p><strong>PROTOCOL CONSTRAINTS</strong></p>
                            <p><strong>Transaction Ordering</strong> All protocol operations execute in the order they are confirmed on the blockchain. Users acknowledge that transaction timing and network conditions may affect execution outcomes.</p>
                            <p><strong>Price Limitations</strong> The Protocol enforces the upper limit price threshold for each asset. Users acknowledge that attempting transactions that would exceed these limits will fail.</p>
                            <p><strong>Technical Requirements</strong> Users must maintain sufficient blockchain network tokens to cover transaction fees and must interact with the Protocol through properly formatted transaction calls. Users acknowledge that incorrect transaction formatting may result in failure or unintended outcomes.</p>

                            <h2>CYBERSECURITY RISKS</h2>
                            <p><strong>SMART CONTRACT SECURITY</strong> The Protocol's smart contracts may contain vulnerabilities, bugs, or other issues despite security audits and testing. Users acknowledge that such issues could result in the loss of functionality, assets, or other value. Smart contracts are immutable once deployed, and Twin Finance cannot modify or update them except through governance-approved upgrades to implementation contracts.</p>
                            <p><strong>PRIVATE KEY SECURITY</strong> Users are solely responsible for securing the private keys associated with their blockchain addresses. Loss or compromise of private keys will permanently and irreversibly deny access to a User's assets and positions within the Protocol. Twin Finance cannot restore access to or recover any assets controlled by lost private keys.</p>
                            <p><strong>NETWORK SECURITY</strong> The Protocol operates on Berachain that may be subject to various attacks, including but not limited to:</p>
                            <ul>
                                <li>Network congestion or denial of service conditions</li>
                                <li>Consensus failures or chain reorganizations</li>
                                <li>Block producer collusion or malicious behavior</li>
                                <li>Network forks or other technical changes</li>
                            </ul>
                            <p>Users acknowledge that such events may affect transaction execution, asset prices, or Protocol functionality.</p>
                            <p><strong>ORACLE SECURITY</strong> The Protocol may rely on external data sources and oracle systems for asset price information and other critical data. These systems may experience technical failures, manipulation, or other issues that could affect Protocol operations. Users acknowledge that oracle malfunctions could result in incorrect asset valuations or Protocol actions.</p>
                            <p><strong>INTERFACE SECURITY</strong> While Twin Finance implements security measures in its user interface, Users acknowledge that:</p>
                            <ul>
                                <li>No internet-based system is entirely secure</li>
                                <li>Communications may be intercepted or modified</li>
                                <li>Malicious actors may attempt to trick Users into approving harmful transactions</li>
                                <li>Frontend interfaces may experience technical issues or downtime</li>
                            </ul>
                            <p><strong>TRANSACTION SECURITY</strong> Users acknowledge that blockchain transactions are:</p>
                            <ul>
                                <li>Irreversible once confirmed</li>
                                <li>Public and visible to all network participants</li>
                                <li>Subject to potential front-running or manipulation</li>
                                <li>Dependent on network conditions for confirmation time and fees</li>
                            </ul>

                            <h2>DIGITAL ASSET RISKS</h2>
                            <p><strong>VALUE VOLATILITY</strong> Digital assets, including TWIN Assets, TWIN tokens, and LP tokens, may experience significant price volatility. Users acknowledge that asset values can change rapidly and substantially, potentially resulting in partial or total loss of value. Past performance does not indicate future results.</p>
                            <p><strong>SYNTHETIC ASSET RISKS</strong> TWIN Assets are synthetic derivatives that track underlying asset prices. Users acknowledge specific risks including:</p>
                            <ul>
                                <li>Potential deviation from underlying asset prices</li>
                                <li>Dependence on price feed accuracy and timeliness</li>
                                <li>Upper limit restrictions and freezing conditions</li>
                                <li>Expiration and settlement uncertainties</li>
                                <li>Early redemption fees and trading costs</li>
                            </ul>
                            <p><strong>LIQUIDITY RISKS</strong> Users acknowledge that digital asset markets may experience:</p>
                            <ul>
                                <li>Insufficient trading volume or liquidity</li>
                                <li>Wide bid-ask spreads</li>
                                <li>Significant price impact from large trades</li>
                                <li>Temporary or permanent loss of liquidity</li>
                                <li>Inability to exit positions at desired prices</li>
                            </ul>
                            <p><strong>TECHNICAL RISKS</strong> Digital assets are subject to technical risks including:</p>
                            <ul>
                                <li>Smart contract bugs or vulnerabilities</li>
                                <li>Blockchain network failures or congestion</li>
                                <li>Oracle malfunctions or manipulations</li>
                                <li>Protocol parameter changes through governance</li>
                                <li>Integration failures with external systems</li>
                            </ul>
                            <p><strong>REGULATORY RISKS</strong> The regulatory status of digital assets is evolving and uncertain. Users acknowledge that regulatory changes may:</p>
                            <ul>
                                <li>Affect the legality or permissibility of certain assets</li>
                                <li>Impact the ability to trade or transfer assets</li>
                                <li>Require identity verification or reporting</li>
                                <li>Result in Protocol modifications or restrictions</li>
                                <li>Lead to the termination of certain Protocol features</li>
                            </ul>
                            <p><strong>MARKET OPERATION RISKS</strong> Users acknowledge that digital asset markets may be subject to:</p>
                            <ul>
                                <li>Market manipulation or abuse</li>
                                <li>Insider trading or information asymmetry</li>
                                <li>Coordinated trading behavior</li>
                                <li>Technical trading issues or errors</li>
                                <li>Sudden changes in market conditions</li>
                            </ul>
                            <p><strong>ASSET-SPECIFIC RISKS</strong> Each TWIN Asset carries unique risks related to its:</p>
                            <ul>
                                <li>Underlying asset or market</li>
                                <li>Price feed mechanism</li>
                                <li>Upper limit and expiration parameters</li>
                                <li>Trading pool composition and depth</li>
                                <li>Governance decisions and modifications</li>
                            </ul>
                            <p><strong>SYSTEMIC RISKS</strong> The interconnected nature of digital asset protocols creates systemic risks:</p>
                            <ul>
                                <li>Cascading failures across protocols</li>
                                <li>Contagion from external market events</li>
                                <li>Dependencies on third-party services</li>
                                <li>Network-wide technical issues</li>
                                <li>Market-wide liquidity crises</li>
                            </ul>
                            <p>Users acknowledge that the risks described in Sections 5 and 6 are not exhaustive, and additional risks may exist or emerge. Users should carefully evaluate their financial situation and risk tolerance before using the Protocol.</p>

                            <h2>LIMITATION OF LIABILITY</h2>
                            <p><strong>MAXIMUM LIABILITY LIMIT</strong> To the maximum extent permitted by applicable law, in no event shall Twin Finance, its affiliates, or any of their respective officers, directors, agents, employees, or representatives (collectively, the "Indemnified Parties") be liable for any indirect, punitive, incidental, special, consequential, or exemplary damages, including without limitation:</p>
                            <ul>
                                <li>Loss of profits, revenue, data, use, goodwill, or other intangible losses</li>
                                <li>Damages relating to any loss of access to or use of the Protocol</li>
                                <li>Damages relating to any conduct or content of any third party using the Protocol</li>
                                <li>Damages relating to any communication failures or unauthorized access to user accounts</li>
                                <li>Damages relating to any bugs, viruses, or other malicious code that may be transmitted through the Protocol</li>
                                <li>Damages relating to any modification, suspension, or discontinuation of the Protocol</li>
                                <li>Damages relating to the accuracy, reliability, or completeness of any Protocol data or information</li>
                            </ul>
                            <p>This limitation applies regardless of the legal theory of liability, whether based on contract, tort, negligence, strict liability, or any other basis, and even if the Indemnified Parties have been advised of the possibility of such damages.</p>
                            <p><strong>PROTOCOL-SPECIFIC LIMITATIONS</strong> The Indemnified Parties shall not be liable for any damages or losses resulting from:</p>
                            <ul>
                                <li>Smart contract vulnerabilities, bugs, or technical failures</li>
                                <li>Oracle malfunctions or price feed inaccuracies</li>
                                <li>Governance decisions or protocol parameter changes</li>
                                <li>Market conditions or trading activity</li>
                                <li>Regulatory changes or compliance requirements</li>
                                <li>Network congestion or transaction failures</li>
                                <li>Loss or compromise of private keys</li>
                                <li>Actions of other Protocol users or market participants</li>
                            </ul>
                            <p><strong>AGGREGATE LIABILITY CAP</strong> In jurisdictions where the exclusion or limitation of liability for consequential or incidental damages is not permitted, the Indemnified Parties' liability shall be limited to the maximum extent permitted by law, and under no circumstances shall exceed the amount paid by you to Twin Finance in the past twelve months, if any.</p>
                            <p><strong>ESSENTIAL PURPOSE</strong> You acknowledge that this limitation of liability is an essential element of the agreement between you and Twin Finance and that the Protocol would not be provided without such limitations.</p>

                            <h2>GOVERNING LAW</h2>
                            <p>These Terms shall be governed by and construed in accordance with the laws of the Republic of Panama, without regard to its conflict of law provisions.</p>

                            <h2>INITIAL DISPUTE RESOLUTION</h2>
                            <p>Prior to initiating any formal dispute resolution process, Users agree to first contact Twin Finance directly to seek an informal resolution. Users must send a written notice describing the dispute to info@twinfinance.io and allow thirty (30) days for Twin Finance to respond before proceeding with further dispute resolution measures.</p>

                            <h2>BINDING ARBITRATION</h2>
                            <p>If a dispute cannot be resolved informally, all disputes arising out of or relating to these Terms or the use of the Protocol shall be finally settled under the Rules of Arbitration of the International Chamber of Commerce by one or more arbitrators appointed in accordance with said Rules. The arbitration proceedings shall be conducted in English.</p>

                            <h2>ARBITRATION PROCEDURES</h2>
                            <p>The arbitration shall be conducted:</p>
                            <ul>
                                <li>By a single arbitrator, unless the amount in controversy exceeds $1,000,000</li>
                                <li>In English language</li>
                                <li>In Panama City, Panama, unless otherwise agreed by both parties</li>
                                <li>In accordance with the ICC Rules of Arbitration</li>
                                <li>On a confidential basis</li>
                            </ul>

                            <h2>CLASS ACTION WAIVER</h2>
                            <p>To the maximum extent permitted by applicable law, you hereby waive any right to participate in class action lawsuits or class-wide arbitration of any claims against Twin Finance.</p>

                            <h2>INDIVIDUAL CAPACITY</h2>
                            <p>Any proceedings to resolve or litigate any dispute in any forum will be conducted solely on an individual basis. Neither you nor Twin Finance will seek to have any dispute heard as a class action or in any other proceeding in which either party acts or proposes to act in a representative capacity.</p>

                            <h2>INJUNCTIVE RELIEF</h2>
                            <p>Notwithstanding the foregoing, Twin Finance may seek injunctive relief or other urgent legal remedies in any jurisdiction to enforce intellectual property rights or prevent imminent harm to the Protocol or its users.</p>

                            <h2>TIME LIMITATION</h2>
                            <p>Any claim or dispute under these Terms must be filed within one (1) year after such claim or dispute arose, or the claim shall be forever barred.</p>

                            <h2>COSTS OF ARBITRATION</h2>
                            <p>The costs of arbitration, including administrative and arbitrator fees, shall be shared equally by the parties, unless the arbitration award provides otherwise. Each party shall bear the cost of its own legal fees unless the arbitrator determines that one party shall bear the legal fees of both parties.</p>

                            <h2>SEVERABILITY</h2>
                            <p>If any portion of this dispute resolution section is found to be unenforceable or unlawful, such portion shall be severed from these Terms, and the remainder of this section shall continue to be fully valid and enforceable.</p>

                            <h2>ELECTRONIC NOTICES</h2>
                            <p>The User consents to receive all communications, agreements, documents, receipts, notices, and disclosures electronically (collectively, our "Communications") that Twin provides in connection with these Terms or any Services. The User agrees that Twin may provide Communications to him by posting them on the Portal or by emailing them to the User at the email address provided in connection with the User's use of the Services if any. The User should maintain copies of Twin’s Communications by printing a paper copy or saving an electronic copy.</p>

                            <h2>CONTACT US</h2>
                            <p>Please send your feedback, comments, or requests for technical support to <a href="mailto:info@twinfinance.io">info@twinfinance.io</a></p>    
                            

                        </div>
                         
                    </div>
                </div>
                
            </div>
        );
    }
}
 
export default TOU;
