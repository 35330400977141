import '../../../styles/market.css';
import React, { useState, useEffect } from 'react';
import { Gear } from 'react-bootstrap-icons';
import { TransactionButton } from '../../../functions/Trx';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import usdcIcon from '../../../img/icons/usd-coin-usdc-logo.png';
import AssetLogo from '../../Common/AssetLogo';
import useMarketLogic from './useMarketLogic';
import AssetSelectorModal from './AssetSelectorModal';
import SettingsModal from './SettingsModal';

const Market = () => {
  const {
    USDCBalance,
    outputNumber,
    USDDecimals,
    stableCoinName,
    slippage,
    trxTime,
    selectedAsset,
    selectedAssetAddress,
    selectedAssetBalance,
    chooseAssetModalOpen,
    priceDataVisible,
    sellPartVisible,
    buyPartVisible,
    style1,
    style2,
    settingsModalOpen,
    warningButtonVisible,
    warningButtonMessage,
    allowanceToken,
    allowanceUSDC,
    USDCAmountIn,
    actualPrice,
    USDCPayoutAmountMin,
    priceImpact,
    liquidityProviderFee,
    amountIn,
    amountOutMin,
    path,
    deadline,
    TokenPayoutAmountMin,
    priceImpactBuy,
    listAssets,
    openSettingsModal,
    closeSettingsModal,
    saveSettingsSlippage,
    saveSettingsTrxTime,
    openChooseAssetModal,
    closeChooseAssetModal,
    openChooseAssetModalSell,
    filterAssets,
    calculateBuyResult,
    calculateBuyResultToken,
    calculateTradeResult,
    calculateSellResultUSD,
    onSuccessSell,
    onSuccessBuy,
    onSuccessApprove,
    showSell,
    showBuy,
    setMaxSellBalanceToken,
    setPercentOfAssetSell,
    setMaxBuyBalanceUSD,
    setPercentOfUSDC,
    MarketRouter_Address,
    MarketRouter_ABI,
    ERC20_ABI,
    selectAsset,
    wrongSlippageInputMessage,
    highSlippageInputMessage,
    wrongTrxTimeInputMessage,
    web3_nm,
    address,
    USDC_Address,
  } = useMarketLogic();

  const [amountError, setAmountError] = useState('');

  const validateAmount = (value, balance, isAssetSelected) => {
    if (!isAssetSelected) {
      setAmountError('Select an asset first');
      return;
    }
    const amount = parseFloat(value);
    if (isNaN(amount) || amount <= 0) {
      setAmountError('Enter a valid amount');
    } else if (amount > balance) {
      setAmountError('Insufficient balance');
    } else {
      setAmountError('');
    }
  };

  useEffect(() => {
    setAmountError('');
  }, [sellPartVisible, buyPartVisible]);

  const handleSetPercentOfAssetSell = (percent) => {
    if (selectedAsset === "Select Asset") {
      setAmountError('Select an asset first');
      return;
    }
    setPercentOfAssetSell(percent);
    const input = document.getElementById('assetAmountIn');
    if (input) {
      const value = input.value;
      validateAmount(value, selectedAssetBalance, true);
    }
  };

  const handleSetMaxSellBalanceToken = () => {
    if (selectedAsset === "Select Asset") {
      setAmountError('Select an asset first');
      return;
    }
    setMaxSellBalanceToken();
    const input = document.getElementById('assetAmountIn');
    if (input) {
      const value = input.value;
      validateAmount(value, selectedAssetBalance, true);
    }
  };

  const handleSetPercentOfUSDC = (percent) => {
    if (selectedAsset === "Select Asset") {
      setAmountError('Select an asset first');
      return;
    }
    setPercentOfUSDC(percent);
    const input = document.getElementById('USDCAmountIn');
    if (input) {
      const value = input.value;
      validateAmount(value, USDCBalance, true);
    }
  };

  const handleSetMaxBuyBalanceUSD = () => {
    if (selectedAsset === "Select Asset") {
      setAmountError('Select an asset first');
      return;
    }
    setMaxBuyBalanceUSD();
    const input = document.getElementById('USDCAmountIn');
    if (input) {
      const value = input.value;
      validateAmount(value, USDCBalance, true);
    }
  };

  return (
    <div className="mainContainer">
      <AssetSelectorModal
        show={chooseAssetModalOpen}
        onHide={closeChooseAssetModal}
        assets={listAssets()}
        onSelectAsset={selectAsset}
        filterAssets={filterAssets}
      />

      <SettingsModal
        show={settingsModalOpen}
        onHide={closeSettingsModal}
        slippage={slippage}
        trxTime={trxTime}
        saveSettingsSlippage={saveSettingsSlippage}
        saveSettingsTrxTime={saveSettingsTrxTime}
        wrongSlippageInputMessage={wrongSlippageInputMessage}
        highSlippageInputMessage={highSlippageInputMessage}
        wrongTrxTimeInputMessage={wrongTrxTimeInputMessage}
      />

      <div className="middleBoxSmall">
        <div className="mainBox">
          <div className="selectButtonRow">
            <div className={style1} onClick={showBuy} role="button">
              <b>Buy</b>
            </div>
            <div className={style2} onClick={showSell} role="button">
              <b>Sell</b>
            </div>
          </div>
          <div className="selectSettingsRow">
            <Gear className="h4" role="button" onClick={openSettingsModal} />
          </div>

          {sellPartVisible && (
            <div>
              <div className="subBox">
                <b>Sell</b>
                <div className="selectAmountRow">
                  {selectedAsset !== "Select Asset" && (
                    <>
                      <div className="percentBox" onClick={() => handleSetPercentOfAssetSell(25)} role="button">25%</div>
                      <div>    </div>
                      <div className="percentBox" onClick={() => handleSetPercentOfAssetSell(50)} role="button">50%</div>
                      <div>    </div>
                      <div className="percentBox" onClick={() => handleSetPercentOfAssetSell(75)} role="button">75%</div>
                      <div>    </div>
                      <div className="percentBox" onClick={handleSetMaxSellBalanceToken} role="button">100%</div>
                    </>
                  )}
                </div>
                <div className="tradingBoxRowWithError">
                  <div className="tradingBoxRow">
                    <input
                      id="assetAmountIn"
                      onChange={(e) => {
                        calculateTradeResult(e);
                        validateAmount(e.target.value, selectedAssetBalance, selectedAsset !== "Select Asset");
                      }}
                      className="inputCustom"
                      type="text"
                      lang="en"
                      placeholder="0.00"
                    />
                    <div className="selectAssetButton" onClick={openChooseAssetModalSell}>
                      <div className="assetBoxIcon">
                        <AssetLogo symbol={selectedAsset} />
                      </div>
                      {selectedAsset} <KeyboardArrowDownOutlinedIcon />
                    </div>
                  </div>
                  {amountError && <div className="errorMessage">{amountError}</div>}
                </div>
                <div onClick={handleSetMaxSellBalanceToken} role="button" className="col align-self-end text-lg-right textBalance">
                  {selectedAsset !== "Select Asset" ? (
                    <span>
                      Balance: {selectedAssetBalance > 0.0001 ? (
                        <span>{outputNumber(selectedAssetBalance, 4)}</span>
                      ) : (
                        <span>&lt; 0.0001</span>
                      )} (Max)
                    </span>
                  ) : ''}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center h-100 py-2">
                <KeyboardDoubleArrowDownOutlinedIcon />
              </div>
              <div className="subBox px-4 py-4">
                <b>Buy</b>
                <div className="tradingBoxRow">
                  <input
                    id="USDCPayoutAmount"
                    onChange={calculateSellResultUSD}
                    className="inputCustom"
                    type="text"
                    lang="en"
                    placeholder="0.00"
                  />
                  <div className="stablecoinAssetBox">
                    <div className="assetBoxIcon">
                      <img src={usdcIcon} alt="usdc" width="24px" />
                    </div>
                    {stableCoinName}
                  </div>
                </div>
                <div className="row">
                  <div className="col textBalance">Balance: {outputNumber(USDCBalance, 2)}</div>
                </div>
              </div>
              {priceDataVisible && (
                <div className="container py-4 pr-4">
                  <div className="row">
                    <div className="col align-self-start">Price:</div>
                    <div className="col align-self-end text-lg-right">
                      {stableCoinName} per {selectedAsset}: {parseFloat(actualPrice).toFixed(3)}
                    </div>
                  </div>
                </div>
              )}
              {selectedAssetBalance > allowanceToken && priceDataVisible && (
                <div className="py-3">
                  <TransactionButton
                    contractAddress={selectedAssetAddress}
                    abi={ERC20_ABI}
                    functionName="approve"
                    args={[MarketRouter_Address, web3_nm.utils.toBigInt(2 ** 255)]}
                    text="Approve"
                    onSuccess={onSuccessApprove}
                  />
                </div>
              )}
              {selectedAssetBalance < allowanceToken && priceDataVisible && !warningButtonVisible && amountIn && amountOutMin && (
                <div className="py-3">
                  <TransactionButton
                    abi={MarketRouter_ABI}
                    contractAddress={MarketRouter_Address}
                    functionName="swapExactTokensForTokens"
                    args={[parseInt(amountIn), parseInt(amountOutMin), path, address, deadline]}
                    text="Trade"
                    onSuccess={onSuccessSell}
                  />
                </div>
              )}
              {warningButtonVisible && (
                <div className="py-3">
                  <div className="issuaaButtonDeactivated">{warningButtonMessage}</div>
                </div>
              )}
              {priceDataVisible && (
                <div className="subBox px-4 py-4">
                  <div>Minimum to receive: {outputNumber(parseFloat(USDCPayoutAmountMin / (10 ** USDDecimals)), 2)}</div>
                  <div className={`priceImpact ${priceImpact > 5 ? 'text-danger' : 'text-success'}`}>
                    Price impact: {outputNumber(priceImpact, 2)}%
                  </div>
                  <div>Liquidity Provide Fee: {outputNumber(parseFloat(liquidityProviderFee / (10 ** USDDecimals)), 2)} {stableCoinName}</div>
                </div>
              )}
            </div>
          )}

          {buyPartVisible && (
            <div>
              <div className="subBox">
                <b>Sell</b>
                <div className="selectAmountRow">
                  <div className="percentBox" onClick={() => handleSetPercentOfUSDC(25)} role="button">25%</div>
                  <div>    </div>
                  <div className="percentBox" onClick={() => handleSetPercentOfUSDC(50)} role="button">50%</div>
                  <div>    </div>
                  <div className="percentBox" onClick={() => handleSetPercentOfUSDC(75)} role="button">75%</div>
                  <div>    </div>
                  <div className="percentBox" onClick={handleSetMaxBuyBalanceUSD} role="button">100%</div>
                </div>
                <div className="tradingBoxRowWithError">
                  <div className="tradingBoxRow">
                    <input
                      className="inputCustom"
                      id="USDCAmountIn"
                      onChange={(e) => {
                        calculateBuyResult(e);
                        validateAmount(e.target.value, USDCBalance, selectedAsset !== "Select Asset");
                      }}
                      type="text"
                      lang="en"
                      placeholder="0.00"
                    />
                    <div className="stablecoinAssetBox">
                      <div className="assetBoxIcon">
                        <img src={usdcIcon} alt="usdc" width="24px" />
                      </div>
                      {stableCoinName}
                    </div>
                  </div>
                  {amountError && <div className="errorMessage">{amountError}</div>}
                </div>
                <div className="row">
                  <div className="col textBalance">Balance: {outputNumber(USDCBalance, 2)}</div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center h-100 py-2">
                <KeyboardDoubleArrowDownOutlinedIcon />
              </div>
              <div className="subBox">
                <b>Buy</b>
                <div className="tradingBoxRow">
                  <input
                    id="TokenPayoutAmount"
                    onChange={calculateBuyResultToken}
                    className="inputCustom"
                    type="text"
                    lang="en"
                    placeholder="0.00"
                  />
                  <div role="button" className="selectAssetButton" onClick={openChooseAssetModal}>
                    <div className="assetBoxIcon">
                      <AssetLogo symbol={selectedAsset} />
                    </div>
                    <div>{selectedAsset} <KeyboardArrowDownOutlinedIcon /></div>
                  </div>
                </div>
                <div className="row">
                  {selectedAsset !== "Select Asset" && (
                    <div className="col textBalance">
                      Balance: {selectedAssetBalance > 0.001 ? (
                        <span>{outputNumber(selectedAssetBalance, 4)}</span>
                      ) : (
                        <span>&lt; 0.001</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {priceDataVisible && (
                <div className="container py-4 pr-4">
                  <div className="row">
                    <div className="col align-self-start">Price:</div>
                    <div className="col align-self-end text-lg-right">
                      {stableCoinName} per {selectedAsset}: {outputNumber(parseFloat(actualPrice), 3)}
                    </div>
                  </div>
                </div>
              )}
              {USDCAmountIn > allowanceUSDC && priceDataVisible && (
                <div className="py-3">
                  <TransactionButton
                    contractAddress={USDC_Address}
                    abi={ERC20_ABI}
                    functionName="approve"
                    args={[MarketRouter_Address, web3_nm.utils.toBigInt(2 ** 255)]}
                    text="Approve"
                    onSuccess={onSuccessApprove}
                  />
                </div>
              )}
              {USDCAmountIn < allowanceUSDC && !warningButtonVisible && priceDataVisible && (
                <div className="py-3">
                  <TransactionButton
                    abi={MarketRouter_ABI}
                    contractAddress={MarketRouter_Address}
                    functionName="swapExactTokensForTokens"
                    args={[USDCAmountIn, TokenPayoutAmountMin, path, address, deadline]}
                    text="Trade"
                    onSuccess={onSuccessBuy}
                  />
                </div>
              )}
              {warningButtonVisible && (
                <div className="py-3">
                  <div className="issuaaButtonDeactivated">{warningButtonMessage}</div>
                </div>
              )}
              {priceDataVisible && (
                <div className="subBox px-4 py-4">
                  <div>Minimum to receive: {outputNumber(parseFloat(TokenPayoutAmountMin / 1e18), 6)}</div>
                  <div className={`priceImpact ${priceImpactBuy > 5 ? 'text-danger' : 'text-success'}`}>
                    Price impact: {parseFloat(priceImpactBuy).toFixed(2)}%
                  </div>
                  <div>Liquidity Provider Trading Fee: {outputNumber(parseFloat(liquidityProviderFee / (10 ** USDDecimals)), 2)} {stableCoinName}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Market;