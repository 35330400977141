// src/components/Pages/Factory/MobileFactory.jsx
import '../../../styles/mobileFactory.css';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { TransactionButton } from '../../../functions/Trx';
import usdcIcon from '../../../img/icons/usd-coin-usdc-logo.png';
import AssetLogo from '../../Common/AssetLogo';
import useFactoryLogic from './useFactoryLogic';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';

const MobileFactory = () => {
  const {
    mintAmount,
    modalOpen,
    selectedAsset,
    errorButtonVisible,
    errorButtonMessage,
    chooseAssetModalOpen,
    filteredAssets,
    tokenAmount,
    USDCAllowance,
    estimatedYield,
    openModal,
    closeModal,
    onSuccessMint,
    onSuccessApprove,
    changeAmount,
    openChooseAssetModal,
    closeChooseAssetModal,
    filterAssets,
    selectAsset,
    setMaxUSDAmount,
    USDCBalance,
    assetFactory_ABI,
    assetFactory_Address,
    ERC20_ABI,
    stableCoinName,
    USDDecimals,
    USDC_Address,
    web3_nm,
    outputNumber,
    renderTooltip1,
    loggedIn
  } = useFactoryLogic();

  const { open } = useWeb3Modal();
  const account = useAccount();
  const address = account.address;
  const [isConnecting, setIsConnecting] = useState(false);

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 4)}...${address.substring(address.length - 5)}`;
  };

  const addressShort = shortenAddress(address);

  const handleConnect = async () => {
    setIsConnecting(true);
    await open();
  };

  useEffect(() => {
    if (address) {
      setIsConnecting(false); // Stop showing "Connecting..." once connected
    }
  }, [address]);

  // Debug log to track state changes
  useEffect(() => {
    console.log('MobileFactory - address:', address, 'loggedIn:', loggedIn, 'isConnecting:', isConnecting);
  }, [address, loggedIn, isConnecting]);

  const listMintableAssets = () => {
    return filteredAssets.map((element, index) => (
      <div key={index} className="assetSelectList" role="button" onClick={() => selectAsset(element[0])}>
        <div className="assetSelectListName">
          <AssetLogo symbol={element[0]} />
          <div>
            <b>{element[0]}</b>
          </div>
          <div>
            <b>{element[3]}</b>
          </div>
        </div>
        <div className="assetSelectListBalance">
          <div>
            Balance: {loggedIn ? `${outputNumber(element[1], 4)} LONG / ${outputNumber(element[2], 4)} SHORT` : 'N/A'}
          </div>
          <div>UL: {outputNumber(Number(element[4]) / 1000, 0)} {stableCoinName}</div>
        </div>
      </div>
    ));
  };

  return (
    <div className="mainContainerMobile">
      <Modal show={chooseAssetModalOpen} onHide={closeChooseAssetModal}>
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>Select TWIN Asset pair to mint</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <div className="row p-3 pr-3 my-auto">
            <input
              type="text"
              className="searchField col w-100"
              id="search"
              placeholder="Search"
              onChange={filterAssets}
            />
          </div>
          <div className="list-group list-assets">{listMintableAssets()}</div>
        </Modal.Body>
      </Modal>

      <Modal show={modalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>You will receive:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="factoryModalHeader">{selectedAsset}</div>
          <div className="factoryModalBody">
            {outputNumber(parseFloat(tokenAmount), 4)} LONG & SHORT Tokens.
            <div className="asset-yield">
              Estimated Yield: {estimatedYield.toFixed(1)}% APY
              <OverlayTrigger
                placement="top"
                trigger="click"
                overlay={renderTooltip1}
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{ color: '#086A24', marginLeft: '0.5rem', cursor: 'pointer' }}
                />
              </OverlayTrigger>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <TransactionButton
            abi={assetFactory_ABI}
            contractAddress={assetFactory_Address}
            functionName="mintAssets"
            args={[selectedAsset, web3_nm.utils.toBigInt(mintAmount * 10 ** USDDecimals)]}
            text="Mint"
            onSuccess={onSuccessMint}
            gasLimit={600000}
            className="w-100"
          />
        </Modal.Footer>
      </Modal>

      <div className="middleBoxSmallMobile">
        <div className="row m-0">
          <div className="mainBox">
            <div className="factoryHeadline">Select a TWIN Asset pair to mint</div>
            <div className="subBox">
              <div className="selectAssetButton factorySelectAssetButton" onClick={openChooseAssetModal}>
                <div className="factorySelectButtonText">
                  <div className="assetBoxIcon">
                    <AssetLogo symbol={selectedAsset} />
                  </div>
                  <div>{selectedAsset}</div>
                </div>
                <div className="factoryIconRight">
                  <KeyboardArrowDownOutlinedIcon />
                </div>
              </div>
              {selectedAsset !== 'Select an asset' && (
                <div className="asset-yield">
                  Estimated Yield: {estimatedYield.toFixed(1)}% APY
                  <OverlayTrigger
                    placement="auto"
                    trigger="click"
                    overlay={renderTooltip1}
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{ color: '#086A24', marginLeft: '0.5rem', cursor: 'pointer' }}
                    />
                  </OverlayTrigger>
                </div>
              )}
            </div>
            <div className="factoryHeadline pt-4">{stableCoinName} amount to deposit</div>
            <div className="subBox">
              <div className="subBoxLine">
                <input
                  placeholder="0.00"
                  className="inputCustom"
                  type="text"
                  id="amountToStake"
                  onChange={changeAmount}
                  disabled={!loggedIn}
                />
                <div className="stablecoinAssetBox">
                  <div className="assetBoxIcon">
                    <img src={usdcIcon} alt="honey icon" width="24px" />
                  </div>
                  {stableCoinName}
                </div>
              </div>
              <div onClick={loggedIn ? setMaxUSDAmount : null} role="button" className="">
                Balance:{' '}
                <span className="tradeBalance">
                  {loggedIn && typeof USDCBalance !== 'undefined'
                    ? `${outputNumber(parseInt(USDCBalance), 0)} ${stableCoinName} (Max)`
                    : `0 ${stableCoinName}`}
                </span>
              </div>
            </div>

            <div className="">
              {loggedIn ? (
                <>
                  {mintAmount > USDCAllowance / 10 ** USDDecimals && (
                    <TransactionButton
                      contractAddress={USDC_Address}
                      abi={ERC20_ABI}
                      functionName="approve"
                      args={[assetFactory_Address, web3_nm.utils.toBigInt(2 ** 255)]}
                      text="Approve"
                      onSuccess={onSuccessApprove}
                    />
                  )}
                  {errorButtonVisible && (
                    <div className="w-100 issuaaButtonDeactivated">{errorButtonMessage}</div>
                  )}
                  {mintAmount < USDCAllowance / 10 ** USDDecimals && !errorButtonVisible && mintAmount > 0 && (
                    <div role="button" className="w-100 issuaaButton" onClick={openModal}>
                      Submit
                    </div>
                  )}
                </>
              ) : (
                <div role="button" className="w-100 issuaaButton" onClick={handleConnect}>
                  {isConnecting ? (
                    <span>
                      <div className="spinner-border spinner-border-sm spinner-border-slow" role="status" />
                      Connecting...
                    </span>
                  ) : address ? (
                    <span>{addressShort}</span>
                  ) : (
                    <span>Connect Wallet</span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFactory;