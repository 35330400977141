import {getWeb3Instance} from '../functions/BlockchainData.jsx'



function timeStampToDate(_timestamp) {
  let timestamp = parseInt(_timestamp)
  const date = new Date(timestamp * 1000)
  const options = {year: 'numeric', month: 'long', day: 'numeric' };
  let formattedDate = date.toLocaleDateString(options);
  return(formattedDate);
  
};

function throttle(fn, delay) {
  let lastCall = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return Promise.resolve(); // Skip this call if it's too soon
    }
    lastCall = now;
    return fn(...args);
  };
}

/**
 * Reads data from a smart contract.
 * @param {string} chain - The chain identifier (e.g., 'bartio').
 * @param {string} contractAddress - The address of the smart contract.
 * @param {Array} ABI - The ABI of the smart contract.
 * @param {string} functionName - The name of the function to call.
 * @param {Array} parameters - The parameters to pass to the function.
 * @returns {Promise<any>} - The result of the contract function call.
 */
async function readContractData(chain, contractAddress, ABI, functionName, parameters = []) {
  try {
    if (chain === 'Berachain BArtio') {chain='bartio'}
    else if (chain === 'Berachain') {chain='berachain'}
    else (chain = 'berachain')
    //const now = new Date();
    //console.log(now)
    console.log(chain, contractAddress, ABI,functionName, parameters)
    console.log(ABI)
    if (functionName === 'totalSupply'){
      console.log(chain, contractAddress, ABI, functionName, parameters)
    }
    // Get the Web3 instance for the specified chain
    let web3_nm = await getWeb3Instance(chain);
    console.log(web3_nm)
    // Create a contract instance
    let contract = new web3_nm.eth.Contract(ABI, contractAddress);

    // Add a small delay to avoid rate limiting
    await sleep(301); // Adjust the delay as needed

    // Call the contract function
    const result = await contract.methods[functionName](...parameters).call();

    // Return the result
    return result;
  } catch (error) {
    console.error(`Error reading contract data:`, error);
    throw error; // Re-throw the error for handling upstream
  }
}

function timeStampToDateAndTime(_timestamp) {
  let timestamp = parseInt(_timestamp)
  var date = new Date(timestamp * 1000)
  const options = {year: 'numeric', month: 'long', day: 'numeric'}
  let formattedDate = date.toLocaleDateString(options)
  const optionsTime = {hour: '2-digit', minute: '2-digit'}
  let formattedTime
  formattedTime = date.toLocaleTimeString([],optionsTime)
  return(formattedDate+" "+formattedTime)
};

function outputNumber(number, digits){
  console.log(number)
  number = parseFloat(number) * (10**digits)
  number = Math.round(number) / (10**digits)
  let output = number.toLocaleString('en-US',{minimumFractionDigits: digits})
  return (output)
}

async function sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const roundDown = (n,d) => {
  n = Math.floor(n*(10**d))
  n = n/(10**d)
  return n
}

export {timeStampToDate, outputNumber, timeStampToDateAndTime,sleep,roundDown, throttle, readContractData};
