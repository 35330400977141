import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../styles/touModal.css';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';

const TOUModal = () => {
  const { setShowTerms, address } = useAppContext();
  const [isChecked, setIsChecked] = useState(false);

  const acceptTOU = async () => {
    if (!isChecked) return;

    try {
      const response = await fetch('https://aapi.twinfinance.io/acceptTerms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ walletAddress: address }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to accept terms');
      }

      console.log('Terms accepted:', data.message);
      setShowTerms(false);
    } catch (error) {
      console.error('Error accepting terms:', error.message);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <Modal show={true} centered dialogClassName="tou-modal">
      <Modal.Header className="border-0 pb-2 pt-4">
        <Modal.Title className="w-100 text-center">
          <h3 className="mb-0 fw-bold">Welcome to Twin Finance</h3>
          <h5 className="text-muted mt-1">Terms of Use and Privacy Policy</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-4 px-5">
        <div className="text-center mb-4">
          <p className="mb-0">
            Before we get started, please review and accept our terms:
          </p>
        </div>
        <div className="agreement-section d-flex align-items-start gap-3">
          <input
            type="checkbox"
            id="accepted"
            className="mt-1 custom-checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <label htmlFor="accepted" className="mb-0 text-start">
            &nbsp;I have read, understood, and agree to the{' '}
            <a href="/TOU" target="_blank" rel="noopener noreferrer" className="text-primary fw-medium">
              Terms of Use
            </a>{' '}
            and{' '}
            <a href="/Privacy" target="_blank" rel="noopener noreferrer" className="text-primary fw-medium">
              Privacy Policy
            </a>.
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 pb-4 justify-content-center">
        <button
          onClick={acceptTOU}
          className="accept-button"
          disabled={!isChecked}
        >
          Accept and Continue
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TOUModal;