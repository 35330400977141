import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext';
import AssetLogo from '../../Common/AssetLogo';
import SearchIcon from '@mui/icons-material/Search'; // Added for search
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; // Added for sort indicator
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // Added for sort indicator

const MobilePortfolioAssets = ({ portfolioAssets, portfolio }) => {
  const { stableCoinName, assetValue, outputNumber, timeStampToDate } = useAppContext();
  const [expandedAsset, setExpandedAsset] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // Function to toggle expansion
  const toggleExpansion = (asset) => {
    setExpandedAsset((prevState) => ({
      ...prevState,
      [asset]: !prevState[asset],
    }));
  };

  // Filter assets based on search term
  const filteredAssets = portfolioAssets
    ? portfolioAssets.filter(
        (asset) =>
          asset.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          (portfolio[asset]?.name || '').toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
    : [];

  // Sort the filtered assets
  const sortedAssets = [...filteredAssets].sort((a, b) => {
    if (!sortColumn) return 0;

    let valueA, valueB;
    switch (sortColumn) {
      case 'name':
        valueA = (portfolio[a]?.name || '').toLowerCase();
        valueB = (portfolio[b]?.name || '').toLowerCase();
        return sortDirection === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      case 'value':
        valueA = parseFloat(portfolio[a]?.portfolioValue || 0);
        valueB = parseFloat(portfolio[b]?.portfolioValue || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      case 'price':
        valueA = parseFloat(portfolio[a]?.price || 0);
        valueB = parseFloat(portfolio[b]?.price || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      default:
        return 0;
    }
  });

  // Handle sort click
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Render sort indicator (always show, style based on active sort)
  const renderSortIndicator = (column) => {
    const isActive = sortColumn === column;
    const isAsc = sortDirection === 'asc';
    return isActive && !isAsc ? (
      <ArrowDownwardIcon
        fontSize="small"
        className={`sortIconMobile ${isActive ? 'sortIconActiveMobile' : ''}`}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        className={`sortIconMobile ${isActive ? 'sortIconActiveMobile' : ''}`}
      />
    );
  };

  return (
    <div className="portfolioAssetBoxMobile">
      <div className="portfolioBoxHeaderMobile">
        <div className="headlineSearchWrapperMobile">
          <div>Your Assets</div>
          <div className="searchContainerMobile">
            <SearchIcon className="searchIconMobile" />
            <input
              type="text"
              placeholder="Search assets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="searchInputMobile"
            />
          </div>
        </div>
      </div>
      <div className="row mainPortfolioContentMobile">
        <div className="pl-3 pr-3 w-100">
          <div className="sortButtonsMobile">
            <button onClick={() => handleSort('name')} className="sortButtonMobile">
              Name {renderSortIndicator('name')}
            </button>
            <button onClick={() => handleSort('value')} className="sortButtonMobile">
              Value {renderSortIndicator('value')}
            </button>
            <button onClick={() => handleSort('price')} className="sortButtonMobile">
              Price {renderSortIndicator('price')}
            </button>
          </div>
          {sortedAssets && sortedAssets.length > 0 ? (
            <div className="w-100">
              {sortedAssets.map((asset, index) => (
                <div className="portfolioCard" key={index}>
                  <div className="portfolioCardHeader">
                    <div className="assetNameSymbolMobile">
                      <AssetLogo symbol={asset} />
                      <span>{portfolio[asset]?.name} ({asset})</span>
                    </div>
                    {portfolio[asset]?.isFrozen ? (
                      <div className="portfolioStatusFrozenMobile">FROZEN</div>
                    ) : (
                      <div className="portfolioStatusLiveMobile">LIVE</div>
                    )}
                  </div>
                  <div className="text-left">Current Price: ${outputNumber(portfolio[asset]?.price, 2)}</div>
                  <div className="text-left">Your holding: {outputNumber(portfolio[asset]?.balance, 4)}</div>
                  <div className="text-left">Position value: ${outputNumber(portfolio[asset]?.portfolioValue, 0)}</div>

                  {/* Expandable section */}
                  {expandedAsset[asset] && (
                    <>
                      <div className="text-left">
                        {asset !== 'TWIN' && asset !== 'HONEY' ? (
                          <div>Upper limit: ${outputNumber(parseFloat(portfolio[asset]?.upperLimit) / 1000, 2)}</div>
                        ) : (
                          <div>n.a.</div>
                        )}
                      </div>
                      <div className="text-left">
                        {asset !== 'TWIN' && asset !== 'HONEY' ? (
                          <div>Expiry date: {timeStampToDate(portfolio[asset]?.expiryDate)}</div>
                        ) : (
                          'n.a.'
                        )}
                      </div>
                    </>
                  )}
                  <div
                    className="expandToggle"
                    onClick={() => toggleExpansion(asset)}
                    style={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'right' }}
                  >
                    {expandedAsset[asset] ? 'Collapse' : 'Expand'}
                  </div>
                  <div>
                    {portfolio[asset]['name'] !== stableCoinName ? (
                      <div className="d-flex flex-row tradeButtonRowMobile">
                        <Link to={`/trade/buy/${portfolio[asset]['marketPair']}`}>
                          <div className="buyButtonMobile">Buy</div>
                        </Link>
                        <Link to={`/trade/sell/${portfolio[asset]['marketPair']}`}>
                          <div className="sellButtonMobile">Sell</div>
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="portfolioCard">
              <div>No assets available</div>
            </div>
          )}
          <div className="portfolioCardSummary">
            <div className="text-left portfolioCardHeader">Total Assets: $ {outputNumber(assetValue, 0)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePortfolioAssets;