import '../styles/mobile.css';
import React from 'react';
import { useAppContext } from '../context/AppContext';
import { Modal } from "react-bootstrap";
import MobileMainpage from './Pages/Mainpage/mobileMainpage';
import MobileNavbar from './Pages/Navbar/mobileNavbar';
import MobileFooter from './Pages/Footer/mobileFooter'; 
import MobileFactory from './Pages/Factory/mobileFactory';
import MobilePortfolio from './Pages/Portfolio/mobilePortfolio';
import MobileBurnAssets from './Pages/BurnAssets/mobileBurnAssets';
import MessageBox from './Common/MessageBox';
import MobileTWIN from './Pages/TWIN/TWIN';
import MobileMarket from './Pages/Market/mobileMarket';
import MobilePool from './Pages/Pool/mobilePool';
import MobileGovernance from './Pages/Governance/mobileGovernance';
import MobileTWIND from './Pages/TWIND/mobileTWIND';
import ChangeChain from './Common/ChangeChain';
import GeoBlock from './Common/GeoBlock';
import TOUModal from './Common/TOUModal';
import TOU from './Common/TOU';
import PrivacyPolicy from './Common/PrivacyPolicy';
import PointsDashboard from './Pages/Points/PointsDashboard';
import { Routes, Route } from 'react-router-dom';
import logo from '../img/graphics/TWIN_Logo_loading.png';

const Mobile = () => {
  const {
    fullProtocolIntegration,
    loggedIn,
    pageLoadedMainpage,
    messageBoxVisible,
    messageBoxContent,
    messageBoxHeader,
    messageBoxFooter,
    loadingBlockchainData,
    wrongNetworkMessage,
    activePage,
    geoLocationOk,
    showTerms
  } = useAppContext();

  return (
    <div className="mainArea">
      <div className="backgroundImageNew">
        <div className="layer">
          {showTerms ? <TOUModal /> : null}
          <div className="w-100 m-0 p-0">
            <MobileNavbar />
            <div className="contentContainerMobile">
              <div className='headlineMobile'>{activePage}</div>
              {geoLocationOk ? (
                <Routes>
                  <Route
                    path="/"
                    element={
                      pageLoadedMainpage ? (
                        <MobileMainpage />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/portfolio"
                    element={
                      pageLoadedMainpage ? (
                        <MobilePortfolio />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/pool/*"
                    element={
                      pageLoadedMainpage ? (
                        <MobilePool />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/mint"
                    element={
                      pageLoadedMainpage ? (
                        <MobileFactory />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/trade/*"
                    element={
                      pageLoadedMainpage ? (
                        <MobileMarket />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/redeem"
                    element={
                      pageLoadedMainpage ? (
                        <MobileBurnAssets />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/TWIN"
                    element={
                      pageLoadedMainpage ? (
                        <MobileTWIN />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/TWIND"
                    element={
                      pageLoadedMainpage ? (
                        <MobileTWIND />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/governance"
                    element={
                      pageLoadedMainpage ? (
                        <MobileGovernance />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/points"
                    element={
                      pageLoadedMainpage ? (
                        <PointsDashboard />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route path="/TOU" element={<TOU />} />
                  <Route path="/Privacy" element={<PrivacyPolicy />} />
                </Routes>
              ) : (
                <div className="mainContainerMobile">
                  <div className="middleBoxFullMobile">
                    <GeoBlock />
                  </div>
                </div>
              )}
              <MobileFooter />
            </div>
            {messageBoxVisible ? (
              <MessageBox content={messageBoxContent} header={messageBoxHeader} footer={messageBoxFooter} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <Modal dialogClassName="loadingBlockchainModalOuter" show={loadingBlockchainData} centered>
        <div className="loadingBlockchainModal">
          <div className="row">
            <div className="col-12 center pt-4">
              <img className="center" src={logo} alt="Logo" width="" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 h5 text-center">
              <div className="spinner-border text-center mt-4" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <div className="col-12 pt-2 pb-3 mr-3 text-center">
              Loading Blockchain data...
            </div>
          </div>
        </div>
      </Modal>

      <Modal className="rounded" show={wrongNetworkMessage}>
        <div className="p-3 bg-dark text-light border border-accent rounded">
          <div className="row m-4"></div>
          <div className="row">
            <div className="col text-center">
              <p>Wrong Network selected.</p>
              Please switch to Berachain or Berachain Artio Testnet.
            </div>
          </div>
          <div className="row m-4"></div>
        </div>
      </Modal>
    </div>
  );
};

export default Mobile;