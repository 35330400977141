// usePoolLogic.js
import { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext';

const usePoolLogic = () => {
    const {
        assetDetails,
        pools,
        web3_nm,
        GovernanceTokenBalance,
        GovernanceToken_Address,
        MarketFactory_nm,
        MarketPair_ABI,
        ERC20_ABI,
        USDC_Address,
        outputNumber,
        stableCoinName,
        address,
        MarketRouter_Address,
        slippage,
        trxTime,
        updateAssetBalanceWithAddress,
        loadUSDBalance,
        updateLPPair,
        loadLPBalances,
        updatePortfolioValue,
        USDCBalance,
        USDDecimals,
        sleep,
        MarketRouter_ABI,
        saveSlippagePreference,
        saveTrxTimePreference,
        roundDown,
        chainName
    } = useAppContext();

    // State variables
    const [assets, setAssets] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [assetBalances, setAssetBalances] = useState({});
    const [assetAddresses, setAssetAddresses] = useState({});
    const [chooseAssetModalOpen, setChooseAssetModalOpen] = useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [wrongSlippageInputMessage, setWrongSlippageInputMessage] = useState();
    const [highSlippageInputMessage, setHighSlippageInputMessage] = useState();
    const [wrongTrxTimeInputMessage, setWrongTrxTimeInputMessage] = useState();
    const [filteredLPAssets, setFilteredLPAssets] = useState([]);
    const [chooseLPTokenModalOpen, setChooseLPTokenModalOpen] = useState();
    const [allowanceToken, setAllowanceToken] = useState();
    const [allowanceUSDC, setAllowanceUSDC] = useState();
    const [allowanceLPToken, setAllowanceLPToken] = useState();
    const [selectedAssetAddress, setSelectedAssetAddress] = useState('');
    const [selectedAsset, setSelectedAsset] = useState('Select Asset');
    const [selectedAssetBalance, setSelectedAssetBalance] = useState();
    const [token0, setToken0] = useState();
    const [selectedLPTokenBalance, setSelectedLPTokenBalance] = useState();
    const [selectedAssetPrice, setSelectedAssetPrice] = useState();
    const [approvalButtonTokenVisible, setApprovalButtonTokenVisible] = useState();
    const [approvalButtonUSDCVisible, setApprovalButtonUSDCVisible] = useState();
    const [addLiquidityButtonVisible, setAddLiquidityButtonVisible] = useState(true);
    const [removeLiquidityButtonVisible, setRemoveLiquidityButtonVisible] = useState(false);
    const [buttonMessage, setButtonMessage] = useState();
    const [selectedLPToken, setSelectedLPToken] = useState();
    const [selectedLPTokenAddress, setSelectedLPTokenAddress] = useState();
    const [token1Ratio, setToken1Ratio] = useState();
    const [token2Ratio, setToken2Ratio] = useState();
    const [approvalButtonLPTokenVisible, setApprovalButtonLPTokenVisible] = useState();
    const [selectedLPPairAddress, setSelectedLPPairAddress] = useState();
    const [errorButtonVisible, setErrorButtonVisible] = useState();
    const [expectedOutputVisible, setExpectedOutputVisible] = useState();
    const [tokenAmountA, setTokenAmountA] = useState();
    const [tokenAmountB, setTokenAmountB] = useState();
    const [selectedLPTokenAmount, setSelectedLPTokenAmount] = useState();
    const [estimatedOutput1, setEstimatedOutput1] = useState();
    const [estimatedOutput2, setEstimatedOutput2] = useState();
    const [amountADesired, setAmountADesired] = useState();
    const [amountBDesired, setAmountBDesired] = useState();
    const [amountAMin, setAmountAMin] = useState();
    const [amountBMin, setAmountBMin] = useState();
    const [deadline, setDeadline] = useState();
    const [liquidityToRemove, setLiquidityToRemove] = useState();
    const [showAddLiquidyPart, setShowAddLiquidyPart] = useState(true);
    const [showRemoveLiquidyPart, setShowRemoveLiquidyPart] = useState();
    const [style2, setStyle2] = useState('selectButtonUnselected');
    const [style1, setStyle1] = useState('selectButtonSelected');

    useEffect(() => {
        const initialize = async () => {
            const locationUnSplit = window.location.pathname;
            if (locationUnSplit) {
                const locationSplit = locationUnSplit.split("/");
                const buyOrSellVisible = locationSplit[2];
                const pairAddress = locationSplit[3];
                if (buyOrSellVisible === 'remove') {
                    showRemoveLiquidity();
                }
                initiatiatePage(pairAddress, buyOrSellVisible);
            }
        };
        initialize();
    }, []);

    useEffect(() => {
        if (estimatedOutput1 !== undefined && estimatedOutput2 !== undefined) {
            calculateRemoveLiquidity();
        }
    }, [estimatedOutput1, estimatedOutput2]);

    const initiatiatePage = async (pairAddress, buyOrSellVisible) => {
        try {
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pairAddress);
            let token0 = await MarketPair.methods.token0().call();
            let token1 = await MarketPair.methods.token1().call();
            let TokenContract0 = new web3_nm.eth.Contract(ERC20_ABI, token0);
            let tokenSymbol0 = await TokenContract0.methods.symbol().call();
            let TokenContract1 = new web3_nm.eth.Contract(ERC20_ABI, token1);
            let tokenSymbol1 = await TokenContract1.methods.symbol().call();
            let selectedAsset, assetAddress;
            if (tokenSymbol0 === 'USDC') {
                selectedAsset = tokenSymbol1;
                assetAddress = token1;
            } else {
                selectedAsset = tokenSymbol0;
                assetAddress = token0;
            }
            if (buyOrSellVisible === 'remove') {
                selectLPInitial(selectedAsset, assetAddress, pairAddress);
            } else {
                selectAssetInitial(selectedAsset, assetAddress, pairAddress);
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const closeChooseAssetModal = () => setChooseAssetModalOpen(false);
    const openSettingsModal = () => setSettingsModalOpen(true);
    const closeSettingsModal = () => setSettingsModalOpen(false);
    const convertBigInt = (number) => {
        const regularNumber = parseFloat(number);
        const integerValue = Math.round(regularNumber);
        return web3_nm.utils.toBigInt(integerValue);
    };

    const checkSlippageInput = () => {
        try {
            let slippage = document.getElementById('slippage').value * 100;
            if (isNaN(slippage) || slippage < 0 || slippage > 5000) {
                setWrongSlippageInputMessage(true);
                setHighSlippageInputMessage(false);
                return false;
            }
            if (slippage > 500 && slippage < 5000) {
                setWrongSlippageInputMessage(false);
                setHighSlippageInputMessage(true);
                return false;
            }
            setWrongSlippageInputMessage(false);
            setHighSlippageInputMessage(false);
            return true;
        } catch {
            return false;
        }
    };

    const saveSettingsSlippage = async () => {
        if (checkSlippageInput()) {
            let slippage = document.getElementById('slippage').value * 100;
            await saveSlippagePreference(slippage);
        }
    };

    const checkTrxTimeInput = () => {
        try {
            let trxTime = document.getElementById('trxTime').value;
            if (isNaN(trxTime) || trxTime < 0 || trxTime > 600) {
                setWrongTrxTimeInputMessage(true);
                return false;
            }
            setWrongTrxTimeInputMessage(false);
            return true;
        } catch {
            return false;
        }
    };

    const saveSettingsTrxTime = async () => {
        if (checkTrxTimeInput()) {
            let trxTime = document.getElementById('trxTime').value;
            await saveTrxTimePreference(trxTime);
        }
    };

    const checkApproval = async (tokenAddress, approvalAddress) => {
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI, tokenAddress);
        var amount = web3_nm.utils.toBigInt("100000000000000000000000000000");
        let allowance = await tokenContract.methods.allowance(address, approvalAddress).call();
        return parseInt(allowance) >= parseInt(amount);
    };

    const filterAssets = () => {
        let _availableAssets = assets.filter(asset => asset[1] > 0);
        let searchTerm = document.getElementById('search').value.toLowerCase();
        let _filteredAssets = _availableAssets.filter(asset =>
            asset[2].toLowerCase().includes(searchTerm) || asset[0].toLowerCase().includes(searchTerm)
        );
        setFilteredAssets(_filteredAssets);
    };

    const openSelectAssetModal = async () => {
        let _assets = [];
        let _assetBalances = {};
        let _assetAddresses = {};
        for (let i = 0; i < pools.length; ++i) {
            let _ticker = pools[i][0];
            let _balance = assetDetails[_ticker]?.tokenBalance1 || assetDetails[_ticker.slice(1)]?.tokenBalance2 || GovernanceTokenBalance;
            let _name = pools[i][3];
            let _lpBalance = pools[i][4];
            _assets.push([_ticker, _balance, _name, _lpBalance]);
            _assetBalances[_ticker] = _balance;
            _assetAddresses[_ticker] = pools[i][11];
        }
        setAssets(_assets);
        setFilteredAssets(_assets);
        setAssetBalances(_assetBalances);
        setAssetAddresses(_assetAddresses);
        setChooseAssetModalOpen(true);
    };

    const openChooseLPTokenModal = async () => {
        let _assets = [];
        let _assetBalances = {};
        let _assetAddresses = {};
        for (let i = 0; i < pools.length; ++i) {
            let _ticker = pools[i][0];
            let _balance = assetDetails[_ticker]?.tokenBalance1 || assetDetails[_ticker.slice(1)]?.tokenBalance2 || GovernanceTokenBalance;
            let _name = pools[i][3];
            let _lpBalance = pools[i][4];
            _assets.push([_ticker, _balance, _name, _lpBalance]);
            _assetBalances[_ticker] = _balance;
            _assetAddresses[_ticker] = pools[i][11];
        }
        let _filteredAssets = _assets.filter(asset => asset[3] > 9999);
        setAssets(_assets);
        setFilteredLPAssets(_filteredAssets);
        setAssetBalances(_assetBalances);
        setAssetAddresses(_assetAddresses);
        setChooseLPTokenModalOpen(true);
    };

    const closeChooseLPTokenModal = () => setChooseLPTokenModalOpen(false);

    const listLPTokens = () => {
        if (filteredLPAssets.length === 0) return [];
        return filteredLPAssets.map((element, index) => ({
            key: index,
            ticker: element[0],
            balance: element[1],
            name: element[2],
            lpBalance: element[3]
        }));
    };

    const listAssets = () => {
        if (filteredAssets.length === 0) return [];
        return filteredAssets.map((element, index) => ({
            key: index,
            ticker: element[0],
            balance: element[1],
            name: element[2],
            lpBalance: element[3]
        }));
    };

    const checkAllowances = async (_tokenAddress) => {
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI, _tokenAddress);
        let _allowanceToken = await tokenContract.methods.allowance(address, MarketRouter_Address).call();
        setAllowanceToken(parseInt(_allowanceToken));
        
        let USDCContract = new web3_nm.eth.Contract(ERC20_ABI, USDC_Address);
        let _allowanceUSDC = await USDCContract.methods.allowance(address, MarketRouter_Address).call();
        setAllowanceUSDC(parseInt(_allowanceUSDC));
    };

    const checkAllowancesLP = async (_tokenAddress) => {
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI, _tokenAddress);
        let _allowanceLPToken = await tokenContract.methods.allowance(address, MarketRouter_Address).call();
        setAllowanceLPToken(parseInt(_allowanceLPToken));
    };

    const selectAsset = async (asset) => {
        document.getElementById('tokenAmountA').value = 0;
        document.getElementById('tokenAmountB').value = 0;
        setSelectedAsset(asset);
        let _selectedAssetAddress = assetAddresses[asset];
        setSelectedAssetAddress(_selectedAssetAddress);
        await checkAllowances(_selectedAssetAddress);
        setSelectedAssetBalance(assetBalances[asset]);
        closeChooseAssetModal();

        try {
            let pair = await MarketFactory_nm.methods.getPair(_selectedAssetAddress, USDC_Address).call();
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
            let _token0 = await MarketPair.methods.token0().call();
            setToken0(_token0);
            let balanceWEI = await MarketPair.methods.balanceOf(address).call();
            let balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'));
            setSelectedLPTokenBalance(balance);
            let reserves = await MarketPair.methods.getReserves().call();
            let price = _token0 === USDC_Address ? parseInt(reserves[0]) / parseInt(reserves[1]) : parseInt(reserves[1]) / parseInt(reserves[0]);
            setSelectedAssetPrice(price);
        } catch {
            setSelectedAssetPrice(0);
        }

        let approvalGivenToken = await checkApproval(_selectedAssetAddress, MarketRouter_Address);
        setApprovalButtonTokenVisible(!approvalGivenToken);
        let approvalGivenUSDC = await checkApproval(USDC_Address, MarketRouter_Address);
        setApprovalButtonUSDCVisible(!approvalGivenUSDC);
        setAddLiquidityButtonVisible(approvalGivenUSDC && approvalGivenToken);
        if (!approvalGivenUSDC || !approvalGivenToken) setButtonMessage("Waiting for approval...");
        checkButtons();
    };

    const selectAssetInitial = async (_asset, _assetAddress, pair) => {
        setSelectedAsset(_asset);
        setSelectedAssetAddress(_assetAddress);
        await checkAllowances(_assetAddress);
        let tokenContract = await new web3_nm.eth.Contract(ERC20_ABI, _assetAddress);
        let selectedAssetBalanceRaw = await tokenContract.methods.balanceOf(address).call();
        let _selectedAssetBalance = parseInt(selectedAssetBalanceRaw) / 1e18;
        setSelectedAssetBalance(_selectedAssetBalance);

        try {
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
            let _token0 = await MarketPair.methods.token0().call();
            setToken0(_token0);
            let balanceWEI = await MarketPair.methods.balanceOf(address).call();
            let balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'));
            setSelectedLPTokenBalance(balance);
            let reserves = await MarketPair.methods.getReserves().call();
            let price = _token0 === USDC_Address ? parseInt(reserves[0]) / parseInt(reserves[1]) : parseInt(reserves[1]) / parseInt(reserves[0]);
            setSelectedAssetPrice(price);
        } catch {
            setSelectedAssetPrice(0);
        }

        let approvalGivenToken = await checkApproval(_assetAddress, MarketRouter_Address);
        setApprovalButtonTokenVisible(!approvalGivenToken);
        let approvalGivenUSDC = await checkApproval(USDC_Address, MarketRouter_Address);
        setApprovalButtonUSDCVisible(!approvalGivenUSDC);
        setAddLiquidityButtonVisible(approvalGivenUSDC && approvalGivenToken);
        if (!approvalGivenUSDC || !approvalGivenToken) setButtonMessage("Waiting for approval...");
        checkButtons();
    };

    const selectLPToken = async (_asset) => {
        document.getElementById('LPTokenAmount').value = 0;
        setSelectedLPToken(_asset);
        setSelectedAsset(_asset);
        setSelectedAssetAddress(assetAddresses[_asset]);
        let _selectedLPTokenAddress = assetAddresses[_asset];
        setSelectedLPTokenAddress(_selectedLPTokenAddress);
        checkAllowancesLP(_selectedLPTokenAddress);
        let pair = await MarketFactory_nm.methods.getPair(_selectedLPTokenAddress, USDC_Address).call();
        setSelectedLPPairAddress(pair);
        let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
        let _token0 = await MarketPair.methods.token0().call();
        setToken0(_token0);
        let balanceWEI = await MarketPair.methods.balanceOf(address).call();
        let balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'));
        let totalSupplyWEI = await MarketPair.methods.totalSupply().call();
        let reserves = await MarketPair.methods.getReserves().call();
        let _token1Ratio = parseInt(reserves[0]) / parseInt(totalSupplyWEI);
        let _token2Ratio = parseInt(reserves[1]) / parseInt(totalSupplyWEI);
        setToken1Ratio(_token1Ratio);
        setToken2Ratio(_token2Ratio);
        setSelectedLPTokenBalance(balance);
        setSelectedAssetBalance(assetBalances[_asset]);
        let price = _token0 === USDC_Address ? reserves[0] / reserves[1] : reserves[1] / reserves[0];
        setSelectedAssetPrice(price);

        let approvalGiven = await checkApproval(pair, MarketRouter_Address);
        setApprovalButtonLPTokenVisible(!approvalGiven);
        setRemoveLiquidityButtonVisible(approvalGiven);
        if (!approvalGiven) setButtonMessage("Waiting for approval...");
        calculateTokenOutput();
        closeChooseLPTokenModal();
    };

    const selectLPInitial = async (_asset, _assetAddress, _pair) => {
        setSelectedLPToken(_asset);
        setSelectedAsset(_asset);
        setSelectedAssetAddress(_assetAddress);
        checkAllowancesLP(_assetAddress);
        setSelectedLPPairAddress(_pair);
        let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, _pair);
        let _token0 = await MarketPair.methods.token0().call();
        setToken0(_token0);
        let balanceWEI = await MarketPair.methods.balanceOf(address).call();
        let balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'));
        let totalSupplyWEI = await MarketPair.methods.totalSupply().call();
        let reserves = await MarketPair.methods.getReserves().call();
        let _token1Ratio = parseInt(totalSupplyWEI) / parseInt(reserves[0]);
        let _token2Ratio = parseInt(totalSupplyWEI) / parseInt(reserves[1]);
        setToken1Ratio(_token1Ratio);
        setToken2Ratio(_token2Ratio);
        setSelectedLPTokenBalance(balance);
        let tokenContract = await new web3_nm.eth.Contract(ERC20_ABI, _assetAddress);
        let selectedAssetBalanceRaw = await tokenContract.methods.balanceOf(address).call();
        let _selectedAssetBalance = parseInt(selectedAssetBalanceRaw) / 1e18;
        setSelectedAssetBalance(_selectedAssetBalance);
        let price = _token0 === USDC_Address ? reserves[0] / reserves[1] : reserves[1] / reserves[0];
        setSelectedAssetPrice(price);

        let approvalGiven = await checkApproval(_pair, MarketRouter_Address);
        setApprovalButtonLPTokenVisible(!approvalGiven);
        setRemoveLiquidityButtonVisible(approvalGiven);
        if (!approvalGiven) setButtonMessage("Waiting for approval...");
        calculateTokenOutput();
        closeChooseLPTokenModal();
    };

    const onSuccessApproveToken = async () => {
        setApprovalButtonTokenVisible(false);
        await sleep(500);
        await checkAllowances(selectedAssetAddress);
        await checkAllowances(USDC_Address);
        await checkButtons();
    };

    const onSuccessApproveLP = async () => {
        await checkAllowancesLP(selectedLPPairAddress);
        checkButtons();
    };

    const checkButtons = async () => {
        if (showAddLiquidyPart) {
            let tokenVolume = parseFloat(document.getElementById('tokenAmountA').value) || 0;
            let USDVolume = parseFloat(document.getElementById('tokenAmountB').value) || 0;
            if (!tokenVolume || !USDVolume) {
                setErrorButtonVisible(true);
                setButtonMessage("Enter valid amount");
            } else if (selectedAssetBalance < tokenVolume || USDCBalance < USDVolume) {
                setErrorButtonVisible(true);
                setButtonMessage("Balance too low");
            } else {
                setErrorButtonVisible(false);
            }
        } else {
            let LPTokenAmount = parseFloat(document.getElementById('LPTokenAmount').value) || 0;
            if (selectedLPTokenBalance < LPTokenAmount) {
                setErrorButtonVisible(true);
                setButtonMessage("Balance too low");
            } else if (!LPTokenAmount) {
                setErrorButtonVisible(true);
                setButtonMessage("Enter valid amount");
            } else {
                setErrorButtonVisible(false);
            }
        }
    };

    const calculateUSDVolume = async () => {
        if (!selectedAssetAddress || !document.getElementById('tokenAmountA').value) {
            document.getElementById('tokenAmountA').value = '';
            document.getElementById('tokenAmountB').value = '';
            return;
        }
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('tokenAmountA').value);
        if (!isPositiveNumber) {
            document.getElementById('tokenAmountA').value = '';
            return;
        }
        checkAllowances(selectedAssetAddress);
        let tokenVolume = parseFloat(document.getElementById('tokenAmountA').value);
        let USDCVolume = selectedAssetPrice && !isNaN(selectedAssetPrice) ?
            (parseInt(USDDecimals) === 6 ? parseFloat(tokenVolume * selectedAssetPrice * (10 ** 12)).toFixed(6) : parseFloat(tokenVolume * selectedAssetPrice).toFixed(13)) :
            document.getElementById('tokenAmountB').value;
        setTokenAmountA(tokenVolume);
        setTokenAmountB(USDCVolume);
        document.getElementById('tokenAmountB').value = USDCVolume;
        setExpectedOutputVisible(!!selectedAssetPrice);
        checkButtons();
        calculateAddLiquidity();
    };

    const calculateTokenVolume = async () => {
        if (!selectedAssetAddress || !document.getElementById('tokenAmountB').value) {
            document.getElementById('tokenAmountA').value = '';
            document.getElementById('tokenAmountB').value = '';
            return;
        }
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('tokenAmountB').value);
        if (!isPositiveNumber) {
            document.getElementById('tokenAmountB').value = '';
            return;
        }
        checkAllowances(selectedAssetAddress);
        let USDCVolume = parseFloat(document.getElementById('tokenAmountB').value);
        let TokenVolume = selectedAssetPrice && !isNaN(selectedAssetPrice) ?
            USDCVolume / selectedAssetPrice / (10 ** (18 - USDDecimals)) :
            document.getElementById('tokenAmountA').value;
        setTokenAmountA(TokenVolume);
        setTokenAmountB(USDCVolume);
        document.getElementById('tokenAmountA').value = TokenVolume;
        setExpectedOutputVisible(!!selectedAssetPrice);
        checkButtons();
        calculateAddLiquidity();
    };

    const calculateTokenOutput = async () => {
        if (!selectedLPPairAddress || !document.getElementById('LPTokenAmount').value) {
            document.getElementById('LPTokenAmount').value = '';
            return;
        }
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('LPTokenAmount').value);
        if (!isPositiveNumber) {
            document.getElementById('LPTokenAmount').value = '';
            return;
        }
        checkAllowancesLP(selectedLPPairAddress);
        let LPTokenAmount = parseFloat(document.getElementById('LPTokenAmount').value);
        setSelectedLPTokenAmount(LPTokenAmount);
        let tokenVolume = token0 === USDC_Address ? LPTokenAmount * token2Ratio : LPTokenAmount * token1Ratio;
        let USDCVolume = token0 === USDC_Address ? LPTokenAmount * token1Ratio * (10 ** (18 - USDDecimals)) : LPTokenAmount * token2Ratio * (10 ** (18 - USDDecimals));
        setEstimatedOutput1(tokenVolume);
        setEstimatedOutput2(USDCVolume);
        setExpectedOutputVisible(!isNaN(USDCVolume) && !isNaN(tokenVolume));
        checkButtons();
    };

    const onSuccessAddLiquidity = async () => {
        let selectedAssetAddressOld = selectedAssetAddress;
        setSelectedAsset("Select Asset");
        setSelectedAssetAddress('');
        setSelectedAssetBalance(0);
        setSelectedAssetPrice(0);
        setErrorButtonVisible(true);
        setButtonMessage("Select an asset");
        document.getElementById('tokenAmountA').value = 0;
        document.getElementById('tokenAmountB').value = 0;

        await updateAssetBalanceWithAddress(selectedAssetAddressOld);
        await loadUSDBalance();
        await loadLPBalances();
        await updatePortfolioValue();
    };

    const calculateAddLiquidity = async () => {
        let tokenAmtA = parseFloat(document.getElementById('tokenAmountA').value) || 0;
        let tokenAmtB = parseFloat(document.getElementById('tokenAmountB').value) || 0;
    
        if (!tokenAmtA || !tokenAmtB) {
            setAmountADesired(0n);
            setAmountBDesired(0n);
            setAmountAMin(0n);
            setAmountBMin(0n);
            return;
        }
    
        // Match original scaling
        let amountADesiredRaw = tokenAmtA * 1e18; // Token A: 18 decimals
        let amountBDesiredRaw = tokenAmtB * (10 ** parseFloat(USDDecimals) - 1); // USDC: Dynamic decimals - 1
        let _amountADesired = web3_nm.utils.toBigInt(Math.round(amountADesiredRaw));
        let _amountBDesired = web3_nm.utils.toBigInt(Math.round(amountBDesiredRaw));
        let amountAMinRaw = amountADesiredRaw * (10000 - parseInt(slippage)) / 10000;
        let amountBMinRaw = amountBDesiredRaw * (10000 - parseInt(slippage)) / 10000;
        let _amountAMin = web3_nm.utils.toBigInt(Math.round(amountAMinRaw));
        let _amountBMin = web3_nm.utils.toBigInt(Math.round(amountBMinRaw));
    
        setAmountADesired(_amountADesired);
        setAmountBDesired(_amountBDesired);
        setAmountAMin(_amountAMin);
        setAmountBMin(_amountBMin);
        let _deadline = Math.round(+new Date() / 1000) + trxTime * 60;
        setDeadline(_deadline);
    
        console.log("Calculated amounts:", {
            amountADesired: _amountADesired.toString(),
            amountBDesired: _amountBDesired.toString(),
            amountAMin: _amountAMin.toString(),
            amountBMin: _amountBMin.toString(),
            deadline: _deadline
        });
    };

    const calculateRemoveLiquidity = async () => {
        let _liquidityToRemove = parseFloat(document.getElementById('LPTokenAmount').value) * 10 ** 18;
        setLiquidityToRemove(_liquidityToRemove);
        // Match original: amount1 is USDC, amount2 is Token
        let amount1Raw = parseInt(USDDecimals) === 6 ? roundDown(estimatedOutput2, 6) * 10 ** 6 : roundDown(estimatedOutput2, 6) * 10 ** 18; // USDC
        let amount2Raw = parseFloat(estimatedOutput1).toFixed(11) * 10 ** 18; // Token
        let amount1 = web3_nm.utils.toBigInt(parseInt(amount1Raw));
        let amount2 = web3_nm.utils.toBigInt(parseInt(amount2Raw));
        let _amountAMin = parseInt(amount2 * (web3_nm.utils.toBigInt(10000 - slippage)) / (web3_nm.utils.toBigInt(10000))); // Token A
        let _amountBMin = parseInt(amount1 * (web3_nm.utils.toBigInt(10000 - slippage)) / (web3_nm.utils.toBigInt(10000))); // USDC
        let _deadline = Math.round(+new Date() / 1000) + trxTime * 60;
        setAmountAMin(_amountAMin);
        setAmountBMin(_amountBMin);
        setDeadline(_deadline);
    
        console.log("Remove liquidity amounts:", {
            liquidityToRemove: _liquidityToRemove.toString(),
            amountAMin: _amountAMin.toString(),
            amountBMin: _amountBMin.toString(),
            deadline: _deadline
        });
    };

    const onSuccessRemoveLiquidity = async () => {
        let selectedAssetAddressOld = selectedAssetAddress;
        setSelectedLPTokenBalance(0);
        setSelectedLPToken("Select Asset");
        setSelectedLPTokenAddress('');
        setExpectedOutputVisible(false);
        setErrorButtonVisible(true);
        setButtonMessage("Select an asset");
        setSelectedLPPairAddress('');
        document.getElementById('LPTokenAmount').value = 0;

        await updateAssetBalanceWithAddress(selectedAssetAddressOld);
        await loadUSDBalance();
        await loadLPBalances();
        await updatePortfolioValue();
    };

    const showRemoveLiquidity = async () => {
        setSelectedLPToken("Select Asset");
        setSelectedAssetAddress('');
        setExpectedOutputVisible(false);
        setSelectedLPPairAddress('');
        setErrorButtonVisible(true);
        setButtonMessage("Select an asset");
        setShowAddLiquidyPart(false);
        setShowRemoveLiquidyPart(true);
        setApprovalButtonLPTokenVisible(false);
        setStyle2("selectButtonSelected");
        setStyle1("selectButtonUnselected");
        setSelectedLPTokenBalance(0);
    };

    const showAddLiquidity = async () => {
        setSelectedAsset("Select Asset");
        setSelectedAssetAddress('');
        setSelectedLPToken("Select Asset");
        setButtonMessage("Select an asset");
        setShowAddLiquidyPart(true);
        setShowRemoveLiquidyPart(false);
        setStyle2("selectButtonUnselected");
        setStyle1("selectButtonSelected");
    };

    const setMaxBalanceToken = async () => {
        document.getElementById('tokenAmountA').value = roundDown(selectedAssetBalance, 14).toFixed(14).replace(/\.?0+$/, "");
        calculateUSDVolume();
    };

    const setMaxBalanceUSD = async () => {
        document.getElementById('tokenAmountB').value = roundDown(USDCBalance, 6);
        calculateTokenVolume();
    };

    const setMaxBalanceLPToken = async () => {
        document.getElementById('LPTokenAmount').value = roundDown(selectedLPTokenBalance, 14).toFixed(14).replace(/\.?0+$/, "");
        calculateTokenOutput();
    };

    const setPercentOfBalanceLPToken = async (_percentage) => {
        let _roundFactor = 14;
        document.getElementById('LPTokenAmount').value = roundDown(selectedLPTokenBalance * _percentage / 100, _roundFactor).toFixed(_roundFactor).replace(/\.?0+$/, "");
        calculateTokenOutput();
    };

    const setPercentOfAsset = async (_percentage) => {
        let _roundFactor = 14;
        document.getElementById('tokenAmountA').value = roundDown(selectedAssetBalance * _percentage / 100, _roundFactor).toFixed(_roundFactor).replace(/\.?0+$/, "");
        calculateUSDVolume();
    };

    return {
        assets,
        filteredAssets,
        assetBalances,
        assetAddresses,
        chooseAssetModalOpen,
        settingsModalOpen,
        wrongSlippageInputMessage,
        highSlippageInputMessage,
        wrongTrxTimeInputMessage,
        filteredLPAssets,
        chooseLPTokenModalOpen,
        allowanceToken,
        allowanceUSDC,
        allowanceLPToken,
        selectedAssetAddress,
        selectedAsset,
        selectedAssetBalance,
        token0,
        selectedLPTokenBalance,
        selectedAssetPrice,
        approvalButtonTokenVisible,
        approvalButtonUSDCVisible,
        addLiquidityButtonVisible,
        removeLiquidityButtonVisible,
        buttonMessage,
        selectedLPToken,
        selectedLPTokenAddress,
        token1Ratio,
        token2Ratio,
        approvalButtonLPTokenVisible,
        selectedLPPairAddress,
        errorButtonVisible,
        expectedOutputVisible,
        tokenAmountA,
        tokenAmountB,
        selectedLPTokenAmount,
        estimatedOutput1,
        estimatedOutput2,
        amountADesired,
        amountBDesired,
        amountAMin,
        amountBMin,
        deadline,
        liquidityToRemove,
        showAddLiquidyPart,
        showRemoveLiquidyPart,
        style1,
        style2,
        USDCBalance,
        USDDecimals,
        stableCoinName,
        address,
        MarketRouter_Address,
        MarketRouter_ABI,
        ERC20_ABI,
        USDC_Address,
        outputNumber,
        web3_nm,
        slippage,
        trxTime,
        closeChooseAssetModal,
        openSettingsModal,
        closeSettingsModal,
        saveSettingsSlippage,
        saveSettingsTrxTime,
        filterAssets,
        openSelectAssetModal,
        openChooseLPTokenModal,
        closeChooseLPTokenModal,
        listLPTokens,
        listAssets,
        selectAsset,
        selectAssetInitial,
        selectLPToken,
        selectLPInitial,
        onSuccessApproveToken,
        onSuccessApproveLP,
        checkButtons,
        calculateUSDVolume,
        calculateTokenVolume,
        calculateTokenOutput,
        onSuccessAddLiquidity,
        calculateAddLiquidity,
        calculateRemoveLiquidity,
        onSuccessRemoveLiquidity,
        showRemoveLiquidity,
        showAddLiquidity,
        setMaxBalanceToken,
        setMaxBalanceUSD,
        setMaxBalanceLPToken,
        setPercentOfBalanceLPToken,
        setPercentOfAsset
    };
};

export default usePoolLogic;