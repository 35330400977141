import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext';
import '../../../styles/pointsHistory.css';

const PointsHistory = () => {
  const { address } = useAppContext();
  const [history, setHistory] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 15, // Default to 15 to match Leaderboard
    total: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputPage, setInputPage] = useState(1); // For "Go to page" input

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await fetch(
          `https://aapi.twinfinance.io/points-history?wallet=${address}&page=${pagination.page}&per_page=${pagination.per_page}`
        );

        if (!response.ok) throw new Error('Failed to fetch history');
        const data = await response.json();

        setHistory(data.history || []);
        setPagination(prev => ({
          ...prev,
          total: data.total_transactions || 0 // Ensure total is set
        }));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (address) {
      fetchHistory();
    }
  }, [address, pagination.page, pagination.per_page]);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(pagination.total / pagination.per_page) || 1;
    if (newPage < 1 || newPage > totalPages) return;
    setPagination(prev => ({ ...prev, page: newPage }));
    setInputPage(newPage); // Sync input with page
  };

  if (loading) {
    return <div className="loading-indicator">Loading history...</div>;
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  if (pagination.total === 0) {
    return null; // No history to display
  }

  return (
    <div className="points-history">
      <div className="table-wrapper">
        <table className="leaderboard-table"> {/* Use leaderboard-table class for consistency */}
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Points</th>
              <th scope="col">Description</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {history.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.transaction_type}</td>
                <td>{entry.points}</td>
                <td>{entry.description}</td>
                <td>{new Date(entry.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination-controls">
        <div className="per-page-group">
          <span>Show</span>
          <select 
            value={pagination.per_page} 
            onChange={(e) => setPagination(prev => ({ 
              ...prev, 
              per_page: parseInt(e.target.value), 
              page: 1 
            }))}
            className="per-page-select"
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
          <span>per page</span>
        </div>
        <div className="page-navigation">
          <button 
            onClick={() => handlePageChange(pagination.page - 1)} 
            disabled={pagination.page <= 1}
            className="pagination-button"
          >
            Previous
          </button>
          <input
            type="number"
            min="1"
            max={Math.ceil(pagination.total / pagination.per_page) || 1}
            value={inputPage}
            onChange={(e) => setInputPage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handlePageChange(parseInt(inputPage) || 1);
              }
            }}
            className="page-input"
          />
          <span>of {Math.ceil(pagination.total / pagination.per_page) || 1}</span>
          <button 
            onClick={() => handlePageChange(pagination.page + 1)} 
            disabled={pagination.page >= Math.ceil(pagination.total / pagination.per_page) || pagination.total === 0}
            className="pagination-button"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PointsHistory;