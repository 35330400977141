import React from 'react';
import { Modal } from 'react-bootstrap';
import AssetLogo from '../../Common/AssetLogo';

const AssetSelectorModal = ({
  show,
  onHide,
  assets,
  onSelectAsset,
  filterAssets,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select a token</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBodyScroll">
        <div className="row p-3 pr-3 my-auto">
          <input
            className="col w-100 searchField"
            id="search"
            placeholder="Search"
            onChange={filterAssets}
          />
        </div>
        <div className="list-group">
          {assets.map((asset) => (
            <div
              key={asset.key}
              className="assetSelectList"
              role="button"
              onClick={() => onSelectAsset(asset.symbol)}
            >
              <AssetLogo symbol={asset.symbol} />
              <div className="row">
                <div className="col-3"><b>{asset.symbol}</b></div>
                <div className="col text-right"><b>{asset.name}</b></div>
              </div>
              <div className="row">
                <div className="col">Balance: {asset.balance}</div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssetSelectorModal;