// src/components/Pages/Portfolio/usePortfolioLogic.js
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/AppContext.tsx';
import { MarketFactory_ABI, tokenLess } from '../../../config.js';
import { readContractData } from '../../../functions/Functions.jsx';

import { useQuery } from '@tanstack/react-query';

const usePortfolioLogic = () => {
  const {
    assetDetails,
    assets,
    blockchainDataLoaded,
    USDCBalance,
    stableCoinName,
    GovernanceTokenTotalBalance,
    ISSPrice,
    MarketFactory_nm,
    web3_nm,
    MarketPair_ABI,
    USDDecimals,
    assetValue,
    LPValue,
    pools,
    outputNumber,
    totalValue,
    USDC_Address,
    timeStampToDate,
    chainName,
    MarketFactory_Address,
    marketLive,
    loggedIn,
  } = useAppContext();

  const [portfolio, setPortfolio] = useState({});
  const [portfolioAssets, setPortfolioAssets] = useState([]);
  const [expandedAsset, setExpandedAsset] = useState({});
  const [expandedLPAsset, setExpandedLPAsset] = useState({});

  const fetchPortfolioData = async () => {
    let _portfolio = {};
    let _portfolioAssets = [];

    if (USDCBalance > 0.01) {
      _portfolio["USDC"] = {
        name: 'USDC',
        balance: USDCBalance,
        expiryDate: "",
        upperLimit: "",
        isFrozen: "",
        price: 1,
        portfolioValue: USDCBalance,
        apr: 0,
      };
      _portfolioAssets.push("USDC");
    }

    if (GovernanceTokenTotalBalance > 0.01 && !tokenLess) {
      _portfolio["TWIN"] = {
        name: "TWIN Protocol Token",
        balance: GovernanceTokenTotalBalance,
        expiryDate: "",
        upperLimit: "",
        isFrozen: "",
        price: ISSPrice,
        portfolioValue: ISSPrice * GovernanceTokenTotalBalance,
        marketPair: '0x5a50c8B092Da12Ed45660aa2164F450D5B25a4d8',
      };
      _portfolioAssets.push("TWIN");
    }

    if (assetDetails && assets && USDC_Address && MarketFactory_nm) {
      for (let i = 0; i < assets.length; ++i) {
        const asset = assets[i];

        if (parseFloat(assetDetails[asset]?.tokenBalance1) > 0.0000001) {
          let tokenPrice = 0
          let pair
          if (marketLive){  
            const tokenAddress = assetDetails[asset][0];
            pair = await readContractData(chainName, MarketFactory_Address, MarketFactory_ABI, 'getPair', [tokenAddress, USDC_Address]);
            const reserves = await readContractData(chainName, pair, MarketPair_ABI, 'getReserves', []);
            const token0 = await readContractData(chainName, pair, MarketPair_ABI, 'token0', []);
            tokenPrice = token0 === USDC_Address
              ? Number(reserves[0]) * (10 ** (18 - USDDecimals)) / Number(reserves[1])
              : Number(reserves[1]) / Number(reserves[0]) * (10 ** (18 - USDDecimals));
          }
          _portfolio[asset] = {
            name: assetDetails[asset].name,
            balance: assetDetails[asset].tokenBalance1,
            expiryDate: assetDetails[asset].expiryTime,
            upperLimit: assetDetails[asset].upperLimit,
            isFrozen: assetDetails[asset].frozen,
            price: tokenPrice,
            portfolioValue: parseFloat(assetDetails[asset].tokenBalance1) * tokenPrice,
            marketPair: pair,
            apr: assetDetails[asset].aprLong,
          };
          _portfolioAssets.push(asset);
        }

        if (parseFloat(assetDetails[asset]?.tokenBalance2) > 0.0000001) {
          let tokenPrice1 = 0
          let pair
          if (marketLive){  
            const tokenAddress = assetDetails[asset][1];
            pair = await readContractData(chainName, MarketFactory_Address, MarketFactory_ABI, 'getPair', [tokenAddress, USDC_Address]);
            const reserves = await readContractData(chainName, pair, MarketPair_ABI, 'getReserves', []);
            const token0 = await readContractData(chainName, pair, MarketPair_ABI, 'token0', []);
            tokenPrice1 = token0 === USDC_Address
              ? Number(reserves[0]) * (10 ** (18 - USDDecimals)) / Number(reserves[1])
              : Number(reserves[1]) / Number(reserves[0]) * (10 ** (18 - USDDecimals));
          }
          _portfolio["i" + asset] = {
            name: 'short ' + assetDetails[asset].name,
            balance: assetDetails[asset].tokenBalance2,
            expiryDate: assetDetails[asset].expiryTime,
            upperLimit: assetDetails[asset].upperLimit,
            isFrozen: assetDetails[asset].frozen,
            price: tokenPrice1,
            portfolioValue: parseFloat(assetDetails[asset].tokenBalance2) * tokenPrice1,
            marketPair: pair,
            apr: assetDetails[asset].aprShort,
          };
          _portfolioAssets.push("i" + asset);
        }
      }
    }

    return { portfolio: _portfolio, assets: _portfolioAssets };
  };

  const { data: portfolioData, isLoading: portfolioLoading } = useQuery({
    queryKey: ['portfolio', loggedIn, blockchainDataLoaded],
    queryFn: fetchPortfolioData,
    enabled: blockchainDataLoaded && loggedIn,
  });

  useEffect(() => {
    if (portfolioData) {
      setPortfolio(portfolioData.portfolio);
      setPortfolioAssets(portfolioData.assets);
    }
  }, [portfolioData]);

  const [lpTokens, setLpTokens] = useState([]);

  const loadLPData = () => {
    let _lpTokens = [];

    if (pools && pools.length > 0) {
      for (let i = 0; i < pools.length; i++) {
        const pool = pools[i];
        const share = (parseFloat(pool[4]) / parseFloat(pool[5])) * 100;

        if (share > 0.001) {
          _lpTokens.push({
            symbol: pool[0],
            name: pool[3],
            lpBalance: outputNumber(parseFloat(pool[4]) / 1e18, 8),
            withdrawableAssets: `${outputNumber((parseFloat(pool[4]) / parseFloat(pool[5])) * pool[8] / 1e18, 3)} ${pool[0]} & ${outputNumber((parseFloat(pool[4]) / parseFloat(pool[5]) * pool[2] * 0.5), 1)} ${stableCoinName}`,
            poolShare: `${share.toFixed(2)}%`,
            value: outputNumber((parseFloat(pool[4]) / parseFloat(pool[5]) * parseFloat(pool[2])), 0),
          });
        }
      }
    }

    setLpTokens(_lpTokens);
  };

  useEffect(() => {
    if (blockchainDataLoaded && loggedIn) {
      loadLPData();
    }
  }, [blockchainDataLoaded, loggedIn, pools]);

  const toggleExpansion = (asset) => {
    setExpandedAsset(prevState => ({
      ...prevState,
      [asset]: !prevState[asset],
    }));
  };

  const toggleLPExpansion = (asset) => {
    setExpandedLPAsset(prevState => ({
      ...prevState,
      [asset]: !prevState[asset],
    }));
  };

  return {
    portfolio,
    portfolioAssets,
    lpTokens,
    expandedAsset,
    expandedLPAsset,
    toggleExpansion,
    toggleLPExpansion,
    assetDetails,
    assets,
    blockchainDataLoaded,
    USDCBalance,
    stableCoinName,
    GovernanceTokenTotalBalance,
    ISSPrice,
    MarketFactory_nm,
    web3_nm,
    MarketPair_ABI,
    USDDecimals,
    assetValue,
    LPValue,
    pools,
    outputNumber,
    totalValue,
    USDC_Address,
    timeStampToDate,
    chainName,
    MarketFactory_Address,
    marketLive,
    loading: portfolioLoading,
    loggedIn,
  };
};

export default usePortfolioLogic;