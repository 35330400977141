// src/components/Pages/Factory/useFactoryLogic.js
import { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext';
import Tooltip from 'react-bootstrap/Tooltip';

const useFactoryLogic = () => {
  const {
    address,
    assetDetails,
    USDCBalance,
    assetFactory_nm,
    assetFactory_Address,
    web3_nm,
    outputNumber,
    updateAssetBalance,
    loadUSDBalance,
    ERC20_ABI,
    stableCoinName,
    assetFactory_ABI,
    USDDecimals,
    USDC_Address,
    USDC_nm,
    loggedIn // Added to destructure from useAppContext
  } = useAppContext();

  const [mintAmount, setMintAmount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState('Select an asset');
  const [errorButtonVisible, setErrorButtonVisible] = useState(false);
  const [errorButtonMessage, setErrorButtonMessage] = useState('');
  const [chooseAssetModalOpen, setChooseAssetModalOpen] = useState(false);
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [USDCAllowance, setUSDCAllowance] = useState(0);
  const [estimatedYield, setEstimatedYield] = useState(0);

  // Debug log to confirm loggedIn from context
  console.log('useFactoryLogic - loggedIn:', loggedIn);

  useEffect(() => {
    if (parseInt(USDCAllowance) > 0) {
      setErrorButtonVisible(false);
    }
    calculateYield();
  }, [USDCAllowance, selectedAsset, mintAmount, assetDetails]);

  const calculateYield = () => {
    if (selectedAsset === 'Select an asset' || !mintAmount || mintAmount <= 0) {
      setEstimatedYield(0);
      return;
    }
    const asset = assetDetails[selectedAsset];
    if (!asset) return;
    const upperLimit = Number(asset.upperLimit) / 1000;
    const totalMinted = (parseFloat(mintAmount) || 0) + (Number(asset.tokenBalance1) + Number(asset.tokenBalance2)) / 2;
    let totalUSDVolume = parseFloat(mintAmount) + asset.outstandingUSDVolumeLong * 2;
    let yieldPercentage = 100 * asset.rewardsAnnualized * 2 / totalUSDVolume;
    setEstimatedYield(yieldPercentage);
  };

  const openModal = async () => {
    if (parseInt(USDCAllowance) > 0) {
      setErrorButtonVisible(false);
    } else {
      setErrorButtonVisible(true);
    }
    const investmentAmount = document.getElementById('amountToStake').value;
    const assetDetails = await assetFactory_nm.methods.getAsset(selectedAsset).call();
    const upperLimit = Number(assetDetails.upperLimit) / 1000;
    const tokenAmount = investmentAmount / upperLimit;
    setModalOpen(true);
    setTokenAmount(tokenAmount);
  };

  const closeModal = () => setModalOpen(false);

  const onSuccessMint = async () => {
    await updateAssetBalance(selectedAsset);
    await loadUSDBalance();
    document.getElementById('amountToStake').value = 0;
    closeModal();
  };

  const onSuccessApprove = async () => {
    changeAmount();
  };

  const changeAmount = async () => {
    let input = document.getElementById('amountToStake').value;
    if (selectedAsset === 'Select an asset') {
      document.getElementById('amountToStake').value = '';
      return;
    }
    if (!input) {
      setMintAmount(0);
      return;
    }
    const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(input);
    if (!isPositiveNumber) {
      if (Number.isNaN(mintAmount) || typeof mintAmount === 'undefined') {
        document.getElementById('amountToStake').value = '';
      } else {
        document.getElementById('amountToStake').value = mintAmount;
      }
      return;
    }
    if (parseFloat(input) === 0) {
      return;
    }
    let allowance = await USDC_nm.methods.allowance(address, assetFactory_Address).call();
    setUSDCAllowance(parseInt(allowance));
    const amount = parseFloat(input);
    setMintAmount(amount > 0 ? amount : 0);

    if (amount > parseInt(USDCBalance)) {
      setErrorButtonVisible(true);
      setErrorButtonMessage('Balance too low');
    } else if (selectedAsset === 'Select an asset') {
      setErrorButtonVisible(true);
      setErrorButtonMessage('Select an asset');
    } else {
      setErrorButtonVisible(false);
    }
    calculateYield();
  };

  const openChooseAssetModal = () => {
    const assetsList = Object.keys(assetDetails)
      .filter(key => assetDetails[key].frozen === false && assetDetails[key].expiryTime > Date.now() / 1000)
      .map(key => [
        key,
        assetDetails[key]['tokenBalance1'],
        assetDetails[key]['tokenBalance2'],
        assetDetails[key]['name'],
        assetDetails[key]['upperLimit'],
      ]);
    setAssets(assetsList);
    setFilteredAssets(assetsList);
    setChooseAssetModalOpen(true);
  };

  const closeChooseAssetModal = () => setChooseAssetModalOpen(false);

  const filterAssets = () => {
    const searchTerm = document.getElementById('search').value.toLowerCase();
    const filtered = assets.filter(asset =>
      asset[3].toLowerCase().includes(searchTerm) || asset[0].toLowerCase().includes(searchTerm)
    );
    setFilteredAssets(filtered);
  };

  const renderTooltip1 = (props) => (
    <Tooltip {...props}>Yield varies based on total minted assets and market conditions</Tooltip>
  );

  const calculateAssetYield = (asset) => {
    const assetData = assetDetails[asset];
    if (!assetData) return 0;
    let yieldPercentage = (assetData['aprLong'] + assetData['aprShort']) * 100 / 2;
    return yieldPercentage.toFixed(1);
  };

  const selectAsset = (asset) => {
    setSelectedAsset(asset);
    closeChooseAssetModal();
    calculateYield();
  };

  const setMaxUSDAmount = () => {
    document.getElementById('amountToStake').value = USDCBalance;
    changeAmount();
  };

  return {
    mintAmount,
    modalOpen,
    selectedAsset,
    errorButtonVisible,
    errorButtonMessage,
    chooseAssetModalOpen,
    filteredAssets,
    tokenAmount,
    USDCAllowance,
    estimatedYield,
    openModal,
    closeModal,
    onSuccessMint,
    onSuccessApprove,
    changeAmount,
    openChooseAssetModal,
    closeChooseAssetModal,
    filterAssets,
    calculateAssetYield,
    selectAsset,
    setMaxUSDAmount,
    address,
    assetDetails,
    USDCBalance,
    assetFactory_nm,
    assetFactory_Address,
    web3_nm,
    outputNumber,
    updateAssetBalance,
    loadUSDBalance,
    ERC20_ABI,
    stableCoinName,
    assetFactory_ABI,
    USDDecimals,
    USDC_Address,
    USDC_nm,
    renderTooltip1,
    loggedIn // Added to return object
  };
};

export default useFactoryLogic;