import Web3 from 'web3';
import axios from 'axios';
import {ERC20_ABI,VotingEscrow_ABI,assetFactory_ABI,MarketPair_ABI,MarketFactory_ABI} from '../config/config';
import {USDC_Address_b, IDT_Address_b,assetFactory_Address_b,GovernanceToken_Address_b, MarketFactory_Address_b,DAO_Address_b,VotingEscrow_Address_b} from '../config';
import {tokenLess,APIBase, USDDecimals, assetNumber, GovernanceTokenSymbol } from '../config';
import {readContractData} from '../functions/Functions.jsx'

const marketLive = false

var _USDC_Address = USDC_Address_b

const AssetFactoryAddress = assetFactory_Address_b
const MarketFactoryAddress = MarketFactory_Address_b
var GovernanceTokenAddress
var DistributionTokenAddress
var VotingEscrowAddress
var DAOAddress
if (!tokenLess){
  GovernanceTokenAddress = GovernanceToken_Address_b
  DistributionTokenAddress = IDT_Address_b
  VotingEscrowAddress = VotingEscrow_Address_b
  DAOAddress = DAO_Address_b
}

async function fetchAssetDataFromAPI() {
  try {
    const response = await axios.get(`https://aapi.twinfinance.io/assetData`);
    return response.data;
  } catch (error) {
    console.error('Error fetching asset data from API:', error);
    return {};
  }
}

async function getWeb3Instance(chain="berachain"){
  let _web3
  if (chain === 'bartio'){
    try {
      _web3 = new Web3(new Web3.providers.HttpProvider('https://bartio.rpc.berachain.com/'));
    } catch (error) {
      console.error('Error initializing Web3 with bArtio:', error);
      try {
        _web3 = new Web3(new Web3.providers.HttpProvider('https://bartio.rpc.berachain.com/'));
      } catch (error) {
        console.error('Error initializing Web3 with Ankr:', error);
        return;
      }
    }
  }
  if (chain === 'berachain'){
    try {
      _web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.berachain.com/'));
    } catch (error) {
      console.error('Error initializing Web3 with Berachain:', error);
      try {
        _web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.berachain.com/'));
      } catch (error) {
        console.error('Error initializing Web3 with Second RPC:', error);
        return;
      }
    }
  }
  return _web3
}
async function getGovernanceTokenData(_address,chain='berachain'){
  if (tokenLess){
    return
  }
  var _governanceTokenData = {}
  let response
  try{response = await axios.get(APIBase+'/governance_token_data');}
  catch{response = {status:404}}
  console.log(response)
  if (response.status === 200){
    console.log("User data successfully fetched.")
    _governanceTokenData = response.data    
  }
  else {
    let web3_nm = await getWeb3Instance(chain)
     
    
    let _ISSSupplyWei = await readContractData(chain, GovernanceTokenAddress, ERC20_ABI, 'totalSupply', [])
    let _ISSSupply = parseFloat(web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'))
    let _ISSBalanceMultisigWei = await readContractData(chain, GovernanceTokenAddress, ERC20_ABI, 'balanceOf', ['0x0E4aaF1351de4c0264C5c7056Ef3777b41BD8e03'])
    let _ISSBalanceDeployerWei = await readContractData(chain, GovernanceTokenAddress, ERC20_ABI, 'balanceOf', ['0x7Dbc67df4d4ea21420B1BaA077028d2c1CCa7399'])
    let _ISSBalanceLockedWei = await readContractData(chain, GovernanceTokenAddress, ERC20_ABI, 'balanceOf', [VotingEscrowAddress])
    let _ISSBalanceDAOWei = await readContractData(chain, GovernanceTokenAddress, ERC20_ABI, 'balanceOf', [DAOAddress])
    let _ISSBalanceNotCirculatingWei = _ISSBalanceDeployerWei + _ISSBalanceMultisigWei + _ISSBalanceLockedWei + _ISSBalanceDAOWei
    let _ISSSupplyCurrentWei  = _ISSSupplyWei - _ISSBalanceNotCirculatingWei
    let _ISSSupplyCurrent = parseFloat(web3_nm.utils.fromWei(_ISSSupplyCurrentWei.toString(), 'ether'))

    let _totalVeISSSupplyWei = await readContractData(chain, VotingEscrowAddress, VotingEscrow_ABI, 'totalSupply', [])
    let _totalVeISSSupply = parseFloat(web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'))
    _governanceTokenData['GovernanceTokenCurrentSupply'] = _ISSSupplyCurrent
    _governanceTokenData['GovernanceTokenTotalSupply'] = _ISSSupply
    _governanceTokenData['TotalVeSupply'] = _totalVeISSSupply
  
  }
return (_governanceTokenData)
}

async function getUserData(_address,chain='berachain'){
  var _uData = {}
  let response
  try{response = await axios.get(APIBase+'/user_data?address='+_address);}
  catch{response = {status:404}}
  console.log(response)
  
  if (response.status === 200){
    console.log("User data successfully fetched.")
    _uData = response.data    
  }
  else {
    console.log("Henlo. API seems to be down. Going directly to blockchain")
    let web3_nm = await getWeb3Instance(chain)
    var _USDCBalanceWEI = await readContractData(chain, _USDC_Address, ERC20_ABI, 'balanceOf', [_address])
    console.log(_USDCBalanceWEI)
    let _USDCBalance = parseInt(_USDCBalanceWEI) / 10**parseInt(USDDecimals)
    _uData['StableCoinBalance'] = _USDCBalance
    _uData['address'] = _address
      
    if (!tokenLess){
      // Load the Government Token and IDT balance as well as the staked amaount
      let _DistributionTokenBalanceWei = await readContractData(chain, DistributionTokenAddress, ERC20_ABI, 'balanceOf', [_address])
      let _DistributionTokenBalance = parseFloat(web3_nm.utils.fromWei(_DistributionTokenBalanceWei.toString(), 'ether'))
      let _GovernanceTokenBalanceWei = await readContractData(chain, GovernanceTokenAddress, ERC20_ABI, 'balanceOf', [_address])
      let _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
      let _userData = await readContractData(chain, VotingEscrowAddress, VotingEscrow_ABI, 'userData', [_address])
      let _GovernanceTokenStakeBalanceWei = _userData['_lockedBalance']['amount']    
      let _GovernanceTokenStakeBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether'))
      let _veISSBalanceWei = _userData['_balanceVeISS']
      let _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'))
      let _lockDate = _userData['_lockedBalance']['end']
      _uData['DistributionTokenBalance'] = _DistributionTokenBalance
      _uData['GovernanceTokenBalance'] = _GovernanceTokenBalance
      _uData['lockTime'] = _lockDate
      _uData['lockedBalance'] = _GovernanceTokenStakeBalance
      _uData['veBalance'] = _veISSBalance
    }
  }
  
  return (_uData)
}

async function getPrice(tokenAddress, chain='berachain'){
  try{
    console.log(tokenAddress)
    let web3_nm = await getWeb3Instance(chain)
    console.log(web3_nm)
    let pair = await readContractData(chain, MarketFactoryAddress, MarketFactory_ABI, 'getPair', [tokenAddress, _USDC_Address])
    console.log(pair)
    let token0 = await readContractData(chain, pair, MarketPair_ABI, 'token0', [])
    let reserves = await readContractData(chain, pair, MarketPair_ABI, 'getReserves', [])
    
    
    let USDReserves;
    let price;
    let reservesToken
    let tvl

    if (token0 === _USDC_Address) {
      USDReserves = parseInt(reserves[0])/(10 ** USDDecimals) 
      reservesToken = parseInt(reserves[1])/1e18
      price = USDReserves /reservesToken
    }
    else{
      USDReserves = parseInt(reserves[1]) /(10 ** USDDecimals) 
      reservesToken = parseInt(reserves[0]) / 1e18
      price = USDReserves /reservesToken
    }
    tvl = 2 * USDReserves 
    if (isNaN(price)){
      price = 0.00
      tvl = 0
    }
    return([price,tvl,reservesToken,USDReserves])
  }
  catch{
    return([0,0,0,0])
  }
}

async function getTVL(tokenAddress, chain='berachain'){
  let token0 = await readContractData(chain, tokenAddress, MarketPair_ABI, 'token0', [])
  let reserves = await readContractData(chain, tokenAddress, MarketPair_ABI, 'getReserves', [])
  let USDReserves;
  let tvl

  if (token0 === _USDC_Address) {
    USDReserves = parseInt(reserves[0]) 
    tvl = USDReserves * 2 / 10**USDDecimals
  }
  else{
    USDReserves = parseInt(reserves[1]) 
    tvl = USDReserves * 2 / 10**USDDecimals
  }
  return(tvl)
}


async function getAssetData(chain = 'berachain') {
  var assetData = {};
  var _assetDetails = {};
  var _pools = [];
  let response;
  let maxYield = 0
  let highestYieldingPool = []
  var _mintedAssetValue = 0

  // Fetch the asset data from the new endpoint
  const apiAssetData = await fetchAssetDataFromAPI();
  console.log(apiAssetData)

  try {
    response = await axios.get(APIBase+'/asset_data?chain=' + chain);
  } catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetData = response.data;
    return ( [assetData[0], assetData[1]] )
  } else {
    console.log('Fetching asset data from blockchain.');

    let web3_nm = await getWeb3Instance(chain)
  

    try {
      let AssetFactoryContract = new web3_nm.eth.Contract(assetFactory_ABI, AssetFactoryAddress);
      let MarketFactoryContract = new web3_nm.eth.Contract(MarketFactory_ABI, MarketFactoryAddress);

      let _assets = [];
      let totalLockedValue = 0
      let newAsset;

      // Add the ISS Token to the pools
      if(!tokenLess) {
        let price = 0
        let tvl = 0
        let pair
        let totalSupply
        let reservesToken
        let reservesUSD
        if (marketLive){
          let priceandtvl = await getPrice(GovernanceTokenAddress);
          pair = await readContractData(chain, MarketFactoryAddress, MarketFactory_ABI, 'getPair', [GovernanceTokenAddress, _USDC_Address])
          totalSupply = await readContractData(chain, pair, MarketPair_ABI, 'totalSupply', [])
          console.log(totalSupply)
          price = priceandtvl[0] 
          tvl = priceandtvl[1] 
          reservesToken = priceandtvl[2]
          reservesUSD = priceandtvl[3]
        }
        
        _pools.push([
          'TWIN',
          pair,
          tvl,
          'TWIN Protocol Token',
          0,
          parseInt(totalSupply),
          0,
          reservesUSD,
          reservesToken,
          'n.a.',
          price,
          GovernanceTokenAddress
        ]);
        //console.log('TWIN token added to pools:', _pools[_pools.length - 1]);
      } 
      let beraPrice;
      try {
        const response = await axios.get('https://api.binance.com/api/v3/ticker/price?symbol=BERAUSDT');
        beraPrice = parseFloat(response.data.price); // Binance returns {"symbol":"BERAUSDT","price":"7.39"}
        console.log(`BERA Price fetched from Binance: $${beraPrice}`);
      } catch (error) {
        console.error('Error fetching BERA price from Binance:', error);
        beraPrice = 8; // Fallback to hardcoded value if API fails
      }
      let rewardsAnnualized = 4000 * 12 + 500 * beraPrice * 12
      for (let i = 0; i < assetNumber; i++) {
        try {
          newAsset = await readContractData(chain, AssetFactoryAddress, assetFactory_ABI, 'assets', [i])
          console.log('New asset fetched:', newAsset);

          _assets.push(newAsset);
          _assetDetails[newAsset] = await readContractData(chain, AssetFactoryAddress, assetFactory_ABI, 'getAsset', [newAsset])
          //console.log('Asset details:', _assetDetails[newAsset]);

          let tokenAddress = _assetDetails[newAsset]['Token1'];
            let pair = await readContractData(chain, MarketFactoryAddress, MarketFactory_ABI, 'getPair', [tokenAddress, _USDC_Address])
            let totalSupply = 0
            let price = parseInt(_assetDetails[newAsset]['upperLimit'])/1000/2
            let tvl = 0
            let reservesToken = 0
            let reservesUSD = 0
            try {
              totalSupply = await readContractData(chain, pair, MarketPair_ABI, 'totalSupply', [])
              let priceandtvl = await getPrice(tokenAddress);
              price = priceandtvl[0] 
              tvl = priceandtvl[1] 
              reservesToken = priceandtvl[2]
              reservesUSD = priceandtvl[3] 
            } catch (error) {
              console.error('Error fetching pair for Token1:', tokenAddress, error);
            }
            const assetInfo = apiAssetData[newAsset] && apiAssetData[newAsset][0] ? apiAssetData[newAsset][0] : { name: '', yahooCode: '' };
  
            // Calculating the APR
            let totalSupplyLongWEI = await readContractData(chain, tokenAddress, ERC20_ABI, 'totalSupply', [])
            let totalSupplyLong = parseFloat(web3_nm.utils.fromWei(totalSupplyLongWEI.toString(), 'ether'))
            let upperLimitAsset = _assetDetails[newAsset]['upperLimit']
            let outstandingUSDVolumeLong = totalSupplyLong * parseFloat(web3_nm.utils.fromWei(upperLimitAsset.toString(), 'kwei'))/2
            let assetAPRLong = rewardsAnnualized / outstandingUSDVolumeLong
            _assetDetails[newAsset]['aprLong'] = assetAPRLong;
            _assetDetails[newAsset]['outstandingUSDVolumeLong'] = outstandingUSDVolumeLong;
            _assetDetails[newAsset]['rewardsAnnualized'] = rewardsAnnualized;
            _assetDetails[newAsset]['name'] = assetInfo.name; // Add name to assetDetails
          
            _mintedAssetValue += outstandingUSDVolumeLong

            _pools.push([
              newAsset,
              pair,
              tvl,
              assetInfo.name, // pools[3] becomes the name from API
              0,
              parseInt(totalSupply),
              0,
              reservesUSD,
              reservesToken,
              _assetDetails[newAsset]['upperLimit'],
              price,
              tokenAddress,
              assetAPRLong,
              outstandingUSDVolumeLong
            ]);
            _assetDetails[newAsset]['priceLong'] = price;
            

            console.log('Token1 added to pools:', _pools[_pools.length - 1]);
          

          tokenAddress = _assetDetails[newAsset]['Token2'];
          pair = await readContractData(chain, MarketFactoryAddress, MarketFactory_ABI, 'getPair', [tokenAddress, _USDC_Address])
          totalSupply = 0
          price = parseInt(_assetDetails[newAsset]['upperLimit'])/1000/2
          tvl = 0
          reservesToken = 0
          reservesUSD = 0
            try {
              totalSupply = await readContractData(chain, pair, MarketPair_ABI, 'totalSupply', [])
              let priceandtvl = await getPrice(tokenAddress);
              price = priceandtvl[0] 
              tvl = priceandtvl[1]
              reservesToken = priceandtvl[2]
              reservesUSD = priceandtvl[3] 
            } catch (error) {
              console.error('Error fetching pair for Token2:', tokenAddress, error);
            }
            // Calculating the APR
            let totalSupplyShortWEI = await readContractData(chain, tokenAddress, ERC20_ABI, 'totalSupply', [])
            let totalSupplyShort = parseFloat(web3_nm.utils.fromWei(totalSupplyShortWEI.toString(), 'ether'))
            let outstandingUSDVolumeShort = totalSupplyShort * parseFloat(web3_nm.utils.fromWei(upperLimitAsset.toString(), 'kwei'))/2
            let assetAPRShort = rewardsAnnualized / outstandingUSDVolumeShort
            _assetDetails[newAsset]['aprShort'] = assetAPRShort;
            _assetDetails[newAsset]['outstandingUSDVolumeShort'] = outstandingUSDVolumeShort;
          

            _mintedAssetValue += outstandingUSDVolumeShort
            if (assetAPRLong > maxYield) {
              maxYield = assetAPRLong;
              highestYieldingPool = [newAsset, outstandingUSDVolumeShort + outstandingUSDVolumeLong, assetAPRShort]
            }

            _pools.push([
              'i'+newAsset,
              pair,
              tvl,
              'short '+assetInfo.name, // pools[3] becomes the name from API
              0,
              parseInt(totalSupply),
              0,
              reservesUSD,
              reservesToken,
              _assetDetails[newAsset]['upperLimit'],
              price,
              tokenAddress,
              assetAPRShort,
              outstandingUSDVolumeShort
            ]);
            _assetDetails[newAsset]['priceShort'] = price;
            
            
            
            console.log('Token2 added to pools:', _pools[_pools.length - 1]);
          

        } catch (error) {
          console.error('Error fetching asset:', i, error);
        }
      }
    } catch (error) {
      console.error('Error in fetching asset data from blockchain:', error);
    }
  }

  console.log('Final pools:', _pools);
  console.log('Final asset details:', _assetDetails);
  console.log ( [_pools, _assetDetails] )
  return ( [_pools, _assetDetails, highestYieldingPool,_mintedAssetValue] )
}

async function getAssetBalances(_address, chain = 'berachain') {
  
  console.log(_address)
  var response;
  var assetBalances = []
  try {
    response = await axios.get(APIBase+'/asset_balances?address='+_address);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  //console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetBalances = response.data;
  } 
  else {
    console.log('Fetching asset balances from blockchain.');
    let web3_nm = await getWeb3Instance(chain)
  
    for (let i = 0; i < assetNumber; i++) {
      console.log(i)
      let newAsset = await readContractData(chain, AssetFactoryAddress, assetFactory_ABI, 'assets', [i])
      console.log(newAsset)
      let _assetDetails =  await readContractData(chain, AssetFactoryAddress, assetFactory_ABI, 'getAsset', [newAsset])
      console.log(_assetDetails)
      let addressTokenLong = _assetDetails['Token1']
      let addressTokenShort = _assetDetails['Token2']
      //console.log(addressTokenLong)
      //console.log(addressTokenShort)
      //console.log(_address)
      let tokenBalanceLong = parseInt(await readContractData(chain, addressTokenLong, ERC20_ABI, 'balanceOf', [_address]))/1e18
      //console.log(tokenBalanceLong) 
      let tokenBalanceShort = parseInt(await readContractData(chain, addressTokenShort, ERC20_ABI, 'balanceOf', [_address]))/1e18
      //console.log(tokenBalanceShort) 
      assetBalances.push([newAsset,tokenBalanceLong,tokenBalanceShort])    
    }
    //console.log(assetBalances) 
  }
  console.log(assetBalances)
  return (assetBalances)
  
  
  
}

async function getLPBalances(_address, chain = 'berachain') {
  let web3_nm = await getWeb3Instance(chain)
  console.log(_address)
  var response;
  var lpBalances = {}
  try {
    response = await axios.get(APIBase+'/LP_balances?address='+_address);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  //console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    lpBalances = response.data;
    
  } 
  else {
    console.log('Fetching lp balances from blockchain.');
    
    // ADD ISS
    if (!tokenLess){
      let pair = await readContractData(chain, MarketFactoryAddress, MarketFactory_ABI, 'getPair', [GovernanceTokenAddress,_USDC_Address])
      let balanceWEI = await readContractData(chain, pair, MarketPair_ABI, 'balanceOf', [_address])
      let balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'))
      lpBalances['TWIN'] = balance 
    }
    for (let i = 0; i < assetNumber; i++) {
      
        let newAsset = await readContractData(chain, AssetFactoryAddress, assetFactory_ABI, 'assets', [i])
        let _assetDetails = await readContractData(chain, AssetFactoryAddress, assetFactory_ABI, 'getAsset', [newAsset])
        let addressTokenLong = _assetDetails['Token1']
        let addressTokenShort = _assetDetails['Token2']
        console.log(addressTokenShort)
        let balanceLong = 0
        let balanceShort = 0
        try {
          let pair = await readContractData(chain, MarketFactoryAddress, MarketFactory_ABI, 'getPair', [addressTokenLong,_USDC_Address])
          console.log(pair)
          let balanceLongWEI = await readContractData(chain, pair, MarketPair_ABI, 'balanceOf', [_address])
          balanceLong = parseFloat(web3_nm.utils.fromWei(balanceLongWEI.toString(), 'ether'))
          pair = await readContractData(chain, MarketFactoryAddress, MarketFactory_ABI, 'getPair', [addressTokenShort,_USDC_Address])
          console.log(pair)
          let balanceShortWEI = await readContractData(chain, pair, MarketPair_ABI, 'balanceOf', [_address])
          balanceShort = parseFloat(web3_nm.utils.fromWei(balanceShortWEI.toString(), 'ether'))
        
        }
        catch (error) {
          console.error('Error fetching pools:', error);
          
        }
        console.log(balanceLong)
        console.log(balanceShort)
        lpBalances[newAsset] = parseFloat(balanceLong) 
        lpBalances['i'+newAsset] = parseFloat(balanceShort)
      
      
       
    }
    
  }
  console.log(lpBalances)
  return (lpBalances)
  
  
  
}
async function getSingleAssetBalance(_address, _assetAddress, chain = 'berachain') {
  console.log("Debug")
  var response;
  var assetBalance = 0
  try {
    response = await axios.get(APIBase+'/assetBalance?address='+_address+'&assetAddress=' + _assetAddress);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetBalance = response.data;
  } 
  else {
    console.log('Fetching asset balance from blockchain.');
    assetBalance = parseInt(await readContractData(chain, _assetAddress, ERC20_ABI, 'balanceOf', [_address]))/1e18
  }
  return (assetBalance)
}

async function getAssetBalancesFromTicker(_address, _ticker, chain = 'berachain') {
  
  var response;
  var assetBalances = []
  try {
    response = await axios.get(APIBase+'/assetBalancesFromTicker?address='+_address+'&chain=' + chain+'&ticker=' + _ticker);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetBalances = response.data;
  } 
  else {
    console.log('Fetching asset balances from blockchain.');
    let web3_nm = await getWeb3Instance(chain)
    if (_ticker === GovernanceTokenSymbol){
      let tokenBalanceLong = parseInt(await readContractData(chain, GovernanceTokenAddress, ERC20_ABI, 'balanceOf', [_address]))/1e18
      return ([tokenBalanceLong,"nm"])   
    }
    let _assetDetails = await readContractData(chain, AssetFactoryAddress, assetFactory_ABI, 'getAsset', [_ticker])
    let addressTokenLong = _assetDetails['Token1']
    let addressTokenShort = _assetDetails['Token2']
    let tokenBalanceLong = parseInt(await readContractData(chain, addressTokenLong, ERC20_ABI, 'balanceOf', [_address]))/1e18
    let tokenBalanceShort = parseInt(await readContractData(chain, addressTokenShort, ERC20_ABI, 'balanceOf', [_address]))/1e18
    assetBalances = [tokenBalanceLong,tokenBalanceShort]  
  }
  return (assetBalances)
  
  
  
}
async function getLPBalancesFromTicker(_address, _ticker, chain = 'berachain') {
  
  var response;
  var lpBalances = []
  try {
    response = await axios.get(APIBase+'/assetBalancesFromTicker?address='+_address+'&chain=' + chain+'&ticker=' + _ticker);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  
  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    lpBalances = response.data;
  } 
  else {
    console.log('Fetching asset balances from blockchain.');
    if (_ticker === GovernanceTokenSymbol){
      let tokenBalanceLong = parseInt(await readContractData(chain, GovernanceTokenAddress, ERC20_ABI, 'balanceOf', [_address]))/1e18
      return ([tokenBalanceLong,"nm"])   
    }
    let _assetDetails = await readContractData(chain, AssetFactoryAddress, assetFactory_ABI, 'getAsset', [_ticker])
    let addressTokenLong = _assetDetails['Token1']
    let addressTokenShort = _assetDetails['Token2']
    let pair = await readContractData(chain, MarketFactoryAddress, MarketFactory_ABI, 'getPair', [addressTokenLong,_USDC_Address])
    let lpBalanceLong = parseInt(await readContractData(chain, pair, MarketPair_ABI, 'balanceOf', [_address]))/1e18
    pair =  await readContractData(chain, MarketFactoryAddress, MarketFactory_ABI, 'getPair', [addressTokenShort,_USDC_Address])
    let lpBalanceShort = parseInt(await readContractData(chain, pair, MarketPair_ABI, 'balanceOf', [_address]))/1e18
    lpBalances = [lpBalanceLong,lpBalanceShort]  
  }
  return (lpBalances)
  
  
  
}



export {getUserData,getGovernanceTokenData,getAssetData,getPrice,getAssetBalances,getLPBalances,getSingleAssetBalance,getAssetBalancesFromTicker,getLPBalancesFromTicker,getWeb3Instance};
