import React from 'react';
import { useAppContext } from '../../../context/AppContext';
import '../../../styles/socialShare.css';

const SocialShare = () => {
  const { myRefCode } = useAppContext();

  const shareOnX = () => {
    if (!myRefCode) return;
    
    const text = `Join me on @TwinFinance and earn rewards together! 🚀
Use my referral code: ${myRefCode}
👉  https://app.twinfinance.io?refCode=${myRefCode}`;

    window.open(
      `https://twitter.com/intent/post?text=${encodeURIComponent(text)}`,
      '_blank',
      'width=550,height=420'
    );
  };

  return myRefCode ? (
    <button 
      onClick={shareOnX}
      className="action-button share" /* Use action-button class for consistency */
    >
      <span className="x-icon">X</span> Share on X
    </button>
  ) : null;
};

export default SocialShare;