import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAppContext } from '../../../context/AppContext';
import '../../../styles/leaderboard.css';

const Leaderboard = () => {
  const { myRefCode } = useAppContext();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 15,
    total: 0 // Will be updated from API
  });
  const [epochFilter, setEpochFilter] = useState('all');
  const [inputPage, setInputPage] = useState(pagination.page);

  useEffect(() => {
    setInputPage(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    setPagination(prev => ({ ...prev, page: 1 }));
  }, [epochFilter, pagination.per_page]);

  const fetchLeaderboard = async () => {
    try {
      setLoading(true);
      setError('');
      const params = {
        page: pagination.page,
        per_page: pagination.per_page,
        epoch: epochFilter === 'all' ? undefined : epochFilter
      };
      const response = await axios.get('https://aapi.twinfinance.io/leaderboard1', { params });
      setLeaderboardData(response.data.leaderboard || []);
      // Fallback to calculate total if API doesn't provide total_count
      const total = response.data.total_count || (response.data.leaderboard?.length || 0);
      setPagination(prev => ({
        ...prev,
        total: total
      }));
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to load leaderboard');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, [pagination.page, pagination.per_page, epochFilter]);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(pagination.total / pagination.per_page) || 1;
    if (newPage < 1 || newPage > totalPages) return;
    setPagination(prev => ({ ...prev, page: newPage }));
  };

  // Truncate address for display (e.g., "0x123...456")
  const truncateAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <div className="leaderboard-container">
      <div className="controls">
        <div className="filter-group">
          <span>Epoch:</span>
          <select 
            value={epochFilter} 
            onChange={(e) => setEpochFilter(e.target.value)}
            className="epoch-select"
          >
            <option value="current">Current Epoch</option>
            <option value="all">All Time</option>
          </select>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
      {loading ? (
        <div className="loading-indicator">Loading...</div>
      ) : (
        <>
          <div className="table-wrapper">
            <table className="leaderboard-table">
              <thead>
                <tr>
                  <th scope="col">Rank</th>
                  <th scope="col">User</th>
                  <th scope="col">Points</th>
                  {epochFilter !== 'all' && <th scope="col">Epoch</th>}
                </tr>
              </thead>
              <tbody>
                {leaderboardData.map((entry, index) => (
                  <tr key={`${entry.address}-${index}`}>
                    <td className={`rank-${entry.rank}`}>
                      {entry.rank}
                      {entry.rank === 1 && <span className="rank-emoji">🥇</span>}
                      {entry.rank === 2 && <span className="rank-emoji">🥈</span>}
                      {entry.rank === 3 && <span className="rank-emoji">🥉</span>}
                    </td>
                    <td className="user-cell">
                      {entry.discordName ? (
                        entry.discordName
                      ) : (
                        <span 
                          className="address" 
                          title={entry.address} // Tooltip for full address
                        >
                          {truncateAddress(entry.address)}
                        </span>
                      )}
                    </td>
                    <td>{entry.points.toLocaleString()}</td>
                    {epochFilter !== 'all' && <td>{entry.epoch}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-controls">
            <div className="per-page-group">
              <span>Show</span>
              <select 
                value={pagination.per_page} 
                onChange={(e) => setPagination(prev => ({ 
                  ...prev, 
                  per_page: parseInt(e.target.value), 
                  page: 1 
                }))}
                className="per-page-select"
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
              <span>per page</span>
            </div>
            <div className="page-navigation">
              <button 
                onClick={() => handlePageChange(pagination.page - 1)} 
                disabled={pagination.page <= 1}
                className="pagination-button"
              >
                Previous
              </button>
              <input
                type="number"
                min="1"
                max={Math.ceil(pagination.total / pagination.per_page) || 1}
                value={inputPage}
                onChange={(e) => setInputPage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handlePageChange(parseInt(inputPage) || 1);
                  }
                }}
                className="page-input"
              />
              <span>of {Math.ceil(pagination.total / pagination.per_page) || 1}</span>
              <button 
                onClick={() => handlePageChange(pagination.page + 1)} 
                disabled={pagination.page >= Math.ceil(pagination.total / pagination.per_page) || pagination.total === 0}
                className="pagination-button"
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Leaderboard;