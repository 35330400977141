import React from 'react';
import { useAppContext } from '../context/AppContext';
import { Modal } from "react-bootstrap";
import Mainpage from './Pages/Mainpage/Mainpage';
import Navbar from './Pages/Navbar/Navbar';
import Footer from './Pages/Footer/Footer'; 
import Factory from './Pages/Factory/Factory';
import Portfolio from './Pages/Portfolio/Portfolio';
import BurnAssets from './Pages/BurnAssets/BurnAssets';
import MessageBox from './Common/MessageBox';
import GovernanceTokenPage from './Pages/TWIN/TWIN';
import Market from './Pages/Market/Market';
import Pool from './Pages/Pool/Pool';
import Governance from './Pages/Governance/Governance';
import PointsDashboard from './Pages/Points/PointsDashboard';
import TWIND from './Pages/TWIND/TWIND';
import SidebarElement from './Pages/Sidebar/Sidebar';
import ChangeChain from './Common/ChangeChain';
import GeoBlock from './Common/GeoBlock';
import TOU from './Common/TOU';
import TOUModal from './Common/TOUModal';
import PrivacyPolicy from './Common/PrivacyPolicy';
import { Routes, Route, useLocation } from 'react-router-dom'; // Import useLocation
import logo from '../img/graphics/TWIN_Logo_loading.png';

const Desktop = () => {
  const {
    fullProtocolIntegration,
    loggedIn,
    pageLoadedMainpage,
    messageBoxVisible,
    messageBoxContent,
    messageBoxHeader,
    messageBoxFooter,
    loadingBlockchainData,
    wrongNetworkMessage,
    geoLocationOk,
    showTerms
  } = useAppContext();

  // Get the current pathname
  const location = useLocation();
  
  // Define paths where TOUModal should not be shown
  const excludedPaths = ['/TOU', '/Privacy']; // Add more paths as needed
  
  // Show TOUModal only if showTerms is true and current path is not in excludedPaths
  const shouldShowTOUModal = showTerms && !excludedPaths.includes(location.pathname);

  return (
    <div className="mainArea">
      <div className="backgroundImageNew">
        <div className="layer">
          {shouldShowTOUModal ? <TOUModal /> : null} {/* Updated condition */}
          <SidebarElement />
          <div className="w-100 m-0 p-0">
            <Navbar />
            <div className="contentContainer">
              {geoLocationOk ? (
                <Routes>
                  <Route
                    path="/"
                    element={
                      pageLoadedMainpage ? (
                        <Mainpage />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/portfolio"
                    element={
                      pageLoadedMainpage ? (
                        <Portfolio />
                      ) : loggedIn && !fullProtocolIntegration ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/pool/*"
                    element={
                      pageLoadedMainpage ? (
                        <Pool />
                      ) : loggedIn ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/mint"
                    element={
                      pageLoadedMainpage ? (
                        <Factory />
                      ) : loggedIn ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/trade/*"
                    element={
                      pageLoadedMainpage ? (
                        <Market />
                      ) : loggedIn ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/redeem"
                    element={
                      pageLoadedMainpage ? (
                        <BurnAssets />
                      ) : loggedIn ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/TWIN"
                    element={
                      pageLoadedMainpage ? (
                        <GovernanceTokenPage />
                      ) : loggedIn ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/TWIND"
                    element={
                      pageLoadedMainpage ? (
                        <TWIND />
                      ) : loggedIn ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/governance"
                    element={
                      pageLoadedMainpage ? (
                        <Governance />
                      ) : loggedIn ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route
                    path="/points"
                    element={
                      pageLoadedMainpage ? (
                        <PointsDashboard />
                      ) : loggedIn ? (
                        <ChangeChain />
                      ) : (
                        ''
                      )
                    }
                  />
                  <Route path="/TOU" element={<TOU />} />
                  <Route path="/Privacy" element={<PrivacyPolicy />} />
                </Routes>
              ) : (
                <GeoBlock />
              )}
              <Footer />
            </div>
            {messageBoxVisible ? (
              <MessageBox content={messageBoxContent} header={messageBoxHeader} footer={messageBoxFooter} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <Modal dialogClassName="loadingBlockchainModalOuter" show={loadingBlockchainData} centered>
        <div className="loadingBlockchainModal">
          <div className="row">
            <div className="col-12 center pt-4">
              <img className="center" src={logo} alt="Logo" width="" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 h5 text-center">
              <div className="spinner-border text-center mt-4" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <div className="col-12 pt-2 pb-3 mr-3 text-center">
              Loading Blockchain data...
            </div>
          </div>
        </div>
      </Modal>

      <Modal className="rounded" show={wrongNetworkMessage}>
        <div className="p-3 bg-dark text-light border border-accent rounded">
          <div className="row m-4"></div>
          <div className="row">
            <div className="col text-center">
              <p>Wrong Network selected.</p>
              Please switch to Berachain or Berachain Artio Testnet.
            </div>
          </div>
          <div className="row m-4"></div>
        </div>
      </Modal>
    </div>
  );
};

export default Desktop;