// src/components/Pages/BurnAssets/MobileBurnAssets.jsx
import '../../../styles/burnAssets.css';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { TransactionButton } from '../../../functions/Trx';
import AssetLogo from '../../Common/AssetLogo';
import useBurnAssetsLogic from './useBurnAssetsLogic';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';

const MobileBurnAssets = () => {
  const {
    view,
    style1,
    style2,
    selectedAsset,
    selectedAssetExpired,
    selectedAssetBurnableBalance,
    burnAmount,
    amountToBurnLong,
    amountToBurnShort,
    amountToGet,
    amountToGetExpired,
    tokenToBurn,
    allowance,
    allowance2,
    submitButtonDeactivated,
    submitButtonDeactivatedMessage,
    chooseAssetModalOpen,
    chooseExpiredAssetModalOpen,
    modalOpen,
    modalBurnExpiredAssetsOpen,
    filteredAssetBurnOptions,
    filteredAssetBurnOptionsExpired,
    burnableBalanceToken1,
    burnableBalanceToken2,
    selectLiveView,
    selectExpiredView,
    openModal,
    closeModal,
    openModalBurnExpiredAssets,
    closeModalBurnExpiredAssets,
    openChooseAssetModal,
    closeChooseAssetModal,
    openChooseExpiredAssetModal,
    closeChooseExpiredAssetModal,
    selectAssetLive,
    selectAssetExpired,
    checkInputLive,
    checkInputExpired,
    setMaxAmount,
    setMaxAmountToken1,
    setMaxAmountToken2,
    filterAssets,
    onSuccessApprove,
    onSuccessBurn,
    stableCoinName,
    assetFactory_ABI,
    assetFactory_Address,
    ERC20_ABI,
    assetDetails,
    outputNumber,
    web3_nm,
    loggedIn
  } = useBurnAssetsLogic();

  const { open } = useWeb3Modal();
  const account = useAccount();
  const address = account.address;
  const [isConnecting, setIsConnecting] = useState(false);

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 4)}...${address.substring(address.length - 5)}`;
  };

  const addressShort = shortenAddress(address);

  const handleConnect = async () => {
    setIsConnecting(true);
    await open();
  };

  useEffect(() => {
    if (address) {
      setIsConnecting(false); // Stop showing "Connecting..." once connected
    }
  }, [address]);

  // Debug log to track state changes
  useEffect(() => {
    console.log('MobileBurnAssets - address:', address, 'loggedIn:', loggedIn, 'isConnecting:', isConnecting);
  }, [address, loggedIn, isConnecting]);

  const listBurnableAssets = () =>
    filteredAssetBurnOptions.map((element, index) => (
      <div key={index} className="assetSelectList" role="button" onClick={() => selectAssetLive(element[0])}>
        <div className="row verticallyCentered">
          <div className="col-4">
            <AssetLogo symbol={element[0]} /> <b>{element[0]}</b>
          </div>
          <div className="col text-right">
            <b>{element[2]}</b>
          </div>
        </div>
        <div className="row">
          <div className="col">Burnable balance: {outputNumber(element[1], 4)}</div>
          <div className="col text-right">UL: {outputNumber(Number(element[3]) / 1000, 0)}</div>
        </div>
      </div>
    ));

  const listBurnableExpiredAssets = () =>
    filteredAssetBurnOptionsExpired.map((element, index) => (
      <div key={index} className="assetSelectList" role="button" onClick={() => selectAssetExpired(element[0])}>
        <div className="row">
          <div className="col-3">
            <b>{element[0]}</b>
          </div>
          <div className="col text-right">
            <b>{element[3]}</b>
          </div>
        </div>
        <div className="row">
          <div className="col">
            Burnable balance: {outputNumber(element[1], 7)}(long) / {outputNumber(element[2], 7)}(short)
          </div>
          <div className="col text-right">UL: {outputNumber(element[4] / 1000, 0)}</div>
        </div>
      </div>
    ));

  const tooltip1 = (props) => (
    <Tooltip {...props}>
      You can "burn" TWIN Asset Tokens and redeem those against {stableCoinName} stable coins at any time, if you provide
      an equal amount of long and short tokens (from your liquid portfolio positions). The amount of {stableCoinName}{' '}
      stable coins you will receive is the (equal) amount of long and short tokens burned multiplied by the upper limit
      of the respective TWIN Asset minus a 2% early redemption fee.
    </Tooltip>
  );

  const tooltip2 = (props) => (
    <Tooltip {...props}>
      When an asset is expired, you can burn your long and short token balance also after the expiry date and redeem{' '}
      {stableCoinName} stable coins. The amount of {stableCoinName} stable coins you will receive is the amount of long
      tokens multiplied with the asset price at expiry time plus the amount of short tokens multiplied with the
      difference of the upper limit of the asset and the asset price at expiry.
    </Tooltip>
  );

  return (
    <div className="mainContainerMobile">
      <Modal show={chooseAssetModalOpen} onHide={closeChooseAssetModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select TWIN Asset pair to burn</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <div className="row p-3 pr-3 my-auto">
            <input className="col w-100 searchField" id="search" placeholder="Search" onChange={filterAssets} />
          </div>
          <div className="list-group">{listBurnableAssets()}</div>
        </Modal.Body>
      </Modal>

      <Modal show={chooseExpiredAssetModalOpen} onHide={closeChooseExpiredAssetModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select TWIN Asset pair to burn</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <div className="row p-3 pr-3 my-auto">
            <input className="col w-100 searchField" id="search" placeholder="Search" onChange={filterAssets} />
          </div>
          <ul className="list-group">{listBurnableExpiredAssets()}</ul>
        </Modal.Body>
      </Modal>

      <Modal show={modalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Burn assets and redeem {stableCoinName}:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will burn {burnAmount} {tokenToBurn} and i{tokenToBurn} token.
        </Modal.Body>
        <Modal.Body>You will receive {outputNumber(amountToGet * 0.98, 2)} {stableCoinName}</Modal.Body>
        <Modal.Footer>
          <TransactionButton
            abi={assetFactory_ABI}
            contractAddress={assetFactory_Address}
            functionName="burnAssets"
            args={[tokenToBurn, burnAmount * 1e18]}
            text="Burn"
            onSuccess={onSuccessBurn}
            gasLimit={6000000}
          />
        </Modal.Footer>
      </Modal>

      <Modal show={modalBurnExpiredAssetsOpen} onHide={closeModalBurnExpiredAssets}>
        <Modal.Header closeButton>
          <Modal.Title>Burn assets and redeem {stableCoinName}:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will burn {amountToBurnLong} {tokenToBurn} and {amountToBurnShort} i{tokenToBurn} token.
        </Modal.Body>
        <Modal.Body>You will receive {outputNumber(amountToGetExpired, 2)} {stableCoinName}</Modal.Body>
        <Modal.Footer>
          <TransactionButton
            abi={assetFactory_ABI}
            contractAddress={assetFactory_Address}
            functionName="burnAssets"
            args={[tokenToBurn, amountToBurnLong * 1e18, amountToBurnShort * 1e18]}
            text="Burn"
            onSuccess={onSuccessBurn}
            gasLimit={6000000}
          />
        </Modal.Footer>
      </Modal>

      <div className="middleBoxSmallMobile">
        <div className="mainBox">
          <div className="selectButtonRow">
            <div className={style1} role="button" onClick={selectLiveView}>
              Live <OverlayTrigger placement="right" overlay={tooltip1}><InfoOutlinedIcon /></OverlayTrigger>
            </div>
            <div className={style2} role="button" onClick={selectExpiredView}>
              Expired <OverlayTrigger placement="right" overlay={tooltip2}><InfoOutlinedIcon /></OverlayTrigger>
            </div>
          </div>
          <div className="redeemHeadline">Select a TWIN asset pair to redeem</div>

          {view === 'live' ? (
            <div className="subBox">
              <div className="col align-self-end text-lg-right">
                {selectedAssetBurnableBalance > 0 && (
                  <div role="button">
                    Balance available:{' '}
                    <span className="tradeBalance textBalance" onClick={setMaxAmount}>
                      {outputNumber(parseInt(selectedAssetBurnableBalance) / 1e18, 8)} (Max)
                    </span>
                  </div>
                )}
              </div>
              <div className="tradingBoxRow">
                <div>
                  <input
                    placeholder="0.00"
                    className="inputCustomMobile"
                    type="text"
                    id="amountToBurn"
                    onChange={checkInputLive}
                    disabled={!loggedIn}
                  />
                </div>
                <div className="selectAssetButtonMobile" type="button" onClick={openChooseAssetModal}>
                  <div className="assetBoxIcon">
                    <AssetLogo symbol={selectedAsset} />
                  </div>
                  <div>
                    {selectedAsset} <KeyboardArrowDownOutlinedIcon />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col align-self-start py-4">
                  {loggedIn ? (
                    <>
                      {burnAmount > allowance && selectedAsset !== 'Select Asset' && (
                        <TransactionButton
                          contractAddress={assetDetails[selectedAsset][0]}
                          abi={ERC20_ABI}
                          functionName="approve"
                          args={[assetFactory_Address, web3_nm.utils.toBigInt(2 ** 255)]}
                          text="Approve Long Token"
                          onSuccess={onSuccessApprove}
                        />
                      )}
                      {burnAmount > allowance2 && selectedAsset !== 'Select Asset' && (
                        <TransactionButton
                          contractAddress={assetDetails[selectedAsset][1]}
                          abi={ERC20_ABI}
                          functionName="approve"
                          args={[assetFactory_Address, web3_nm.utils.toBigInt(2 ** 255)]}
                          text="Approve Short Token"
                          onSuccess={onSuccessApprove}
                        />
                      )}
                      {submitButtonDeactivated && burnAmount < allowance && burnAmount < allowance2 ? (
                        <div className="issuaaButtonDeactivated">{submitButtonDeactivatedMessage}</div>
                      ) : null}
                      {!submitButtonDeactivated && burnAmount < allowance && burnAmount < allowance2 && (
                        <div role="button" className="issuaaButton" onClick={openModal}>
                          Submit
                        </div>
                      )}
                    </>
                  ) : (
                    <div role="button" className="issuaaButton" onClick={handleConnect}>
                      {isConnecting ? (
                        <span>
                          <div className="spinner-border spinner-border-sm spinner-border-slow" role="status" />
                          Connecting...
                        </span>
                      ) : address ? (
                        <span>{addressShort}</span>
                      ) : (
                        <span>Connect Wallet</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="subBox">
              <div className="tradingBoxRow">
                <div role="button" className="selectAssetButtonMobile w-100" onClick={openChooseExpiredAssetModal}>
                  <div>
                    {selectedAssetExpired} <KeyboardArrowDownOutlinedIcon />
                  </div>
                </div>
              </div>
              <div className="row mt-4 px-4">
                <div className="col align-self-end text-lg-right textBalance">
                  {burnableBalanceToken1 > 0 && (
                    <div>
                      Balance available to burn:{' '}
                      <span role="button" onClick={setMaxAmountToken1}>
                        {outputNumber(parseInt(burnableBalanceToken1) / 1e18, 8)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {selectedAssetExpired !== 'Select Asset' && (
                <div className="row px-4">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <label className="input-group-text" htmlFor="amountToBurnToken1">
                        Amount to convert {selectedAssetExpired}:
                      </label>
                    </div>
                    <input
                      className="form-control bg-innerBox"
                      onChange={checkInputExpired}
                      type="number"
                      id="amountToBurnToken1"
                      defaultValue={0}
                      disabled={!loggedIn}
                    />
                  </div>
                </div>
              )}
              <div className="row px-4">
                <div className="col align-self-end text-lg-right textBalance">
                  {burnableBalanceToken2 > 0 && (
                    <div>
                      Balance available to burn:{' '}
                      <span role="button" onClick={setMaxAmountToken2}>
                        {outputNumber(parseInt(burnableBalanceToken2) / 1e18, 8)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {selectedAssetExpired !== 'Select Asset' && (
                <div className="row px-4">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <label className="input-group-text" htmlFor="amountToBurnToken2">
                        Amount to convert i{selectedAssetExpired}:
                      </label>
                    </div>
                    <input
                      className="form-control bg-innerBox"
                      onChange={checkInputExpired}
                      type="number"
                      id="amountToBurnToken2"
                      defaultValue={0}
                      disabled={!loggedIn}
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col align-self-start py-4">
                  {loggedIn ? (
                    <>
                      {amountToBurnLong > allowance && selectedAssetExpired !== 'Select Asset' && (
                        <div id="buttonRounded">
                          <TransactionButton
                            contractAddress={assetDetails[selectedAssetExpired][0]}
                            abi={ERC20_ABI}
                            functionName="approve"
                            args={[assetFactory_Address, web3_nm.utils.toBigInt(2 ** 255)]}
                            text="Approve Long"
                            onSuccess={onSuccessApprove}
                          />
                        </div>
                      )}
                      {amountToBurnShort > allowance2 && selectedAssetExpired !== 'Select Asset' && (
                        <div id="buttonRounded">
                          <TransactionButton
                            contractAddress={assetDetails[selectedAssetExpired][1]}
                            abi={ERC20_ABI}
                            functionName="approve"
                            args={[assetFactory_Address, web3_nm.utils.toBigInt(2 ** 255)]}
                            text="Approve Short Token"
                            onSuccess={onSuccessApprove}
                          />
                        </div>
                      )}
                      {submitButtonDeactivated ? (
                        <div className="issuaaButtonDeactivated">{submitButtonDeactivatedMessage}</div>
                      ) : null}
                      {!submitButtonDeactivated && amountToBurnLong < allowance && amountToBurnShort < allowance2 && (
                        <div role="button" className="issuaaButton" onClick={openModalBurnExpiredAssets}>
                          Submit
                        </div>
                      )}
                    </>
                  ) : (
                    <div role="button" className="issuaaButton" onClick={handleConnect}>
                      {isConnecting ? (
                        <span>
                          <div className="spinner-border spinner-border-sm spinner-border-slow" role="status" />
                          Connecting...
                        </span>
                      ) : address ? (
                        <span>{addressShort}</span>
                      ) : (
                        <span>Connect Wallet</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileBurnAssets;