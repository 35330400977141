// src/components/Pages/Mainpage/MobileMainpage.jsx
import '../../../styles/mobileMainpage.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AssetLogo from '../../Common/AssetLogo';
import useMainpageLogic from './useMainpageLogic';

const MobileMainpage = () => {
  const {
    assets,
    outputNumber,
    ISSPrice,
    totalLockedValue,
    ISSMarketCapCurrent,
    marketLive,
    highestYieldingAsset,
    mintedAssetVolume,
    poolOutput,
  } = useMainpageLogic();

  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // Filter pools based on search term
  const filteredPools = poolOutput
    ? poolOutput.filter(
        (pool) =>
          pool.symbol.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          pool.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
    : [];

  // Sort the filtered pools
  const sortedPools = [...filteredPools].sort((a, b) => {
    if (!sortColumn) return 0;

    let valueA, valueB;
    switch (sortColumn) {
      case 'name':
        valueA = a.name.toLowerCase();
        valueB = b.name.toLowerCase();
        return sortDirection === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      case 'tvl':
        valueA = marketLive ? parseFloat(a.poolLiquidityUSD || 0) : parseFloat(a.yourStake || 0);
        valueB = marketLive ? parseFloat(b.poolLiquidityUSD || 0) : parseFloat(b.yourStake || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      case 'yield':
        if (marketLive) return 0;
        valueA = parseFloat(a.apr || 0);
        valueB = parseFloat(b.apr || 0);
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      default:
        return 0;
    }
  });

  // Handle sort click
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Render sort indicator (always show, style based on active sort)
  const renderSortIndicator = (column) => {
    const isActive = sortColumn === column;
    const isAsc = sortDirection === 'asc';
    return isActive && !isAsc ? (
      <ArrowDownwardIcon
        fontSize="small"
        className={`sortIconMobile ${isActive ? 'sortIconActiveMobile' : ''}`}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        className={`sortIconMobile ${isActive ? 'sortIconActiveMobile' : ''}`}
      />
    );
  };

  return (
    <div className="mainContainer">
      <div className="middleBoxFullMobile">
        <div className="">
          <div className="infoBoxesMobile">
            {false && (
              <div className="infoBoxMobile">
                <div className="infoBoxMobileLeft">
                  <div className="infoBoxItemMobile">
                    ${outputNumber(parseFloat(ISSPrice), 3) > 0 ? outputNumber(parseFloat(ISSPrice), 3) : <span>...</span>}
                  </div>
                  <div className="infoBoxItem2Mobile">TWIN Price</div>
                </div>
                <div className="infoBoxIconMobile">
                  <AttachMoneyOutlinedIcon fontSize="inherit" />
                </div>
              </div>
            )}
            <div className="infoBoxMobile">
              <div>
                <div className="infoBoxItemMobile">
                  {typeof assets !== 'undefined' ? assets.length * 2 : <span>...</span>}
                </div>
                <div className="infoBoxItem2Mobile">Assets</div>
              </div>
              <div className="infoBoxIconMobile">
                <WorkOutlineOutlinedIcon fontSize="inherit" />
              </div>
            </div>
            {!marketLive ? (
              <div className="infoBoxMobile">
                <div className="infoBoxMobileLeft">
                  <div className="infoBoxItemMobile">
                    ${typeof mintedAssetVolume !== 'undefined' ? outputNumber(mintedAssetVolume / 1e6, 4) : <span>...</span>}m
                  </div>
                  <div className="infoBoxItem2Mobile">Minted Asset Volume</div>
                </div>
                <div className="infoBoxIconMobile">
                  <AttachMoneyOutlinedIcon fontSize="inherit" />
                </div>
              </div>
            ) : (
              <div className="infoBoxMobile">
                <div className="infoBoxMobileLeft">
                  <div className="infoBoxItemMobile">
                    ${typeof totalLockedValue !== 'undefined' ? outputNumber(totalLockedValue / 1e6, 2) : <span>...</span>}m
                  </div>
                  <div className="infoBoxItem2Mobile">Value locked</div>
                </div>
                <div className="infoBoxIconMobile">
                  <AttachMoneyOutlinedIcon fontSize="inherit" />
                </div>
              </div>
            )}
            {false && (
              <div className="infoBoxMobile">
                <div className="infoBoxMobileLeft">
                  <div className="infoBoxItemMobile">
                    ${typeof assets !== 'undefined' ? outputNumber(ISSMarketCapCurrent / 1e6, 2) : <span>...</span>}m
                  </div>
                  <div className="infoBoxItem2Mobile">TWIN Market Cap</div>
                </div>
                <div className="infoBoxIconMobile">
                  <PieChartRoundedIcon fontSize="inherit" />
                </div>
              </div>
            )}
            {!marketLive && (
              <div className="infoBoxMobile">
                <div className="infoBoxMobileLeft">
                  <div className="infoBoxItemMobile">
                    {typeof highestYieldingAsset !== 'undefined' ? outputNumber(highestYieldingAsset[2] * 100, 0) : <span>...</span>}%
                  </div>
                  <div className="infoBoxItem2Mobile">
                    Top farm: {typeof highestYieldingAsset !== 'undefined' ? highestYieldingAsset[0] : <span>...</span>}
                  </div>
                </div>
                <div className="infoBoxIconMobile">
                  <AgricultureIcon fontSize="inherit" />
                </div>
              </div>
            )}
          </div>

          <div className="mainpageDashboardMobile">
            <div className="">
              <div className="headlineSearchWrapperMobile">
                <div className="mainpageHeadline">TWIN Assets and pools</div>
                <div className="searchContainerMobile">
                  <SearchIcon className="searchIconMobile" />
                  <input
                    type="text"
                    placeholder="Search assets..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="searchInputMobile"
                  />
                </div>
              </div>
              <div className="sortButtonsMobile">
                <button onClick={() => handleSort('name')} className="sortButtonMobile">
                  Name {renderSortIndicator('name')}
                </button>
                <button onClick={() => handleSort('tvl')} className="sortButtonMobile">
                  {marketLive ? 'Stake' : 'TVL'} {renderSortIndicator('tvl')}
                </button>
                {!marketLive && (
                  <button onClick={() => handleSort('yield')} className="sortButtonMobile">
                    Yield {renderSortIndicator('yield')}
                  </button>
                )}
              </div>
              <div className="">
                {sortedPools.length > 0 ? (
                  sortedPools.map((pool) => (
                    <div className="assetItemMobile" key={pool.key}>
                      <div className="assetNameSymbol">
                        <AssetLogo symbol={pool.symbol} />
                        <span>{pool.name} ({pool.symbol})</span>
                      </div>
                      {marketLive && <div className="text-left">Price: {pool.price}$</div>}
                      <div className="text-left">
                        {isNaN(pool.upperLimit) ? '' : <div>Upper limit: {pool.upperLimit}$</div>}
                      </div>
                      {marketLive && <div className="text-left">Pool liquidity: {pool.poolLiquidity}$</div>}
                      {!marketLive && (
                        <div className="text-left">
                          <div className="text-right">TVL: {pool.yourStake}$</div>
                        </div>
                      )}
                      {!marketLive && (
                        <div className="text-right">
                          APR: {pool.isHighestYield && <span>🔥</span>} {pool.apr}%
                        </div>
                      )}
                      {marketLive ? (
                        <div>
                          <div className="d-flex flex-row tradeButtonRowMobile">
                            <Link to={pool.tradeLinkBuy}>
                              <div className="buyButtonMobile">Buy</div>
                            </Link>
                            <Link to={pool.tradeLinkSell}>
                              <div className="sellButtonMobile">Sell</div>
                            </Link>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="noAssetsMobile">No assets found</div>
                )}
                <div className="buffer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMainpage;